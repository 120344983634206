const grecaptcha = window.grecaptcha;

export async function createRecaptchaToken(action) {
 return new Promise((resolve, reject) => {
  if (grecaptcha) {
   grecaptcha.ready(async () => {
    try {
     const token = await grecaptcha.execute('6LfwL1cmAAAAAJi6NrXTWxsP0ArXEH1TKffEtgON', { action });
     resolve(token);
    } catch (err) {
     reject(err);
    }
   });
  } else {
   reject('grecaptcha is not defined');
  }
 });
}
