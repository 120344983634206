<template>
  <div class="login-wrapper">
    <div class="login-wrapper-background">
      <Header />
      <div class="login-help">
        <div class="login-body">
          <component :is="modal"></component>
        </div>
      </div>
    </div>

    <Footer class="login-wrapper-footer" theme="home" />
  </div>
</template>

<script>
import Header from "@/components/Header/Header";
import Footer from "@/components/Footer/Footer";
export default {
  name: "LoginLayout",
  props: {
    item: {
      type: String,
      default: "",
    },
  },
  components: {
    Header,
    Footer,
  },
  computed: {
    modal() {
      return this.item;
    },
  },
};
</script>

<style lang="scss" scoped>
@media only screen and (min-width: 740px) {
  .login-help {
    padding-top: 90px;
  }
}

@media only screen and (max-width: 740px) {
  .login-help {
    padding-top: 20px;
  }
}

.login-wrapper {
  position: relative;
  &-background {
    background-size: cover;
    display: block;
    height: 100%;
    padding-bottom: 20px;
    min-height: 100vh;
    overflow: hidden;
    width: 100%;
    z-index: -1;
    background-image: url(https://assets.nflxext.com/ffe/siteui/acquisition/login/login-the-crown_2-1500x1000.jpg);
    background-position-x: 120vw;
  }
  &-footer {
    position: relative;
    padding: 0px;
  }
}
</style>
