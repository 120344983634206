<template>
  <GenericModal ref="otpModal" @cancel="onCancel">
    <p class="dialog-header">{{ $t('leadSignup.otpDialog') }}</p>
    <div :class="{ error: errors.otp }">
      <Input theme="login" id="otp" :label="$t('common.code')" v-bind="otp"
             class="otp-dialog__input"
      />
      <div class="input-errors">
        <div class="error-msg">{{ errors.otp }}</div>
      </div>
    </div>
    <template v-slot:actionButtons>
      <Button class="dialog-button" :label="$t('common.verify')" @click="submitOTPCode"/>
      <Button class="dialog-button" :disabled="timer" :label="$t('common.resend') + ' ' + timer"
              @click="sendCode('sms')"
      />
    </template>
  </GenericModal>
</template>

<script setup>

import GenericModal from '@/components/dialogs/GenericModal.vue';
import Button from '@/components/Button.vue';
import Input from '@/components/Input.vue';
import { useForm } from 'vee-validate';
import { z } from 'zod';
import { defineEmits, defineExpose, defineProps, ref } from 'vue';
import { createRecaptchaToken } from '@/helpers/recaptcha';
import { sendPhoneVerificationCodeByGuid } from '@/api/backoffice';
import { toTypedSchema } from '@vee-validate/zod';
import { useStore } from 'vuex';
import { getterTypes } from '@/store/modules/auth';

const emit = defineEmits(['submit']);
const store = useStore();

const timer = ref(0);
const otpModal = ref(null);

const props = defineProps({
  phone: {
    type: String
  }
});

const {
  errors,
  values,
  defineComponentBinds,
  validate,
  handleReset,
  setErrors
} = useForm({
  validationSchema: toTypedSchema(z.object({
    otp: z.string()
        .regex(/^\d{6}$/, 'OTP must be 6 digits')
  }))
});

const otp = defineComponentBinds('otp');

let interval = null;

function startTimer(time) {
  timer.value = time;
  if (interval) {
    clearInterval(interval);
    interval = null;
  }

  interval = setInterval(() => {
    timer.value--;
    if (timer.value === 0) {
      clearInterval(interval);
      interval = null;
    }
  }, 1000);
}

async function sendCode(channel = 'whatsapp') {
  const {
    guid,
    lead: leadId
  } = store.getters[getterTypes.guidCredentials];
  if (timer.value > 0) return;
  startTimer(channel === 'whatsapp' ? 30 : 60);
  const token = await createRecaptchaToken('lead_signup_otp');
  await sendPhoneVerificationCodeByGuid({
    guid,
    leadId,
    phone: props.phone,
    channel,
    captchaToken: token
  });
}

async function submitOTPCode() {
  const res = await validate();
  if (!res.valid) {
    return;
  }
  emit('submit', values.otp);
}

function onCancel() {
  handleReset();
}

defineExpose({
  show: () => {
    otpModal.value?.show();
    sendCode();
  },
  setError: error => setErrors(error),
  close: () => otpModal.value?.close()
});

</script>

<style lang="scss" scoped>
.input-errors {
  margin-top: 5px;
}

.error-msg {
  text-align: left;
  font-size: 12px;
}

.error {
  color: red
}

.dialog-header{
  font-size: 20px;
  margin-bottom: 20px;
  text-align: center;
}

.dialog-button {
  background-color: #fb8b00;
  border-radius: 5px;
  border: none;
  color: white;
  padding: 5px 15px;
  font-size: 18px;
  font-weight: 500;
  margin-top: 15px;
  cursor: pointer;
}

[data-theme="apollogroup"] {
  .dialog-button {
    background-color: #1caee5;
  }
}

[data-theme="pizzatime"] {
  .dialog-button {
    background-color: #fb8b00;
  }
}
</style>
