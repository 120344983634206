<template>
  <div class="bg-template" :class="'bg-template--' + theme">
    <img v-if="backgroundImage"
        class="bg-template__image"
        :src="backgroundImage"
        alt="Hero"
    />
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "BgTemplate",
  computed: {
    ...mapState({
      backgroundImage: (state) => `/img/${state.config?.brandName}-background.png`,
      theme: (state) => {
        return state.config?.brandName || "";
      },
    })
  }
};
</script>

<style lang="scss" scoped>
.bg-template {
  @media only screen and (min-width: 950px) {
    top: -100px;
  }
  left: 0;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  right: 0;
  top: -70px;
  z-index: -2;
  &:after {
    content: "";
    background-color: rgba(0, 0, 0, 0.55);
    background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.8) 0,
      transparent 50%,
      rgba(0, 0, 0, 0.8) 100%
    );
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  &__image {
    height: 100%;
    object-fit: cover;
    width: 100%;
    img {
      max-width: 100%;
    }
  }

  &--apollogroup {
    &:after {
      background-color: rgba(6, 36, 47, 0.55);
    }
  }
  &--pizzatime {
    &:after {
      background-color: rgba(37, 22, 6, 0.55);
    }
  }
}
</style>
