<template>
  <div class="register-mobile--container">
    <div class="register-mobile--close">
      <Close class="register-mobile-close-icon" @click="click" />
    </div>
    <div class="register-mobile--picture">
      <img :src="brandImage" alt="register mobile" />
    </div>
    <div class="register-mobile--info">
      <p class="register-mobile--info--title">{{ $t("registerMobile.title") }}</p>
      <p class="register-mobile--info--subtitle">
        {{ $t("registerMobile.subTitle") }}
        <span class="register-mobile--info--subtitle--app--icon">
          <img :src="brandAppImage" alt="register mobile brand">
        </span>
        <span class="register-mobile--info--subtitle--app--title">{{ brandAppName }}</span>
      </p>
      <div class="register-mobile--info--buttons">
        <a :href="googlePlayLink">
          <img src="@/assets/img/google-play.png">
        </a>
        <a :href="appStoreLink">
          <img src="@/assets/img/app-store.png">
        </a>
      </div>
      <div class="register-mobile--info--footer">
        <p>
          {{ $t("registerMobile.easyInstallation") }}
          <a href="/">{{ $t("registerMobile.here") }}</a>
        </p>
        <p>{{ $t("registerMobile.webAccess") }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

import Close from "@/assets/icons/svg/close.svg";
import phoneApollo from "@/assets/img/phone_apollo.png";
import phonePizza from "@/assets/img/phone_pizza.png";

export default {
  name: "RegisterMobilePopup",
  props: {
    click: {
      type: Function,
      default: () => {},
    },
  },
  components: {
    Close,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      brandName: (state) => state.config?.brandName || "",
      appStoreLink: (state) => state.config?.appStoreLink || "",
      googlePlayLink: (state) => state.config?.googlePlayLink || "",
      brandImage: (state) => {
        return state.config?.brandName === "apollogroup" ? phoneApollo : phonePizza;
      },
      brandAppImage: (state) => `/img/${state.config?.appName}-icon.png`,
      brandAppName: (state) => state.config?.appName,
    })
  },
  mounted() {
    window.addEventListener("message", this.click);
  },
  beforeUnmount() {
    window.removeEventListener("message", this.click);
  },
};
</script>

<style lang="scss" scoped>
.register-mobile {
  &--container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(26, 35, 45);
    color: #fff;
    z-index: 1000;
    padding: 80px 40px 30px;
    //@media (prefers-color-scheme: dark) {
    //  color: #fff;
    //  background: rgb(26, 35, 45);
    //}
    //@media (prefers-color-scheme: light) {
    //  background: rgb(245, 245, 245);
    //  color: #000;
    //}
  }
  &--close {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 40px;
  }
  &--picture{
    display: flex;
    justify-content: center;
    align-content: center;
  }
  &--info{
    box-shadow: 0px -48px 20px 0px rgb(26, 35, 45);
    z-index: 10;
    position: relative;
    &--title {
      font-family: "NetflixSans", sans-serif;
      font-size: 30px;
      line-height: 30px;
      font-weight: bold;
      text-align: center;
      margin-bottom: 30px;
    }
    &--subtitle {
      font-size: 20px;
      line-height: 26px;
      font-weight: 500;
      text-align: center;
      &--app {
        &--icon{
          border-radius: 6px;
          width: 26px;
          height: 26px;
          overflow: hidden;
          margin-right: 5px;
          display: inline-block;
          & > img {
            width: 26px;
            height: 26px;
          }
        }
        &--title {
          font-size: 21px;
          font-weight: bold;
        }
      }
    }
    &--buttons {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-flow: row;
      flex-wrap: nowrap;
      gap: 30px;
      margin-top: 30px;
      margin-bottom: 30px;
      img {
        width: 130px;
      }
    }
    &--footer {
      font-size: 12px;
      text-align: center;
    }
  }
}
</style>
