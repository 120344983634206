<template>
  <div :class="'animation-card--' + direction" class="animation-card">
    <div class="animation-card__text">
      <h1 class="story-title">{{ title }}</h1>
      <h2 class="story-subtitle">{{ subTitle }}</h2>
    </div>
    <component :is="cardItem"></component>
  </div>
</template>

<script>
export default {
  name: "AnimationCard",
  props: {
    direction: {
      type: String,
      default: "default",
    },
    title: {
      type: String,
      default: "",
    },
    subTitle: {
      type: String,
      default: "",
    },
    item: {
      type: String,
      default: "",
    },
  },
  computed: {
    cardItem() {
      return this.item;
    },
  },
};
</script>

<style lang="scss" scoped>
.animation-card {
  align-items: center;
  display: flex;
  justify-content: space-between;
  &--row-reverse {
    flex-direction: row-reverse;
  }
  margin: 0 auto;
  max-width: 1100px;
  @media only screen and (max-width: 349px),
    only screen and (min-width: 350px) and (max-width: 399px),
    only screen and (min-width: 400px) and (max-width: 549px),
    only screen and (min-width: 550px) and (max-width: 949px) {
    flex-flow: column;
  }
  &__text {
    flex: 0 1 auto;
    height: 100%;
    padding: 0 3rem 0 0;
    width: 52%;
    z-index: 3;

    @media only screen and (max-width: 349px),
      only screen and (min-width: 350px) and (max-width: 399px),
      only screen and (min-width: 400px) and (max-width: 549px),
      only screen and (min-width: 550px) and (max-width: 949px) {
      padding: 0;
      text-align: center;
      width: 100%;
    }
  }
}
</style>
