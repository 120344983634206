<template>
  <div class="db" :class="{ show: isActive }">
    <div class="header-wrap">
      <Header />
      <!-- <SubHeader :title="$t('movies')" /> -->
    </div>
    <div class="mainView mainView-aligned">
      <div class="volatile-billboard-animations-container" v-if="billboardMovies">
        <Billboard :click="showInfo" :item="billboardMovies" />
      </div>
      <VideoTeleport />
      <div ref="scrollComponent" class="slider-container slider-container-newmovies" v-if="!searchMovies">
        <Loader class="loader-categories" v-show="isLoadingCategory" />
        <!-- <div v-if="getAllCategory" @click="goToAll()">
          <Slider class="category-slider" :category="getAllCategory" :cards="itemsArr" :isInfos="true" />
          <div class="category-slider-hider" @click="goToAll()"></div>
        </div> -->
        <div class="categories_images" @click="goToAll()" v-if="!isHideAllMovie">
          <img src="../../assets/categories/All.jpg" class="category-view" />
          <span class="category-text">{{ $t("all") }}</span>
          <!-- </v-lazy> -->
        </div>
        <div class="categories_images" v-for="category in categories" @click="setCategory(category)" :key="category.id">
          <img :src="category.logo_url" class="category-view" />
          <span class="category-text">{{ category.name }}</span>
          <!-- </v-lazy> -->
        </div>
      </div>
      <GlobalSearchView :order="{ movies: 1, tvshow: 2, sports: 3, livetv: 4 }" />
    </div>
    <Footer class="member-footer" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import Header from "@/components/Header/Header";
import Loader from "@/components/Loader/Loader";
// import SubHeader from "@/components/Header/SubHeader";
import Billboard from "@/components/Billboard/Billboard";
import Footer from "@/components/Footer/Footer";
import VideoTeleport from "@/components/Slider/VideoTeleport";
import GlobalSearchView from "@/components/GlobalSearchView.vue";
import getCategoryImage from "../../helpers/categories";

export default {
  name: "NewMovies",
  components: {
    VideoTeleport,
    Header,
    Billboard,
    Loader,
    Footer,
    GlobalSearchView,
  },
  computed: {
    ...mapState({
      categories: (state) => {
        if (state.movies.categories) {
          const withImages = Object.values(state.movies.categories).map((item) => {
            return { ...item, logo_url: item.logo_url ? item.logo_url : getCategoryImage(item.name) };
          });
          return withImages;
        }
      },
      getAllCategory: (state) => {
        if (state.movies.categories?.[0]) {
          return {
            ...state.movies.categories[0],
            name: "All",
          };
        } else return null;
      },
      searchMovies: (state) => {
        return state.movies.searchMovies;
      },
      searchText: (state) => {
        return state.movies.searchText;
      },
      isLoadingCategory: (state) => state.movies.isLoading,
      isHideAllMovie: (state) => state.movies.categories.length <= 0 && state.movies.isLoading,
      itemsArr: (state) => state.movies.movies,
      billboardMovies(state) {
        if (this.randomMovie) return this.randomMovie;

        const recentAddedCategoryId = 2;
        if (!state.movies.categories) {
          return null;
        }

        const recentAdded = state.movies.categories.find((item) => item.id === recentAddedCategoryId) || false;
        if (!recentAdded) {
          return null;
        }
        const recentAddedInfos = recentAdded.infos || [];

        const randomId = Math.floor(Math.random() * recentAddedInfos.length);
        this.randomMovie = recentAddedInfos[randomId];

        return this.randomMovie;
      },
      isActive: (state) => state.infoMovie.isActive,
      isFinishedLoad: (state) => {
        return state.movies.isFinished;
      },
    }),
  },
  data() {
    return {
      offset: 0,
      lazyLoading: false,
      randomMovie: null,
    };
  },
  methods: {
    goToAll() {
      this.$router.push("/allMovies");
    },
    setCategory(category) {
      this.$store.commit("setSearchMovies", {
        text: category?.name,
        movies: category?.infos,
      });
    },
    showInfo() {
      this.$store.commit("handleGetInfoMovie", this.billboardMovies);
      this.$store.commit("handleShowInfoMovie", true);
    },
    loadCategories(offset) {
      this.$store.commit("finishedCategoryLoader", false);
      this.$store.dispatch("initMoviesLoad", {
        offset: offset,
        size: 5,
        toContinue: false,
      });
    },
    async handleScroll() {
      let element = this.$refs.scrollComponent;
      if (element?.getBoundingClientRect().bottom < window.innerHeight && this.lazyLoading === false) {
        this.lazyLoading = true;
        if (!this.isFinishedLoad) {
          this.offset = this.offset + 6;
          await this.$store.dispatch("loadMoreCategories", {
            offset: this.offset,
            size: 6,
          });
        }
        this.lazyLoading = false;
      }
    },
  },
  mounted() {
    const screenWidth = window.innerWidth > 0 ? window.innerWidth : screen.width;
    const isMobile = screenWidth <= 400;
    if (isMobile) {
      this.$router.push({ path: "/allmovies" });
    }
    this.loadCategories(this.offset);
    window.addEventListener("scroll", this.handleScroll);
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>
<style lang="scss">
.slider-container-newmovies {
  display: flex;

  column-gap: 50px;
  flex-wrap: wrap;
  row-gap: 60px;
  justify-content: center;
  margin-left: 100px;
  margin-right: 100px;

  // &:after {
  //   content: "";
  //   flex: auto;
  // }
  .lolomoRow {
    max-width: 300px;
    .wrapper__back:hover {
      display: none;
    }
    .wrapper__front:hover {
      opacity: 1;
    }
    li {
      min-width: 120%;
    }
  }
  .category-slider-hider {
    width: 95%;
    height: 100%;
    position: relative;
    top: -91%;
    cursor: pointer;
  }
}
.slider-container {
  .search-title {
    margin-top: 100px;
    .tvguide-picker__back {
      width: 30px;
      height: 30px;
      cursor: pointer;
    }
  }
}
.loader {
  &-billboard {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 20%;
  }
  &-categories {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 60%;
  }
}
.categories_images {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: start;
  align-items: center;
  .category-text {
    padding: 5px;
    background-color: #141414;
    width: 100%;
    text-align: center;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    box-shadow: 0px 5px 5px 2px #11111198;
    font-size: 14px;
    letter-spacing: 0.5px;
  }
  & img {
    height: 150px;
    width: 250px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    box-shadow: 0px 5px 5px 2px #11111198;
    cursor: pointer;
    &:hover {
      opacity: 0.6;
    }
  }
}
.row-header-title {
  cursor: pointer;
}

.db {
  &.show {
    height: 100.1vh;
    overflow: hidden;
  }
}
</style>
