<template>
  <div>
    <div v-if="defaultCountry" id="tabs" class="live-tv__select">
      <div class="live-tv__text">
        <span @click="setCategoryNull">
          <Back class="tvguide-picker__back"></Back>
          {{ $t("liveTv.selectedChannel") }}
        </span>
        <div class="tvguide-picker width-250" @blur="open = false">
          <div class="tvguide-picker__selected-option" :class="{ open: open }" @click="open = !open">
            <div class="tvguide-picker__text">
              {{ defaultCountry.countryName }}
            </div>
            <Down class="tvguide-picker__down" />
          </div>
          <div class="tvguide-picker__options" :class="{ selectHide: !open }">
            <div
              class="tvguide-picker__option"
              v-for="category in categories"
              :key="category.id"
              @click="
                switchCategory(category);
                open = false;
                $emit('input', category.id);
              "
            >
              {{ category.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="country-list justify-content">
      <div class="country-list__card" v-for="category in categories" :key="category.id" @click="switchCategory(category)">
        <img width="120" :src="imgUrl(category)" :alt="category.name" height="80" />
        <!-- <img :src="category.banner_logo_url" width="120" /> -->
        <p>{{ category.name }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import Down from "@/assets/icons/svg/down.svg";
import Back from "@/assets/icons/svg/back.svg";
import { mapActions, mapState } from "vuex";
export default {
  name: "SelectCountry",
  props: {
    categories: {
      type: Array,
    },
  },
  data() {
    return {
      open: false,
      country: {},
    };
  },
  computed: {
    ...mapState({
      defaultCountry: (state) => state.liveTv.defaultCountry,
      liveTvCards: (state) => state.liveTv.liveTvCards,
    }),
  },
  methods: {
    ...mapActions(["switchCategory"]),
    setCategoryNull() {
      this.$store.dispatch("switchCategory", null);
    },
    imgUrl(category) {
      try {
        return require(`@/assets/img/flags/${category.name}.png`);
      } catch (e) {
        console.log(e);
        return category.banner_logo_url;
      }
    },
  },
  components: {
    Down,
    Back,
  },
};
</script>

<style lang="scss">
.country-list {
  flex-wrap: wrap;
  display: flex;
  gap: 40px 10px;
  align-items: center;
  justify-content: flex-start;
  margin: 0 0 50px;
  touch-action: pan-y;
  &__card {
    padding: 5px;
    width: 250px;
    height: 70px;
    font-size: 14px;
    cursor: pointer;
  }
}
.tvguide-picker {
  width: 100%;
  padding: 5px 10px;
  display: block;
  color: $white;
  width: auto;
  height: auto;
  position: relative;
  border: 1px solid $gray;
  outline: none;
  &__selected-option {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__text {
    font-size: 16px;
    font-weight: bold;
    padding: 0.3rem 1.375rem 0.3rem 0.2rem;
    white-space: nowrap;
    cursor: pointer;
    user-select: none;
  }
  &__down {
    fill: $white;
  }
  &__back {
    width: 16px;
    height: 16px;
    path {
      fill: #fff;
    }
  }
  &__options {
    color: $white;
    position: absolute;
    margin-top: 6.5px;
    overflow: auto;
    background-color: $bg-gray;
    left: 0;
    right: 0;
    height: 260px;
    z-index: 1;
    border: 1px solid $bg-gray;
  }
  &__option {
    padding: 0.5rem 1.375rem;
    &:hover {
      background-image: linear-gradient(180deg, #0080ff, #0277ec);
    }
  }
}
.width-250 {
  width: 250px;
}
.selectHide {
  display: none;
}
.live-tv {
  display: flex;
  flex-direction: column;
  display: flex;
  padding: 4%;
  &__text {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 400;
    color: $white;
    padding-right: 20px;
    padding-left: 20px;
    gap: 10px;
    @media screen and (max-width: 450px) {
      flex-direction: column;
    }
    span {
      display: flex;
      cursor: pointer;
      // @media screen and (max-width: 450px) {
      //   max-width: 100px;
      // }
    }
    svg {
      margin-right: 10px;
      margin-top: 5px;
    }
  }
}
</style>
