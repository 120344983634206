import axios from 'axios';
import { getItem } from '@/helpers/storage'

axios.defaults.baseURL = "https://easy-play.cc/";

axios.interceptors.request.use(config => {
  const token = getItem('accessToken')
  const authorizisationToken = token ? `${token}` : ''
  config.headers.Authorization = `Bearer ${authorizisationToken}`;
  return config
})

export default axios
