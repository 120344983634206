<template>
  <nav class="header-nav">
    <ul class="header-nav__list">
      <li class="header-nav__list-item" v-for="item in menu" :key="item.link">
        <router-link class="header-nav__list-link" :to="item.link">
          {{ item.name }}
          <!-- {{ item.defaultLang ? language : $t(item.name) }} -->
        </router-link>
      </li>
    </ul>
    <ul class="header-nav__sub-list">
      <li class="header-nav__list-button">
        <Button label="Browse" />
        <Down class="header-nav__list-icon" />
      </li>
      <ul class="sub-menu header-nav__sub-menu">
        <Down class="sub-menu__icon" />
        <div class="sub-menu__topbar"></div>
        <li class="sub-menu__header-item" v-for="item in menu" :key="item.link">
          <router-link class="sub-menu__header-link" :to="item.link">
            {{ item.name }}
            <!-- {{ item.defaultLang ? language : $t(item.name) }} -->
          </router-link>
        </li>
      </ul>
    </ul>
  </nav>
</template>

<script>
import navHeaderData from "@/data/headerNav.js";
import Button from "@/components/Button";
import Down from "@/assets/icons/svg/down.svg";
import "./style.scss";
export default {
  name: "HeaderNav",
  data() {
    return {
      menu: navHeaderData,
      defaultLanguage: "en",
    };
  },
  computed: {
    selectedLanguage() {
      return this.$i18n.locale || this.defaultLanguage;
    },
  },
  components: {
    Button,
    Down,
  },
};
</script>
