<template>
  <div class="profile-selector">
    <div class="profile-selector-link">
      <h1 class="headline">{{ $t(`${$route.path}.title`) }}</h1>
      <ul class="profiles">
        <Loader class="loading" v-if="isLoading" />
        <li class="profile" v-for="user in users" :key="user.name">
          <button
            @click="handleChoiceProfile(user.profile_number)"
            class="profile-link"
          >
            <img class="profile-image" :alt="user.name" :src="user.avatar" />
            <span class="profile-name">{{ user.name }}</span>
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { actionTypes } from "@/store/modules/profile/profiles";
import { mapState } from "vuex";
import { setItem, getItem } from "@/helpers/storage";
import Loader from "@/components/Loader/Loader";
export default {
  name: "ProfilesGate",
  components: {
    Loader,
  },
  computed: {
    ...mapState({
      users: (state) => state.profiles.data,
      isLoading: (state) => state.profiles.isLoading,
    }),
  },
  data() {
    return {
      currentProfile: null,
    };
  },
  methods: {
    handleChoiceProfile(profileNumber) {
      this.currentProfile = this.users.find(
        (user) => user.profile_number === profileNumber
      );
      setItem("currentProfile", this.currentProfile);
      this.$router.push(getItem("savedPath") || "/movies");
    },
  },
  mounted() {
    this.$store.dispatch(actionTypes.getProfiles);
  },
};
</script>

<style lang="scss" scoped>
@import "style";
</style>
