<template>
  <a class="logo" :href="websiteLink">
    <img v-if="logoImage" :src="logoImage" alt="Logo" class="logo__image" />
    <p v-if="!logoImage">WebPlayer</p>
  </a>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Logo",
  computed: {
    ...mapState({
      logoImage: (state) => `/img/${state.config?.brandName}-logo.png`,
      websiteLink: (state) => {
        const portlaLink = state.config?.portalLink
        if (portlaLink) return portlaLink.replace("https://portal.", "https://")
      }
    }),

  }
};
</script>

<style lang="scss" scoped>
.logo {
  font-size: 25px;
  font-weight: bold;
  color: $text-red;

  @media screen and (min-width: 950px) {
    font-size: 32px;
  }

  &__image {
    width: auto;
    height: 50px;
  }
}
</style>
