import { getItem, setItem } from '@/helpers/storage';
import { getBrandConfig } from '@/api/backoffice';

export const flags = {
  isAlreadySeenMobileAppLink: 'isAlreadySeenMobileAppLink',
};

function initFlags() {
  return Object.entries(flags).reduce((acc, [, value]) => {
    acc[value] = getItem(value) ?? false;
    return acc;
  }, {});
}

export const actionTypes = {
  loadBrandConfig: '[config] load brand config',
};

export const mutationTypes = {
  setConfig: '[config] set config',
  updateFlag: '[config] update flag',
};

export default {
  state: {
    brandName: '',
    isConfigLoaded: false,
    appStoreLink: null,
    googlePlayLink: null,
    portalLink: null,
    chatProvider: null,
    appName: '',
    ...initFlags(),
  },
  actions: {
    async [actionTypes.loadBrandConfig]({ commit }) {
      try {
        const res = await getBrandConfig();
        commit(mutationTypes.setConfig, res.data.data);
      } catch (error) {
        console.error(error);
      }
    },
  },
  mutations: {
    [mutationTypes.setConfig](state, payload) {
      state.isConfigLoaded = true;
      state.brandName = payload?.brandName;
      state.appStoreLink = payload?.apps?.ios;
      state.googlePlayLink = payload?.apps?.android;
      state.portalLink = payload?.portalLink;
      state.appName = payload?.appName;
      state.chatProvider = payload?.chatProvider;
    },
    [mutationTypes.updateFlag](state, { flag, value }) {
      state[flag] = value;
      setItem(flag, value);
    },
  },
};
