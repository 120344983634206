<template>
  <button class="btn" :class="'btn--' + theme" v-if="!link">
    {{ label }}
  </button>
  <a
    v-else-if="to?.includes('http')"
    :href="to"
    target="_blank"
    class="link"
    :class="'link--' + theme"
  >
    {{ label }}
  </a>
  <router-link v-else :to="to" class="link" :class="'link--' + theme">
    {{ label }}
  </router-link>
</template>

<script>
export default {
  name: "Button",
  props: {
    link: Boolean,
    label: {
      type: String,
      default: "",
    },
    to: {
      type: String,
      default: "",
    },
    theme: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.btn {
  font-family: "NetflixSans", sans-serif;
  white-space: nowrap;
  cursor: pointer;
  border-radius: 4px;
  padding: 14px;
  font-size: 13px;
  font-weight: 700;
  color: $white;
  background-color: transparent;
  border: 0;
  min-height: 36px;
  line-height: 1em;
  &--default {
    background-image: linear-gradient(180deg, #e6e6e6, #ddd);
  }

  &--red {
    color: $white;
    font-size: 16px;
    height: auto;
    min-width: auto;
    padding: 12px 74px;
    width: auto;
    background-color: $bg-red;
  }
  &--red-sm {
    min-height: unset;
    display:flex;
    justify-content: center;
    align-items: center;
    color: $white;
    font-size: 16px;
    height: 30px;
    min-width: auto;
    padding: 12px 74px;
    width: auto;
    background-color: $bg-red;
  }
  &--red-large {
    width: 100%;
    color: $white;
    background-color: $bg-red;
    font-size: 24px;
    height: 65px;
  }
  &--cancel {
    bottom: 10px;
    left: 0;
    margin-left: 5%;
    color: $black;
    text-transform: none;
    width: 90%;
    background-color: $gray60;
    &:hover {
      background-color: $bg-light;
    }

    @media only screen and (min-width: 500px) {
      display: block;
      margin: 20px 10px 10px 0;
      max-width: 200px;
      position: relative;
      width: 200px;
      font-size: 13px;
      line-height: 1em;
      min-height: 37px;
      min-width: 98px;
      padding: 12px 1em;
    }
  }
  &--cookie {
    padding: 3px 10px;
    background-color: $bg-red;
    border-radius: 0;
    font-size: 13px;
    margin-right: 10px;
    min-height: 24px;
  }
  &--cookie-gray {
    background: transparent;
    border: 1px solid #8c8c8c;
    color: #333;
    min-height: 24px;
    padding: 3px 10px;
    border-radius: 0;
    font-size: 13px;
  }
  &--link {
    padding: 0;
    background: transparent;
    color: $text-primary;
    min-height: 0;
  }
  &--primary {
    width: 100%;
    background-image: linear-gradient(180deg, #0080ff, #0277ec);
    font-size: 20px;
    min-height: 48px;
    min-width: 112px;
    color: $white;
    padding: 14px 2em;
  }
  &--primary-small {
    background-image: linear-gradient(180deg, #0080ff, #0277ec);
    font-size: 13px;
    line-height: 1em;
    color: $white;
    min-height: 37px;
    min-width: 98px;
    padding: 12px -26em;
  }
  &--cancel {
  }
}

.link {
  cursor: pointer;
  color: $white;
  font-weight: 500;
  font-size: 1rem;
  &:hover {
    text-decoration: underline;
  }

  &--primary {
    color: $text-primary;
    font-size: 13px;
    &-large {
      color: $text-primary;
      font-size: 16px;
    }
  }
  &--logout {
    width: 45%;
    background-image: linear-gradient(180deg, #0080ff, #0277ec);
    font-size: 16px;
    display: block;
    min-height: 48px;
    min-width: 112px;
    text-align: center;
    color: $white;
    padding: 14px 2em;
  }
  &--gray {
    font-size: 14px;
    color: $black80;
    font-weight: 700;
    text-decoration: underline;
    &-large {
      color: $black80;
      font-size: 19px;
      font-weight: 700;
      line-height: 90px;
    }
  }
  &--white {
    color: $black;
    background-color: $white;
    font-size: 15px;
    font-weight: bold;
    border-radius: 4px;
    padding: 0.8rem;
  }

  &--red {
    background-color: $bg-red;
    font-weight: 400;
    padding: 7px 17px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    &:hover {
      text-decoration: none;
    }
  }
  &--lang-setup {
    border-radius: 2px;
    font-size: 17px;
    font-weight: 400;
    background-color: $bg-red;
    line-height: 1;
    width: 100%;
    text-align: center;
    display: inline-block;
    min-height: 60px;
    min-width: 110px;
    padding: 20.5px 2em;
    width: 100%;
    @media only screen and (min-width: 860px) {
      height: auto;
      min-width: auto;
      padding: 20px 74px;
      width: auto;
    }
  }
  &--faq {
    text-decoration: underline;
    font-size: 1.625rem;
  }
  &--login {
    float: right;
    color: $text-red;
    font-weight: 700;
    font-size: 1.2rem;
    line-height: 90px;
  }

  button.btn:disabled {
    background-color: #ddd;
    color: #666;
    cursor: not-allowed;
  }
}
</style>
