import { login, getCurrentUser, logout } from '@/api/index.js';
import { guidLogin, leadSignup } from '@/api/backoffice.js';
import { getItem, removeItem, setItem } from '@/helpers/storage';
import router from '@/router';

const state = {
  isSubmitting: false,
  isLoggedIn: null,
  isLoading: false,
  validationErrors: null,
  currentUser: null,
  lead: null,
  failedLogin: false,
  isSubscriptionExpired: false,
  guidCredentials: null,
  credentials: null
};

export const mutationTypes = {
  loginStart: '[auth] Login start',
  loginSuccess: '[auth] Login success',
  loginFailure: '[auth] Login failure',

  getCurrentUserStart: '[auth] Get current user start',
  getCurrentUserSuccess: '[auth] Get current user success',
  getCurrentUserFailure: '[auth] Get current user failure',

  subscriptionExpired: '[auth] subscription expired',

  setCredentials: '[auth] set credentials',

  setGuidCredentials: '[auth] set guid credentials',

  logout: '[auth] logout',
};

export const actionTypes = {
  login: '[auth] Login',
  closeLead: '[auth] close lead registration',
  guidLogin: '[auth] guidLogin',
  getCurrentUser: '[auth] Get current user',
  logout: '[auth] logout',
  leadRegistration: '[auth] lead registration',
};

export const getterTypes = {
  currentUser: '[auth] currentUser',
  isLoggedIn: '[auth] isLoggedIn',
  isTrial: '[auth] isTrial',
  isAnonymous: '[auth] isAnonymous',
  guidCredentials: '[auth] guidCredentials',
  credentials: '[auth] credentials',
};

const getters = {
  [getterTypes.currentUser]: (state) => {
    return state.currentUser;
  },
  [getterTypes.isLoggedIn]: (state) => {
    return Boolean(state.isLoggedIn);
  },
  isTrial: (state) => {
    if (!state.currentUser?.data) {
      return Boolean(getItem('guidCredentials')?.lead);
    }
    return Boolean(state.currentUser?.data?.account_type === 12);
  },
  leadId: () => Number(getItem('guidCredentials')?.lead),
  [getterTypes.isAnonymous]: (state) => {
    return state.isLoggedIn === false;
  },
  [getterTypes.guidCredentials]: (state) => {
    return state.guidCredentials;
  },
  [getterTypes.credentials]: (state) => {
    return state.credentials;
  }
};

const mutations = {
  [mutationTypes.loginStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
    state.failedLogin = false;
  },
  [mutationTypes.loginSuccess](state) {
    state.isSubmitting = false;
    state.isLoggedIn = true;
    state.failedLogin = false;
    router.push('/ProfilesGate');
  },
  [mutationTypes.loginFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
    state.failedLogin = true;
    router.push('/');
    if (payload?.leadId) {
      state.lead = payload.leadId;
    }
  },
  [mutationTypes.getCurrentUserStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.getCurrentUserSuccess](state, payload) {
    state.isLoading = false;
    state.isLoggedIn = true;
    state.currentUser = payload;
  },
  [mutationTypes.getCurrentUserFailure](state) {
    state.isLoading = false;
    state.isLoggedIn = false;
    state.currentUser = null;
    setItem('accessToken', undefined);
  },
  [mutationTypes.logout]() {
    state.currentUser = null;
    state.isLoggedIn = false;
    setItem('accessToken', undefined);
    router.push('/');
  },
  [mutationTypes.subscriptionExpired](state) {
    state.isSubscriptionExpired = true;
  },
  [mutationTypes.setCredentials](state, payload) {
    state.credentials = payload;
  },
  [mutationTypes.setGuidCredentials](state, payload) {
    state.guidCredentials = payload;
  }
};

const actions = {
  [actionTypes.closeLead](context) {
    context.state.lead = null;
  },
  [actionTypes.login](context, credentials) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.loginStart);
      login(credentials)
        .then((response) => {
          context.commit(mutationTypes.loginSuccess);
          setItem('accessToken', response.data.data.token);
          removeItem('guidCredentials');
          context.commit(mutationTypes.setGuidCredentials, null);
          context.dispatch(actionTypes.getCurrentUser);
          resolve(response);
        })
        .catch((e) => {
          if (e.response.data.message === 'Subscription expired' || e.response.data.message === 'No Subscription') {
            context.commit(mutationTypes.subscriptionExpired);
          }
          context.commit(mutationTypes.loginFailure);
        });
    });
  },
  [actionTypes.guidLogin](context, credentials) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.loginStart);
      guidLogin(credentials)
        .then((response) => {
          context.commit(mutationTypes.loginSuccess);
          setItem('accessToken', response.data.data.token);
          setItem('guidCredentials', credentials);
          context.commit(mutationTypes.setGuidCredentials, credentials);
          context.dispatch(actionTypes.getCurrentUser);
          resolve(response);
        })
        .catch(() => {
          context.commit(mutationTypes.loginFailure, { leadId: credentials.lead });
        });
    });
  },
  [actionTypes.getCurrentUser](context) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getCurrentUserStart);
      getCurrentUser()
        .then((response) => {
          context.commit(mutationTypes.getCurrentUserSuccess, response.data);
          resolve(response);
        })
        .catch(() => {
          context.commit(mutationTypes.getCurrentUserFailure);
          if (getItem('guidCredentials')?.lead) {
            context.dispatch(actionTypes.guidLogin, getItem('guidCredentials'));
          } else {
            context.commit(mutationTypes.logout);
          }
        });
    });
  },
  [actionTypes.logout](context) {
    return new Promise((resolve) => {
      logout().then(() => {
        context.commit(mutationTypes.logout);
      });
      resolve();
    });
  },
  async [actionTypes.leadRegistration](context, { clientData, captchaToken }) {
    const { guid, lead: leadId } = getItem('guidCredentials');
    const res = await leadSignup({ guid, leadId, captchaToken, payload: clientData });
    if (res.data.data.error) {
      return res.data.data;
    }
    context.commit(mutationTypes.setCredentials, res.data.data.credentials);
    setItem('accessToken', res.data.data.token);
    removeItem('guidCredentials');
    context.commit(mutationTypes.setGuidCredentials, null);
    await context.dispatch(actionTypes.getCurrentUser);
    return res.data.data;
  },
};
export default {
  state,
  actions,
  mutations,
  getters,
};
