<template>
  <ul class="notifications">
    <li>
      <Hawkins class="notifications__icon" />
      <ul class="sub-menu notifications__sub-menu">
        <!--        <Down class="sub-menu__icon sub-menu__icon-notifications" />-->
        <div class="sub-menu__topbar"></div>
        <li class="sub-menu__item">
          <ul class="sub-menu__notifications-list" v-if="false">
            <li
              class="sub-menu__notifications-item"
              v-for="notification in notifications"
              :key="notification.img"
            >
              <router-link class="sub-menu__notifications-link" to="/">
                <img
                  class="sub-menu__notifications-image"
                  src="https://dnm.nflximg.net/api/v6/kvDymu0eXRyicIuSUzvRrxrm5dU/AAAABXIOwepOA2Yu2T7SF3zjWQRG_0HDalWc2BpVnSY-oFP2NjeQwlRDC3_z21nGXmiwbEw56HqrzSOWyCN3iFJLS_vWBx-Yr67Hvsl-4RqPoO-Tm_4HArWlRfnaJWEWcTqyD43pArz4qKJXa34.jpg?r=58c"
                  srcset="
                    https://dnm.nflximg.net/api/v6/kvDymu0eXRyicIuSUzvRrxrm5dU/AAAABXIOwepOA2Yu2T7SF3zjWQRG_0HDalWc2BpVnSY-oFP2NjeQwlRDC3_z21nGXmiwbEw56HqrzSOWyCN3iFJLS_vWBx-Yr67Hvsl-4RqPoO-Tm_4HArWlRfnaJWEWcTqyD43pArz4qKJXa34.jpg?r=58c 112w
                  "
                  alt="Hustle"
                  sizes="112px"
                />
                <div class="sub-menu__notifications-content" to="/">
                  <h4>
                    {{ notification.header }}
                  </h4>
                  <p>
                    {{ notification.body }}
                  </p>
                </div>
              </router-link>
            </li>
            <li
              class="sub-menu__notifications-item"
              v-for="notification in notifications"
              :key="notification.img"
            >
              <router-link class="sub-menu__notifications-link" to="/">
                <img
                  class="sub-menu__notifications-image"
                  src="https://dnm.nflximg.net/api/v6/kvDymu0eXRyicIuSUzvRrxrm5dU/AAAABXIOwepOA2Yu2T7SF3zjWQRG_0HDalWc2BpVnSY-oFP2NjeQwlRDC3_z21nGXmiwbEw56HqrzSOWyCN3iFJLS_vWBx-Yr67Hvsl-4RqPoO-Tm_4HArWlRfnaJWEWcTqyD43pArz4qKJXa34.jpg?r=58c"
                  srcset="
                    https://dnm.nflximg.net/api/v6/kvDymu0eXRyicIuSUzvRrxrm5dU/AAAABXIOwepOA2Yu2T7SF3zjWQRG_0HDalWc2BpVnSY-oFP2NjeQwlRDC3_z21nGXmiwbEw56HqrzSOWyCN3iFJLS_vWBx-Yr67Hvsl-4RqPoO-Tm_4HArWlRfnaJWEWcTqyD43pArz4qKJXa34.jpg?r=58c 112w
                  "
                  alt="Hustle"
                  sizes="112px"
                />
                <div class="sub-menu__notifications-content" to="/">
                  <h4>
                    {{ notification.header }}
                  </h4>
                  <p>
                    {{ notification.body }}
                  </p>
                </div>
              </router-link>
            </li>
          </ul>
          <ul v-else class="sub-menu__notifications-list">
            <li class="sub-menu__item">
              <div class="sub-menu__item-text">
                {{ $t("notifications.EmptyNotifications") }}
              </div>
            </li>
          </ul>
        </li>
      </ul>
    </li>
  </ul>
</template>

<script>
import Hawkins from "@/assets/icons/svg/notification.svg";
export default {
  name: "Notifications",
  components: {
    Hawkins,
  },
  data() {
    return {
      notifications: [
        {
          img: "https://www.netflix.com/title/81232463",
          header: "Новое на webPlayer",
          body: "Конец Дороги",
          age: "5 dayes ago",
        },
      ],
    };
  },
};
</script>
