<template>
  <div class="db">
    <div class="pinning-header">
      <div class="pinning-header-container">
        <Header :hideSearch="true" />
        <SubHeader :title="$t('yourAccount.title')" />
      </div>
    </div>
    <div class="responsive-account-container">
      <h1 class="account-header account-header-inline">
        {{ $t("AccountPage.Account") }}
      </h1>
      <div class="account-section-membersince"></div>
      <div class="account-pre-messages-container"></div>
      <div class="account-messages-container"></div>
      <div class="responsive-account-content">
        <div class="account-section">
          <header class="account-section-header collapsable-section-toggle">
            <h2 class="account-section-heading">
              {{ $t("AccountPage.MembershipBilling") }}
            </h2>
            <!-- <Button label="Cancel Membership" theme="cancel" /> -->
          </header>
          <section class="account-section-content">
            <div class="account-subsection clearfix">
              <div class="clearfix">
                <div class="account-section-left">
                  <div class="account-section-item account-section-email">
                    {{ user?.data?.username }}
                  </div>
                  <div
                    class="account-section-item account-section-item-disabled"
                  >
                    Country: {{ user?.data?.geo?.country_name }}
                  </div>
                  <div
                    class="account-section-item account-section-item-disabled"
                  >
                    City Name: {{ user?.data?.geo?.city_name }}
                  </div>
                  <div
                    class="account-section-item account-section-item-disabled"
                  >
                    Timezone: {{ user?.data?.geo?.timezone }}
                  </div>
                  <div
                    class="account-section-item account-section-item-disabled"
                  >
                    Registered Date:
                    {{ user?.data?.display?.[1]?.["Registered Date"] }}
                  </div>
                  <div
                    class="account-section-item account-section-item-disabled"
                  >
                    IP:
                    {{ user?.data?.display?.[6]?.IP }}
                  </div>
                  <div
                    class="account-section-item account-section-item-disabled"
                  >
                    Reseller {{ user?.data?.display?.[3]?.Reseller }}
                  </div>
                </div>
                <div class="account-section-right">
                  <div
                    v-for="item in accountProfilesLinks.membersip"
                    :key="item.link"
                    class="account-section-item"
                  >
                    <Button
                      link
                      :label="item.name"
                      theme="primary-large"
                      :to="item.link"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div class="account-section">
          <header class="account-section-header collapsable-section-toggle">
            <h2 class="account-section-heading">
              {{ $t("AccountPage.planDetails") }}
            </h2>
          </header>
          <!-- <section class="account-section-content">
            <div class="account-subsection clearfix">
              <div class="clearfix">
                <div class="account-section-left">
                  <div>
                    <b> {{ user?.data?.display[5]["Account type"] }}</b>
                    <Premium class="quality-icon" />
                  </div>
                </div>
                <div class="account-section-right">
                  <div
                    v-for="item in accountProfilesLinks.planDetails"
                    :key="item.link"
                    class="account-section-item"
                  >
                    <Button
                      link
                      :label="item.name"
                      theme="primary-large"
                      :to="item.link"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section> -->
        </div>
        <div class="account-section clearfix">
          <!-- <header class="account-section-header collapsable-section-toggle">
            <h2 class="account-section-heading">
              {{ $t("AccountPage.productInfo") }}
            </h2>
          </header> -->
        </div>
      </div>
    </div>
  </div>
  <Footer />
</template>

<script>
import Button from "@/components/Button";
import Header from "@/components/Header/Header";
import SubHeader from "@/components/Header/SubHeader.vue";
import Footer from "@/components/Footer/Footer";
import { mapState } from "vuex";
import "./style.scss";
export default {
  name: "YourAccount",
  components: {
    Button,
    Footer,
    SubHeader,
    Header,
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.currentUser,
      accountProfilesLinks: (state) => ({
        membersip: [
          {
            name: "Change account details",
            link:
              state.auth.currentUser?.data?.service_info?.retail_website +
              "/portal",
          },
        ],
        billing: [],
        planDetails: [],
      }),
    }),
  },
};
</script>

<style lang="scss" scoped></style>
