export default {
  "common": {
    "appStoreBanner": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ми помітили, що ви використовуєте мобільний пристрій"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Щоб отримати кращий досвід, завантажте нашу програму (реєстраційні дані для входу надіслано вам електронною поштою)."])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Завантажити APP"])}
    }
  },
  "cookieDisclosure": {
    "thirdParties": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["треті особи "])},
    "cookiesAndSimilarTechnologies ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cookies and similar technologies "])},
    "LearnMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дізнайтеся більше про використання нами файлів cookie та інформації."])},
    "Accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прийняти"])},
    "Reject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Відхилити"])},
    "PersonaliseMyChoices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Персоналізуйте мій вибір"])}
  },
  "searchPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назви, народи, жанри"])},
  "header": {
    "accountDropdown": {
      "manageProfiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Керування профілями"])},
      "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рахунок"])},
      "helpCenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Довідковий центр"])}
    },
    "buttonKids": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kids"])}
  },
  "email": {
    "emailFormTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Готові до перегляду? Введіть свою електронну адресу, щоб створити або відновити членство."])},
    "input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Adress"])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Увійти"])}
  },
  "signUp": {
    "stepOne": {
      "stepTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закінчити налаштування облікового запису"])},
      "stepText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["webPlayer персоналізований для вас. Створіть пароль для перегляду на будь-якому пристрої в будь-який час."])}
    },
    "stepTwo": {
      "stepTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створіть пароль для початку членства"])},
      "stepTextOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ще кілька кроків - і все готово!"])},
      "stepTextTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ми теж ненавидимо паперову роботу."])},
      "inputEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати пароль"])},
      "checkbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Так, будь ласка, надішліть мені спеціальні пропозиції webPlayer."])}
    },
    "stepThree": {
      "stepTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обирайте свій план."])},
      "list": {
        "firstTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ніяких зобов'язань, скасування в будь-який момент."])},
        "nextTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все на webPlayer за однією низькою ціною."])},
        "lastTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Необмежений перегляд на всіх ваших пристроях."])}
      }
    },
    "createAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створити аккаунт"])},
    "createTrialAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створити пробний аккаунт"])}
  },
  "loginForm": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Увійдіть в систему"])},
    "inputEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Електронна пошта або номер телефону"])},
    "inputPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль"])},
    "inputRememberMe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пам'ятай мене"])},
    "btnSignIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Увійдіть в систему"])},
    "btnNeedHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Забули пароль?"])},
    "btnSignUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зареєструватися зараз"])},
    "btnRegister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Постановка на учет"])},
    "btnLearnMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дізнатися більше"])},
    "btnPrivacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
    "btnTermsOfService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Умови надання послуг"])},
    "formInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ця сторінка захищена Google reCAPTCHA, щоб переконатися, що ви не бот."])},
    "errorLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User/Password are incorrect"])},
    "tips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Інформація, зібрана за допомогою Google reCAPTCHA, є предметом угоди Google"])},
    "tipsNext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["і використовується для надання, підтримки і поліпшення сервісу reCAPTCHAсервісу reCAPTCHA і в загальних цілях безпеки (він не використовується для персоналізованої реклами Google)."])},
    "myAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мій рахунок"])},
    "subscriptionExpired": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Термін дії підписки закінчився"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Термін дії вашої підписки закінчився. Поновіть підписку, щоб і надалі користуватися нашими послугами."])},
      "renewSubscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поновити мою підписку"])}
    },
    "registerLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Немає облікового запису?"])}
  },
  "forgotPassword": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Забули електронну пошту/пароль?"])},
    "subheaderStepOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Як ви бажаєте скинути свій пароль?"])},
    "subheaderStepTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Будь ласка, надайте цю інформацію, щоб допомогти нам знайти ваш обліковий запис (всі поля обов'язкові)?"])},
    "labelEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "labelTextMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Текстове повідомлення (SMS)"])},
    "emailTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ми надішлемо Вам електронного листа з інструкціями про те, як скинути пароль."])},
    "btnStepOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Напишіть мені"])},
    "inputFirstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прізвище на рахунку"])},
    "inputLastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прізвище на рахунку"])},
    "inputCredit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер кредитної або дебетової картки у файлі"])},
    "btnStepSecond": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Знайти обліковий запис"])},
    "btnCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скасувати"])},
    "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я не пам'ятаю ні своєї електронної адреси, ні номера телефону."])}
  },
  "hero": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Необмежена кількість фільмів, телепередач тощо."])},
    "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дивитися будь-де. Скасувати в будь-який момент."])}
  },
  "cardAnimation": {
    "watchOnTv": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Насолоджуйтесь на своєму телевізорі."])},
      "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дивіться на смарт-телевізорах, PlayStation, Xbox, Chromecast, Apple TV, Blu-ray плеєрах тощо."])}
    },
    "downLoadAndWatch": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Завантажуйте програми для перегляду в режимі офлайн."])},
      "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Легко зберігайте улюблені фільми та завжди майте, що подивитися."])}
    },
    "watchOnDevice": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скрізь пильнуйте."])},
      "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дивіться необмежену кількість фільмів та телепрограм на телефоні, планшеті, ноутбуці та телевізорі, не переплачуючи."])}
    },
    "kidsCard": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створюйте профілі для дітей"])},
      "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Відправляйте дітей у пригоди з улюбленими героями у просторі, створеному спеціально для них - безкоштовно з вашим членством."])}
    }
  },
  "faq": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Найпоширеніші запитання."])},
    "faqQuestions": {
      "q1": {
        "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Що можна дивитися на webPlayer?"])},
        "answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["webPlayer має велику бібліотеку художніх та документальних фільмів, телепрограм, аніме, відзначених нагородами оригіналів webPlayer та багато іншого. Дивіться скільки завгодно і коли завгодно."])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ознайомтеся з деякими з наших матеріалів"])}
      },
      "q2": {
        "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Що таке webPlayer?"])},
        "answer_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["webPlayer - це потоковий сервіс, який пропонує широкий вибір відзначених нагородами телевізійних програм, фільмів, аніме, документальних фільмів та багато іншого - на тисячах пристроїв, підключених до Інтернету."])},
        "answer_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ви можете дивитися скільки завгодно, коли завгодно, без жодної реклами - і все це за одну низьку щомісячну ціну. Вас завжди чекає щось нове, а нові телепрограми та фільми додаються щотижня!"])}
      },
      "q3": {
        "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скільки коштує webPlayer?"])},
        "answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дивіться webPlayer на смартфоні, планшеті, смарт-телевізорі, ноутбуці або потоковому пристрої - і все це за одну фіксовану щомісячну плату. Вартість планів варіюється від 7,99 до 15,99 євро на місяць. Ніяких додаткових витрат, ніяких контрактів"])}
      },
      "q4": {
        "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Де можна подивитися?"])},
        "answer_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["webPlayer має велику бібліотеку художніх та документальних фільмів, телепрограм, аніме, відзначених нагородами оригіналів webPlayer та багато іншого. Дивіться скільки завгодно і коли завгодно."])},
        "answer_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ви також можете завантажити улюблені програми за допомогою програми для iOS, Android або Windows 10. Завантажуйте програми для перегляду в дорозі та без підключення до Інтернету. Візьміть webPlayer з собою куди завгодно."])}
      },
      "q5": {
        "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Як скасувати?"])},
        "answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["webPlayer гнучкий. Немає ніяких надокучливих контрактів і ніяких зобов'язань. Ви можете легко скасувати свій обліковий запис онлайн у два кліки. Плата за скасування не стягується - запускайте або зупиняйте свій обліковий запис у будь-який час."])}
      },
      "q6": {
        "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чи корисний webPlayer для дітей?"])},
        "answer_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TДитячий досвід webPlayer включений у вашу підписку, щоб дати батькам можливість контролювати, поки діти насолоджуються сімейними телепрограмами та фільмами у власному просторі."])},
        "answer_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дитячі профілі мають захищений PIN-кодом батьківський контроль, який дозволяє обмежити рейтинг зрілості контенту, який можуть переглядати діти, і заблокувати певні назви, які ви не хочете, щоб діти бачили."])}
      }
    }
  },
  "/ProfilesGate": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Хто дивиться?"])}
  },
  "/profiles/manage": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Керування профілями:"])},
    "editProfile": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редагувати профіль"])},
      "sectionOneTitle": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Параметри погашення"])},
        "firstString": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Усі рейтинги до погашення"])},
        "secondString": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Показати заголовки <b>", _interpolate(_named("msg")), "</b>  для цього профілю."])}
      },
      "sectionTwoTitle": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Керування автовідтворенням"])},
        "firstString": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автовідтворення наступного епізоду серіалу на всіх пристроях."])},
        "secondString": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autoplay previАвтовідтворення прев'ю під час перегляду на всіх пристроях."])}
      }
    }
  },
  "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Готово"])},
  "addProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати профіль"])},
  "addProfileDescr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додайте профіль для іншої людини, яка дивиться WebPlayer"])},
  "kid?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kid?"])},
  "kidsTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Якщо вибрано, цей профіль показуватиме лише телепередачі та фільми з рейтингом для дітей віком до 12 років."])},
  "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продовжити"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скасувати"])},
  "profileName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ім'я профілю"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ім'я"])},
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мова"])},
  "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редагувати"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зберегти"])},
  "deleteProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видалити профіль"])},
  "share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["частка"])},
  "watchNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дивитися зараз"])},
  "withYourFriends": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["зі своїми друзями"])},
  "logout": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ви так скоро їдете?"])},
    "textFirst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вам не потрібно щоразу виходити з WebPlayer. Це необхідно лише в тому випадку, якщо ви користуєтеся громадським або спільним комп'ютером."])},
    "textNext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ви будете перенаправлені на сайт webPlayer.com через 30 секунд."])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На головну"])}
  },
  "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Допомога"])},
  "pageNotFound": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заблукали?"])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WebPlayer Home"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вибачте, ми не можемо знайти цю сторінку. Ви знайдете багато цікавого на головній сторінці."])}
  },
  "moviesModal": {
    "cast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кастинг"])},
    "genres": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жанри"])},
    "trailers&more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Трейлери  & інше"])},
    "seasonsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сезони"])},
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Про"])},
    "director": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Директор"])},
    "writer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автор"])},
    "maturityRating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рейтинг"])},
    "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Компанія"])},
    "trailer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Трейлер"])}
  },
  "movies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Movies"])},
  "notifications": {
    "EmptyNotifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Відсутні нотіфікації"])}
  },
  "myLists": {
    "titlePage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Моі лісти"])},
    "titleMovie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фільми"])},
    "titlelTvShows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Серіали"])},
    "titleChannels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Канали"])},
    "titleSportsVOD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Спортивні канали"])}
  },
  "search": {
    "titlePage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пошук за категоріями"])},
    "movies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фільми"])},
    "tvShows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Серіали"])},
    "channels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Канали"])},
    "sportsVOD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Спортивні канали"])}
  },
  "tvGuide": {
    "tabs": {
      "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Today"])},
      "tomorrow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tomorrow"])},
      "dayAfterTomorrow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Day After tomorrow"])}
    },
    "selectedChannel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обраний канал"])}
  },
  "logOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вийти із WebPlayer"])},
  "play": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дивитись"])},
  "moreInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Більше інформаціі"])},
  "AccountPage": {
    "Account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аккаунт"])},
    "MembershipBilling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Членство та білінг"])},
    "planDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ДЕТАЛІ ПЛАНУ"])},
    "productInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Інформація про продукт"])}
  },
  "registerMobile": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цей вміст доступний виключно в нашому додатку."])},
    "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для найкращого досвіду завантажте"])},
    "easyInstallation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Щоб легко встановити, дотримуйтесь нашого короткого посібника"])},
    "here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["тут"])},
    "webAccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для доступу до Інтернету завантажте"])}
  }
}