<template>
  <div class="story-image-container">
    <div class="watch-on-device">
      <div class="watch-on-device__image">
        <img src="@/assets/img/mobile.jpg" alt="watch on tv" />
      </div>
      <div class="watch-on-device__animation">
        <div class="watch-on-device__animation-image">
          <img src="@/assets/img/boxshot.png" alt="image" />
        </div>
        <div class="watch-on-device__container">
          <div class="watch-on-device__text">Stranger Things</div>
          <div class="watch-on-device__download">Downloading...</div>
        </div>
        <div class="watch-on-device__animation-download"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WatchOnDevice",
};
</script>

<style lang="scss" scoped>
.watch-on-device {
  margin: -8% 0 -4% -15%;
  min-height: 100px;
  width: 100%;
  overflow: hidden;
  position: relative;
  @media only screen and (max-width: 349px),
    only screen and (min-width: 350px) and (max-width: 399px),
    only screen and (min-width: 400px) and (max-width: 549px),
    only screen and (min-width: 550px) and (max-width: 949px) {
    margin: -8% 0 0;
  }
  &__image {
    position: relative;

    img {
      height: 100%;
      width: 100%;
    }
  }
  &__animation {
    align-items: center;
    background: $black;
    border: 2px solid hsla(0, 0%, 100%, 0.25);
    border-radius: 0.75em;
    bottom: 8%;
    box-shadow: 0 0 2em 0 $black;
    display: flex;
    left: 50%;
    margin: 0 auto;
    min-width: 15em;
    padding: 0.25em 0.65em;
    position: absolute;
    transform: translateX(-50%);
    width: 60%;
    @media only screen and (max-width: 349px),
      only screen and (min-width: 350px) and (max-width: 399px),
      only screen and (min-width: 400px) and (max-width: 549px),
      only screen and (min-width: 550px) and (max-width: 949px) {
      margin: -8% 0 0;
    }
    @media only screen and (min-width: 400px) and (max-width: 549px),
      only screen and (min-width: 550px) and (max-width: 949px) {
      padding: 0.35em 0.75em;
    }
  }
  &__container {
    flex-grow: 1;
    flex-shrink: 1;
    margin: 0.3em 0;
  }
  &__text {
    color: $white;
    font-size: 0.9em;
    font-weight: 600;
    @media only screen and (min-width: 1450px),
      only screen and (min-width: $lg) and (max-width: $lg-max),
      only screen and (min-width: 950px) and (max-width: 1449px) {
      font-size: 1em;
    }
  }
  &__download {
    color: $text-primary;
    font-size: 0.75em;
    font-weight: 400;
    @media only screen and (min-width: 1450px),
      only screen and (min-width: $lg) and (max-width: $lg-max),
      only screen and (min-width: 950px) and (max-width: 1449px) {
      font-size: 0.9em;
    }
  }
  &__animation-image {
    flex-grow: 0;
    flex-shrink: 0;
    margin: 0 1em 0 0;
    img {
      border: 0;
      height: auto;
      max-width: 100%;
      height: 3em;
      @media only screen and (min-width: $lg) and (max-width: $lg-max) {
        height: 4em;
      }
    }
  }
  &__animation-download {
    background: url(https://assets.nflxext.com/ffe/siteui/acquisition/ourStory/fuji/desktop/download-icon.gif)
      50% no-repeat;
    background-size: 100%;
    content: "";
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    height: 3em;
    outline: 2px solid $black;
    outline-offset: -2px;
    width: 3em;
  }
}
</style>
