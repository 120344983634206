<template>
  <div class="wrapper wrapper-sport">
    <div class="card card--rounded card-sport">
      <div class="card-sport">
        <div class="card-sport__image-wrapper">
          <img :src="getFanArt" loading="lazy" :alt="item?.name" />
        </div>
        <div class="card-sport__body">
          <div class="card-sport-title">
            {{ item?.name }}
          </div>

          <div class="card__body--tags-item">
            {{ item?.now?.now?.title }}
          </div>
          <div class="card__body--tags-item">
            {{ dateTime(item.now?.now?.start, item.now?.now?.stop) !== "Invalid dateInvalid date" ? dateTime(item.now?.now?.start, item.now?.now?.stop) : "" }}
          </div>
        </div>
      </div>
      <div class="card-sport-buttons">
        <button @click="handlePlayVideo" class="play-btn round-btn">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" class="card__icon card__icon-sport" fill="none" viewBox="0 0 24 24">
            <path d="M21.44 10.72L5.96 2.98A1.38 1.38 0 004 4.213v15.474a1.373 1.373 0 002 1.233l15.44-7.74a1.38 1.38 0 000-2.467v.007z" />
          </svg>
        </button>
        <button @click="handleAddFavoriteLiveTv(item.id, item.is_favorite)" class="info-btn round-btn">
          <Ok v-if="item.is_favorite" class="card__icon" />
          <svg v-if="!item.is_favorite" class="card__icon-sport" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
            <path d="M12 0a1.5 1.5 0 011.5 1.5v9h9a1.5 1.5 0 110 3h-9v9a1.5 1.5 0 11-3 0v-9h-9a1.5 1.5 0 110-3h9v-9A1.5 1.5 0 0112 0z" />
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import Ok from "@/assets/icons/svg/ok.svg";
export default {
  name: "SliderLiveTv",
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    click: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    getFanArt() {
      return this.item?.logo || "/no-image-available.png";
    },
  },
  components: {
    Ok,
  },
  methods: {
    handleAddFavoriteLiveTv(id, favorite) {
      this.$store.dispatch("toggleMovieInFavorite", {
        section: "liveChannel",
        id,
      });
      this.$store.dispatch("switchFavouriteLiveTv", {
        id,
        is_favorite: favorite,
      });
    },
    handlePlayVideo() {
      this.$router.push({
        name: "Video",
        params: { slug: this.item.name },
        query: { trackId: this.item.id, type: "getLinkLive" },
      });
    },
    dateTime(from, to) {
      return moment(from, "YYYY-MM-DDTHH:mm").format("YYYY-MM-DD hh:mm") + moment(to, "YYYY-MM-DDTHH:mm").format("-hh:mm");
    },
  },
};
</script>

<style lang="scss">
.sport {
  position: relative;
  &-item {
    margin: 0 4px;
    color: $white10;
    position: relative;
    background: $black10;
    box-shadow: 0 0 12px rgb(0 0 0 / 90%);

    &-data {
      padding-bottom: 5px;
      font-size: 13px;
    }
    &-title {
      min-height: 2em;
      font-size: 14px;
      font-weight: 700;
      text-overflow: ellipsis;
    }
    &:focus,
    &:hover {
      transform: scale(1.1);
      z-index: 1;
      .sport-item-buttons {
        opacity: 1;
        visibility: visible;
      }
      .sport-item--backDrop {
        opacity: 1;
        visibility: visible;
      }
    }

    &-wrapper {
      padding: 4% 10%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
    }
    &-wrapper > * {
      margin-bottom: 1em;
    }
    &-buttons {
      display: flex;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-right: -50%;
      transform: translate(-50%, -50%);
      z-index: 23;
      opacity: 0;
      visibility: hidden;
      transition: all 1s, opacity 0.3s;
      background-color: rgba(0, 0, 0, 0.2);
    }
    &--backDrop {
      background-color: rgba(0, 0, 0, 0.5);
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      z-index: 22;
      opacity: 0;
      visibility: hidden;
      width: 100%;
    }
    &-image {
      width: 100px;
      margin-left: auto;
      margin-right: auto;
      display: block;
      height: 80px;
      object-fit: contain;
    }
  }
}
.sport-btn {
  padding: 0.9em !important;
  &:last-child {
    margin-left: 20px !important;
  }
}
.card__progress {
  margin-top: 1rem;
  gap: 0.75rem;
}
.progressbar {
  flex: 1;
  width: 200px;
  height: 3px;
  border-radius: 5px;
  background: $gray500;
  overflow: hidden;
  &-time {
    display: flex;
    justify-content: space-between;
    width: 85%;
  }
}
.progressbar__status {
  width: 15%;
  height: 100%;
  background: $red10;
}
</style>
