<template>
  <svg
    fill="#808080"
    viewBox="0 0 16 16"
    width="110"
    height="110"
    class="svg-plus"
  >
    <path
      d="M8,0C3.582,0,0,3.582,0,8s3.582,8,8,8s8-3.582,8-8S12.418,0,8,0z M12,9H9v3H7V9H4V7h3V4h2v3h3V9z"
    />
  </svg>
</template>

<script>
export default {
  name: "PlusIcon",
};
</script>

<style lang="scss" scoped>
.svg-plus {
  @media screen and (min-width: 1666px) {
    max-height: 110px;
    max-width: 110px;
  }
  @media screen and (max-width: 1665.9px) {
    max-height: 64px;
    max-width: 64px;
  }
}
</style>
