<template>
  <div class="db">
    <div class="header-wrap">
      <Header />
      <!-- <SubHeader :title="$t('sportsVOD.titlePage')" /> -->
    </div>
    <div class="mainView">
      <div class="lolomo">
        <div class="top">
          <div class="mainView-aligned">
            <Loader class="loader-categories" v-if="!categories" />
          </div>
          <div ref="scrollComponent">
            <GlobalSearchView :order="{ movies: 3, tvshow: 2, sports: 1, livetv: 4 }" />
            <div class="category-slider sports_cards" v-for="category in categories" :key="category.id">
              <div>
                <Slider :category="category" :cards="sportCards" :isSport="true" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer class="member-footer" />
    <Loader class="loader" v-if="lazyLoading" />
  </div>
</template>

<script>
import Header from "@/components/Header/Header";
import Footer from "@/components/Footer/Footer.vue";
// import SubHeader from "@/components/Header/SubHeader";
import Slider from "@/components/Slider/Slider";
import Loader from "@/components/Loader/Loader";
import { mapState } from "vuex";
import GlobalSearchView from "@/components/GlobalSearchView.vue";

export default {
  name: "SportsVOD",
  components: {
    Header,
    Footer,
    Slider,
    // SubHeader,
    Loader,
    GlobalSearchView,
  },
  computed: {
    ...mapState({
      searchSports: (state) => state.sportsVod.searchSports,
      categories: (state) => state.sportsVod.sportsCategories,
      isLoading: (state) => state.sportsVod.isLoading,
      sportCards: (state) => state.sportsVod.sportsCards,
      searchText: (state) => state.sportsVod.searchText,
    }),
  },
  data: () => ({
    lazyLoading: false,
  }),
  mounted() {
    this.$store.dispatch("getSportsVodData");
    window.addEventListener("scroll", this.handleScroll);
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    async handleScroll() {
      let element = this.$refs.scrollComponent;
      if (element?.getBoundingClientRect().bottom < window.innerHeight && this.lazyLoading === false) {
        this.lazyLoading = true;

        // this.offset = this.offset + 3;
        await this.$store.dispatch("getSportCards");

        this.lazyLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.py-200 {
  padding-top: 200px;
}
.mainView-aligned {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 120px;

  flex: 1;
  flex-direction: column;
}
.search-title {
  margin-top: 0;
  margin-bottom: 40px;
}
.loader {
  position: sticky;
  bottom: 150px;
}
</style>
