<template>
  <div class="list-profiles">
    <h1 class="profile-gate-label">{{ $t(`${$route.path}.title`) }}</h1>
    <ul class="choose-profile">
      <Loader class="loading" v-if="isLoading" />
      <li
        class="profile"
        v-for="user in users"
        :key="user.id"
        @click="handleEditUser(user)"
      >
        <a role="button" class="profile-link">
          <div class="avatar-wrapper">
            <div
              class="profile-icon profile-edit-mode"
              :style="{ backgroundImage: `url(${user?.avatar})` }"
            />
            <div class="svg-edit-overlay">
              <EditIcon />
            </div>
          </div>
          <span class="profile-name">{{ user.name }}</span>
        </a>
      </li>
      <li>
        <a role="button" tabindex="0" @click="handleCreateUser">
          <div class="addProfileIcon icon-tvuiAdd">
            <PlusIcon />
          </div>
          <span class="profile-name">{{ $t("addProfile") }}</span>
        </a>
      </li>
    </ul>
  </div>
  <span>
    <a href="/ProfilesGate" class="profile-button preferred-action">{{
      $t("done")
    }}</a>
  </span>
</template>

<script>
import EditIcon from "@/assets/icons/svg/EditIcon";
import PlusIcon from "@/assets/icons/svg/PlusIcon";
import { actionTypes } from "@/store/modules/profile/profiles";
import Loader from "@/components/Loader/Loader";
import { mapState } from "vuex";
export default {
  name: "AllProfiles",
  components: {
    EditIcon,
    PlusIcon,
    Loader,
  },

  computed: {
    ...mapState({
      users: (state) => state.profiles.data,
      isLoading: (state) => state.profiles.isLoading,
    }),
  },
  mounted() {
    this.$store.dispatch(actionTypes.getProfiles);
  },
  methods: {
    handleEditUser() {
      this.$store.commit("handleEditProfile", true);
    },
    handleCreateUser() {
      this.$store.commit("handleAddProfile", true);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "style";
</style>
