import { getProfilesPictures  } from '@/api/index.js'

const state = {
  data: null,
  isSubmitting: false,
  validationErrors: null
}

export const mutationTypes = {
  getProfilePicturesStart: '[createProfilePictures] Get profile pictures start',
  getProfilePicturesSuccess: '[createProfilePictures] Get profile pictures success',
  getProfilePicturesFailure: '[createProfilePictures] Get profile pictures failure',

}

export const actionTypes = {
  getProfilePictures: '[createProfilePictures] Get profile pictures',
}

const mutations = {
  [mutationTypes.getProfilePicturesStart](state) {
    state.isSubmitting = true
  },
  [mutationTypes.getProfilePicturesSuccess](state, payload) {
    state.isSubmitting = false
    state.data = payload
  },
  [mutationTypes.getProfilePicturesFailure](state, payload) {
    state.isSubmitting = false
    state.validationErrors = payload
  }
}
const actions = {
  [actionTypes.getProfilePictures](context) {
    return new Promise(resolve => {
      context.commit(mutationTypes.getProfilePicturesStart)
      getProfilesPictures()
        .then(response => {
          context.commit(mutationTypes.getProfilePicturesSuccess, response.data.data)
          resolve(response)
        })
        .catch(result => {
          context.commit(
            mutationTypes.getProfilePicturesFailure,
            result.response.data.errors
          )
        })
    })
  }
}

export default {
  state,
  actions,
  mutations
}
