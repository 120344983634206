import _ from "lodash";
export const getItem = (key) => {
  try {
    if (localStorage.getItem(key) && localStorage.getItem(key) !== 'undefined') {
      return JSON.parse(localStorage.getItem(key));
    }
  } catch (e) {
    console.error("Error in getting data from localStorage", e);
    return null;
  }
};

export const setItem = (key, data) => {
  try {
    localStorage.setItem(key, JSON.stringify(data));
  } catch (e) {
    console.error("Error in setting data to localStorage", e);
  }
};

export const removeItem = (key) => {
  localStorage.removeItem(key);
};

export const addCategoryToItem = (list = [], category) => {
  for (var i = 0, len = list.length; i < len; i++) {
    const categoryList = [];

    const { id, type, name } = category;
    categoryList.push({ id, type, name });
    list[i].categories = categoryList;
  }
  console.log(list);

  return list;
};

export const arrayFilterUniqueItemsById = (array, filterKey) => {
  // Creates an array of objects with unique "imdb_id" property values.
  let uniqueObjArray = [...new Map(array.map((item) => [item[filterKey], item])).values()];
  return uniqueObjArray;
};

export const arrayFilterItemsById = (arr) => {
  const result = [];
  const duplicatesIndices = [];

  arr.forEach((current, index) => {
    if (duplicatesIndices.includes(index)) return;

    result.push(current);

    for (let comparisonIndex = index + 1; comparisonIndex < arr.length; comparisonIndex++) {
      const comparison = arr[comparisonIndex];
      const currentKeys = Object.keys(current);
      const comparisonKeys = Object.keys(comparison);

      if (currentKeys.length !== comparisonKeys.length) continue;

      const currentKeysString = currentKeys.sort().join("").toLowerCase();
      const comparisonKeysString = comparisonKeys.sort().join("").toLowerCase();
      if (currentKeysString !== comparisonKeysString) continue;

      let valuesEqual = true;
      for (let i = 0; i < currentKeys.length; i++) {
        const key = currentKeys[i];
        if (current[key] !== comparison[key]) {
          valuesEqual = false;
          break;
        }
      }
      if (valuesEqual) duplicatesIndices.push(comparisonIndex);
    } // Конец цикла
  });
  return result;
};

export const convertToTracks = (data) => {
  let textTracks = [];
  data.map((track) => {
    // https://subs.tv4.live/movies/tt4513678/tt4513678.pt.srt
    // 'https://raw.githubusercontent.com/Apollo2000/subs/master/movies/tt4513678/tt4513678.pt.srt'
    let textTrack = track.data;
    let item = Object.entries(textTrack).map(([key, value]) => {
      return {
        src: value.replace(".srt", ".vtt"), //.replace('https://subs.tv4.live', 'https://raw.githubusercontent.com/Apollo2000/subs/master'),
        kind: "captions",
        srclang: key.slice(0, 2).toLowerCase(),
        label: key,
      };
    });
    textTracks.push(...item);
  });
  return textTracks;
};

export const commitGlobalSearch = (commit, text, result) => {
  commit("setSearchMovies", {
    text,
    movies: _.isEmpty(result.movies.data) ? null : result.movies.data,
  });
  commit("setSearchTvShows", _.isEmpty(result.tvShow.data) ? null : result.tvShow.data);
  commit("setSearchSports", {
    text,
    sports: _.isEmpty(result.sports.data) ? null : result.sports.data,
  });
  commit("setSearchLiveTv", _.isEmpty(result.livetv.data) ? null : result.livetv.data);
};
