import { createStore } from "vuex";
import auth from "@/store/modules/auth";
import tvShows from "@/store/modules/tvShow/tvShows";
import profiles from "@/store/modules/profile/profiles";
import ediProfile from "@/store/modules/profile/editProfile";
import createProfile from "@/store/modules/profile/createProfile";
import sportsVod from "@/store/modules/SportsVod/sportsVod";
import liveTv from "@/store/modules/LiveTv/liveTv";
import profilePictures from "@/store/modules/profile/profilePictures";
import playVideo from "@/store/modules/playVideo";
import infoMovie from "@/store/modules/infoMovie";
import tvGuide from "@/store/modules/tvGuide/tvGuide";
import movies from "@/store/modules/movies/movies";
import favorite from "@/store/modules/favorite/favorite";
import config from '@/store/modules/config';

export const store = createStore({
  state() {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    tvShows,
    profiles,
    ediProfile,
    createProfile,
    sportsVod,
    playVideo,
    profilePictures,
    infoMovie,
    movies,
    liveTv,
    tvGuide,
    favorite,
    config
  },
});
