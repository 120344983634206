<template>
  <div class="db" :class="{ show: isActive }">
    <div class="header-wrap">
      <Header />
      <!-- <SubHeader :title="$t('TvShows')" /> -->
    </div>
    <div ref="scrollComponent" class="mainView mainView-aligned">
      <div class="volatile-billboard-animations-container" v-if="billboardTvShows && !searchTvShows">
        <Billboard :click="showInfo" :item="billboardTvShows" />
      </div>
      <VideoTeleport v-if="searchText === null || searchText === ''" />
      <GlobalSearchView :order="{ movies: 2, tvshow: 1, sports: 3, livetv: 4 }" />
      <div class="slider-container" v-if="!searchText">
        <div class="mainView-aligned">
          <Loader class="loader-billboard" v-if="!billboardTvShows" />
        </div>
        <div v-for="category in categories" :key="category.id">
          <Slider class="category-slider" :category="category" :isInfos="true" />
        </div>
      </div>
    </div>
    <Footer class="member-footer" />
    <Loader class="loader" v-if="lazyLoading" />
  </div>
</template>

<script>
import Header from "@/components/Header/Header";
import { mapState } from "vuex";
import Footer from "@/components/Footer/Footer.vue";
import VideoTeleport from "@/components/Slider/VideoTeleport";
// import SubHeader from "@/components/Header/SubHeader.vue";
import Billboard from "@/components/Billboard/Billboard";
import Slider from "@/components/Slider/Slider";
import Loader from "@/components/Loader/Loader";
import "@/views/Movies/style.scss";
import GlobalSearchView from "@/components/GlobalSearchView.vue";

export default {
  name: "TVShows",
  components: {
    Header,
    Footer,
    Billboard,
    Slider,
    Loader,
    VideoTeleport,
    GlobalSearchView,
  },

  computed: {
    ...mapState({
      searchTvShows: (state) => state.tvShows.searchTvShows,
      searchText: (state) => state.tvShows.searchText,
      categories: (state) => state.tvShows.categories,
      isLoadingCategory: (state) => state.tvShows.isLoading,
      isActive: (state) => state.infoMovie.isActive,
      billboardTvShows: (state) => {
        const recentAddedCategoryId = 53;
        if (!state.tvShows.categories) {
          return null;
        }

        const recentAdded = state.tvShows.categories.find((item) => item.id === recentAddedCategoryId) || false;
        if (!recentAdded) {
          return null;
        }
        const recentAddedInfos = recentAdded.infos || [];
        const randomId = Math.floor(Math.random() * recentAddedInfos.length);
        return recentAddedInfos[randomId];
      },
    }),
  },
  data() {
    return {
      offset: 0,
      isActive: false,
      lazyLoading: false,
      randomMovie: null,
    };
  },
  methods: {
    showInfo() {
      this.$store.commit("handleGetInfoMovie", this.billboardTvShows);
      this.$store.commit("handleShowInfoMovie", true);
    },
    loadCategories(offset) {
      this.$store.commit("finishedCategoryLoader", false);
      this.$store.dispatch("initTvShowsLoad", {
        offset: offset,
        size: 3,
      });
    },
    async handleScroll() {
      let element = this.$refs.scrollComponent;
      if (element.getBoundingClientRect().bottom < window.innerHeight && this.lazyLoading === false) {
        this.lazyLoading = true;

        this.offset = this.offset + 3;
        await this.$store.dispatch("loadMoreCategories", {
          offset: this.offset,
          size: 3,
        });
        this.lazyLoading = false;
      }
    },
  },
  mounted() {
    this.loadCategories(this.offset);
    window.addEventListener("scroll", this.handleScroll);
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style lang="scss">
.search-title {
  margin: auto;
  text-align: center;
  font-size: 28px;
  padding-top: 20px;
}
.mainView-aligned {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  flex-direction: column;
}
.slider-container {
  width: 100%;
  position: relative;
  z-index: 1;
}
.db {
  &.show {
    height: 100.1vh;
    overflow: hidden;
  }
}
</style>
<style scoped>
.loader {
  position: sticky;
  bottom: 150px;
}
</style>
