<template>
  <div class="error-page">
    <div class="error-page__content">
      <h1 class="error-page__content-header">
        {{ $t("pageNotFound.header") }}
      </h1>
      <div class="error-page__content-body">
        <p>
          {{ $t("pageNotFound.text") }}
        </p>
        <Button
          class="error-page__button"
          :label="$t('pageNotFound.button')"
          theme="white"
          link
          to="/"
        />
        <div class="error-page__content-errorCode">
          <span id="">Error Code <strong>NSES-404</strong></span>
        </div>
      </div>
    </div>
    <span class="error-page-imageSource">
      FROM <strong>LOST IN SPACE</strong>
    </span>
    <div class="error-page-requestInfo"></div>
  </div>
</template>

<script>
import Button from "@/components/Button";
export default {
  name: "PageNotFound",
  components: {
    Button,
  },
};
</script>

<style lang="scss" scoped>
.error-page {
  font-size: 1.5vw;
  &::before {
    content: "";
    display: flex;
    flex: 1;
    max-height: 25%;
  }
  &::after {
    content: "";
    display: flex;
    flex: 1;
    max-height: 25%;
  }
  @media screen and (min-width: 841px) and (max-width: 1280px) and (orientation: landscape) {
    font-size: 0.85vw;
  }
  @media screen and (min-width: 0) and (max-width: 480px) and (max-aspect-ratio: 4 / 3) {
    font-size: 1.5vw;
  }
  @media screen and (min-width: 481px) and (max-width: 840px) and (max-aspect-ratio: 4 / 3) {
    font-size: 1vw;
  }
  @media screen and (min-width: 1281px) and (max-width: 1600px) and (orientation: landscape) {
    font-size: 0.75vw;
  }

  background: rgba(0, 0, 0, 0.18);
  bottom: 0;
  color: $white;
  display: flex;

  flex-direction: column;
  left: 0;
  line-height: 1.2;
  overflow: hidden;
  padding-top: 68px;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1000;
  &-imageSource {
    bottom: 0;
    color: hsla(0, 0%, 90%, 0.57);
    font-size: 1.4em;
    font-weight: 100;
    letter-spacing: 0.1em;
    line-height: 1.5vw;
    margin: 2.5vw 4.8vw;
    position: fixed;
    right: 0;
    text-shadow: 0 1px 2px rgb(0 0 0 / 57%);
    strong {
      color: hsla(0, 0%, 90%, 0.77);
    }
  }
  &-requestInfo {
    bottom: 0;
    color: transparent;
    left: 0;
    margin: 2.5vw 4.8vw;
    position: fixed;
  }
  &__content {
    &:before {
      background: radial-gradient(
        ellipse at center,
        rgba(0, 0, 0, 0.5) 0,
        rgba(0, 0, 0, 0.2) 45%,
        rgba(0, 0, 0, 0.1) 55%,
        transparent 70%
      );
      bottom: -10vw;
      content: "";
      left: 10vw;
      position: absolute;
      right: 10vw;
      top: -10vw;
      z-index: 0;
    }
    &::after {
      background-attachment: fixed;
      background-position: 50%;
      background-repeat: no-repeat;
      background-size: cover;
      bottom: 0;
      content: "";
      left: 0;
      position: fixed;
      right: 0;
      top: 0;
      z-index: -1;
      background-image: url(https://assets.nflxext.com/ffe/siteui/pages/errors/bg-lost-in-space.png);
    }
    display: flex;
    flex: 2 0;
    flex-direction: column;
    margin: 0 1em;
    position: relative;
    text-align: center;
    user-select: none;
    &-header {
      font-size: 6em;
      font-weight: 700;
      margin-bottom: 2vw;
      z-index: 1;
    }
    &-errorCode {
      align-items: flex-end;
      display: flex;
      flex: 1;
      justify-content: center;
      margin-top: 2em;
      span {
        border-left: 2px solid #e50914;
        font-size: 2.4em;
        font-weight: 100;
        letter-spacing: 0.1em;
        line-height: 2.2em;
        padding: 0 1vw;
      }
    }
    &-body {
      display: flex;
      flex: 1;
      flex-direction: column;
      margin: 0 auto;
      padding-bottom: 2em;
      width: 50vw;
      z-index: 1;
      p {
        font-size: 2.2em;
        font-weight: 300;
        padding-bottom: 20px;
      }
    }
  }
  &__button {
    display: flex;
    justify-content: center;
    max-width: 200px;
    margin: 0 auto;
    &:hover {
      text-decoration: none;
      background-color: rgba(255, 255, 255, 0.8);
    }
  }
}
</style>
