<template>
  <div class="db" :class="{ show: isActive }">
    <div class="header-wrap">
      <Header :hideSearch="true" />
      <!-- <SubHeader :title="$t('search.titlePage')" /> -->
    </div>
    <div class="mainView">
      <div class="view-search">
        <div id="tabs" class="view-search__container">
          <Tabs
            :activetab="activetab"
            :setActiveTab="setActiveTab"
            :tabs="tabs"
          ></Tabs>
          <div class="view-search__content">
              <div class="lists-content justify-content">
                <Loader class="loader-search" v-if="loading" />
              </div>
            <div v-if="activetab === 1" class="tabcontent">
              <div class="view-search__content-input search-page">
                <input
                  type="text"
                  v-model.trim="searchMovie"
                  v-on:keyup.enter="getDataMovies"
                  :placeholder="$t('search.searchHere')"
                />
                <SearchIcon
                  @click="getDataMovies"
                  class="view-search__content-icon"
                />
              </div>
              <div class="lists-content justify-content">
                <div v-for="item in searchMovies" :key="item.id">
                  <SliderItem :brandImage="brandImage" :item="item" />
                </div>
              </div>
            </div>
            <div v-if="activetab === 2" class="tabcontent">
              <div class="view-search__content-input search-page">
                <input
                  type="text"
                  v-on:keyup.enter="getDataTvShows"
                  v-model.trim="serachTvShow"
                  :placeholder="$t('search.searchHere')"
                />
                <SearchIcon
                  @click="getDataTvShows"
                  class="view-search__content-icon"
                />
              </div>
              <div class="lists-content justify-content">
                <div v-for="item in searchTvShows" :key="item.id">
                  <SliderItem :brandImage="brandImage" :item="item" />
                </div>
              </div>
            </div>
            <div v-if="activetab === 3" class="tabcontent">
              <div class="view-search__content-input search-page">
                <input
                  type="text"
                  v-model.trim="searchSportVod"
                  v-on:keyup.enter="getDataSportsVod"
                  :placeholder="$t('search.searchHere')"
                />
                <SearchIcon
                  @click="getDataSportsVod"
                  class="view-search__content-icon"
                />
              </div>
              <div class="lists-content justify-content">
                <div
                  class="margin-4"
                  v-for="item in searchSportsVod"
                  :key="item.id"
                >
                  <SliderItemSport :item="item" />
                </div>
              </div>
            </div>
            <div v-if="activetab === 4" class="tabcontent">
              <div class="view-search__content-input search-page">
                <input
                  type="text"
                  v-model.trim="searchLiveTv"
                  v-on:keyup.enter="getDataLiveTv"
                  :placeholder="$t('search.searchHere')"
                />
                <SearchIcon
                  @click="getDataLiveTv"
                  class="view-search__content-icon"
                />
              </div>
              <div class="lists-content justify-content">
                <div
                  class="margin-4"
                  v-for="item in serachLiveTv"
                  :key="item.id"
                >
                  <SliderLiveTv :item="item" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer class="member-footer" />
  </div>
</template>

<script>
import Header from "@/components/Header/Header";
import Footer from "@/components/Footer/Footer.vue";
// import SubHeader from "@/components/Header/SubHeader";
import SliderItem from "@/components/Slider/SliderItem.vue";
import Tabs from "@/components/Tabs/Tabs.vue";
import SearchIcon from "@/assets/icons/search.svg";
import Loader from "@/components/Loader/Loader";
import SliderItemSport from "@/components/Slider/SliderItemSport.vue";
import SliderLiveTv from "@/components/Slider/SliderLiveTv.vue";

import {mapState} from "vuex"
import "./style.scss";
import {
  getSearchLiveTv,
  getSearchSportsVod,
  getSearchTvShow,
  getSearchMovie,
} from "@/api/index.js";

export default {
  name: "SportsVOD",
  components: {
    Header,
    Footer,
    // SubHeader,
    SliderItem,
    SliderItemSport,
    SliderLiveTv,
    SearchIcon,
    Loader,
    Tabs,
  },
  data() {
    return {
      searchMovies: null,
      searchTvShows: null,
      serachLiveTv: null,
      searchSportsVod: null,
      activetab: 1,
      searchLiveTv: "Mega",
      searchSportVod: "Boxing",
      serachTvShow: "Dexter",
      searchMovie: "Bullet Train",
      loading: false,
      tabs: [
        { id: 1, text: "search.movies" },
        { id: 2, text: "search.tvShows" },
        { id: 3, text: "search.sportsVOD" },
        { id: 4, text: "search.channels" },
      ],
    };
  },
  computed: {
    ...mapState({
      isActive: (state) => state.infoMovie.isActive,
      brandImage: (state) => `/img/${state.config?.brandName}-logo.png`,
    }),
  },
  methods: {
    setActiveTab(tab) {
      this.activetab = tab;
    },
    async getDataMovies() {
      try {
        this.loading = true;
        this.searchMovies = null;
        await getSearchMovie(this.searchMovie).then((response) => {
          this.searchMovies = response.data.data;
          this.loading = false;
        });
      } catch (e) {
        console.log(e.message);
        this.loading = false;
      }
      this.searchMovie = "";
    },
    async getDataTvShows() {
      try {
        this.loading = true;
        (this.searchTvShows = null),
          await getSearchTvShow(this.serachTvShow).then((response) => {
            this.searchTvShows = response.data.data;
            this.loading = false;
          });
      } catch (e) {
        console.log(e.message);
        this.loading = false;
      }
      this.serachTvShow = "";
    },
    async getDataLiveTv() {
      try {
        this.loading = true;
        this.serachLiveTv = null;
        await getSearchLiveTv(this.searchLiveTv).then((response) => {
          this.serachLiveTv = response.data.data;
          this.loading = false;
        });
      } catch (e) {
        console.log(e.message);
        this.loading = false;
      }
      this.searchLiveTv = "";
    },
    async getDataSportsVod() {
      try {
        this.loading = true;
        this.searchSportsVod = null;
        await getSearchSportsVod(this.searchSportVod).then((response) => {
          this.searchSportsVod = response.data.data;
          this.loading = false;
        });
      } catch (e) {
        console.log(e.message);
        this.loading = false;
      }
      this.searchSportVod = "";
    },
  },
  mounted() {
    this.getDataMovies();
    this.getDataTvShows();
    this.getDataLiveTv();
    this.getDataSportsVod();
  },
};
</script>

<style lang="scss">

.db {
  &.show {
    height: 100.1vh;
    overflow: hidden;
  }
}
</style>
