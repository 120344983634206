<template>
  <div class="input-field">
    <input
      :type="type"
      class="input-field__input"
      :class="'input-field__input--' + size"
      :id="id"
    />
    <label
      :for="id"
      class="input-field__label"
      :class="'input-field__label--' + size"
      >{{ label }}
    </label>
  </div>
</template>

<script>
export default {
  name: "Checkbox",
  props: {
    label: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "checkbox",
    },
    size: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.input-field {
  display: flex;
  align-items: center;
  &__label {
    color: #b3b3b3;
    display: block;
    line-height: 1.2;
    margin-left: 5px;
    font-size: 13px;
    &--large {
      color: #333;
      display: block;
      line-height: 1.2;
      padding: 6px 0;
      position: relative;
      font-size: 16px;
      min-height: 20px;
    }
  }
  &__input {
    background-color: $dark-gray;
    border: 0;
    border-radius: 2px;
    height: 16px;
    left: -20px;
    width: 16px;
    &--large {
      border-radius: 50%;
      height: 25px;
      width: 25px;
    }
  }
}
</style>
