import { getSportsVodCategories, addTSportsVod, deleteSportsVod, getSportsCategory, getSearchGlobal } from "@/api/index.js";
import axios from "@/api/axios";
import { flatten, values } from "lodash";
import { commitGlobalSearch } from "../../../helpers/storage";

export default {
  state: {
    sportsCategories: null,
    isLoading: null,
    searchSports: null,
    categories: null,
    sportsCards: [],
    searchText: null,
  },
  actions: {
    async search({ commit, state }, { text }) {
      if (window.location.pathname.toLowerCase().startsWith("/sports")) {
        state.isLoading = true;
        if (text?.length) {
          const result = await getSearchGlobal(text);
          commitGlobalSearch(commit, text, result);
        } else {
          commit("setSearchSports", { text: null, sports: null });
        }
      }
    },
    async getSportsVodData({ commit, state, dispatch }) {
      if (!state.sportsCategories) {
        commit("getSportsStart");
        try {
          const response = await getSportsVodCategories();
          if (response.status !== 200) {
            throw new Error(`HTTP error: ${response.status}`);
          }
          state.categories = response.data.data;
          dispatch("getSportCards");
        } catch (error) {
          // commit('getAllCategoriesLiveTvFailure')
          console.error(`Could not get products: ${error}`);
        }
      }
    },
    async getSportCards({ commit, state }) {
      try {
        const offset = state.sportsCategories?.length || 0;
        let firstfiveCat = state.categories.slice(offset, 4 + offset);
        const sportCategoriesLength = Object.keys(firstfiveCat).length;
        const catRequests = [];
        for (let i = 0; i < sportCategoriesLength; i++) {
          const category = firstfiveCat[i];
          catRequests.push(getSportsCategory(category.id));
        }

        let categoriesCards = await axios.all(catRequests);
        categoriesCards = categoriesCards.map((item) => values(item.data.data));
        firstfiveCat = firstfiveCat.map((item, index) => {
          const categoryShows = categoriesCards[index] || [];
          return {
            ...item,
            infos: categoryShows.map((show) => show.id),
          };
        });

        commit("getTvSportsCardsSuccess", flatten(categoriesCards));
        commit("getSportsSuccess", firstfiveCat);
      } catch (error) {
        // commit('getAllCategoriesLiveTvFailure')
        console.error(`Could not get products: ${error}`);
      }
    },
    async switchFavouriteSport({ state }, { id, is_favorite }) {
      return new Promise((resolve) => {
        const promise = is_favorite ? deleteSportsVod(id) : addTSportsVod(id);
        promise
          .then((response) => {
            const itemIndex = state.sportsCards.findIndex((item) => item.id === id);

            state.sportsCards[itemIndex].is_favorite = !state.sportsCards[itemIndex].is_favorite;
            resolve(response);
          })
          .catch(() => {});
      });
    },
  },
  mutations: {
    getSportsStart(state) {
      state.isLoading = true;
      state.sportsCategories = null;
    },
    setSearchSports(state, payload) {
      state.searchSports = payload.sports;
      state.searchText = payload.text;
      state.isLoading = false;
    },
    getSportsSuccess(state, payload) {
      state.isLoading = false;
      if (state.sportsCategories) {
        state.sportsCategories.push(...payload);
      } else {
        state.sportsCategories = payload;
      }
    },
    getSportsFailure(state) {
      state.isLoading = false;
    },

    getTvSportsCardsSuccess(state, payload) {
      state.isLoading = false;
      state.sportsCards.push(...payload);
    },
  },
  getters: {
    favouriteLisTvSports: (state) => {
      return state.sportsCards?.filter((card) => card.is_favorite);
    },
  },
};
