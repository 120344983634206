import { getTvShowsCategories, addTvShow, deleteTvShow, getSearchGlobal } from "@/api/index.js";
import { commitGlobalSearch } from "@/helpers/storage";

// import {arrayFilterUniqueItemsById } from '@/helpers/storage'
export default {
  state: {
    categories: [],
    // tvShows:[],
    isLoading: false,
    error: null,
    searchTvShows: null,
    searchText: null,
  },
  actions: {
    async search({ commit }, { text }) {
      if (window.location.pathname.toLowerCase().startsWith("/tvshows")) {
        // state.searchText = text;
        // state.isLoading = true;
        commit("setTvShowSearchLoading", text);
        if (text?.length) {
          const result = await getSearchGlobal(text);
          commitGlobalSearch(commit, text, result);
        } else {
          commit("setSearchTvShows", null);
          //   state.searchTvShows = null;
          // state.isLoading = false;
        }
      }
    },
    async initTvShowsLoad({ dispatch }, { offset, size, toContinue }) {
      await dispatch("getFirstTvShowCategories", { offset, size, toContinue });
    },
    async loadMoreTVShowCategories({ commit }, { offset, size }) {
      commit("getAllCategoriesTvShowsStart");
      try {
        const response = await getTvShowsCategories(offset, size);

        if (response.status !== 200) {
          throw new Error(`HTTP error: ${response.status}`);
        }

        let categoriesList = response.data.data;
        commit("loadMoreCategoriesSuccess", categoriesList);
      } catch (error) {
        commit("loadMoreCategoriesSuccess");
        console.error(`Could not get products: ${error}`);
      }
    },
    async getFirstTvShowCategories({ commit }, { offset, size, toContinue }) {
      commit("getAllCategoriesTvShowsStart");
      try {
        let commitFunction = "getAllCategoriesTvShowsSuccess";
        do {
          const response = await getTvShowsCategories(offset, size);

          if (response.status !== 200) {
            throw new Error(`HTTP error: ${response.status}`);
          }

          commit(commitFunction, response.data.data);

          if (toContinue && response.data.data.length === size) {
            offset += size;
            commitFunction = "getAllCategoriesTvShowsSuccess";
          } else toContinue = false;
        } while (toContinue);
      } catch (error) {
        console.error(`Could not get products: ${error}`);
      }
    },
    // fillTvShowStore({ commit }, { tvShowsList }) {
    //     commit('getTvShowsByCategoryStart')
    //     try {
    //         const filteredTvShows = arrayFilterUniqueItemsById(tvShowsList, 'imdb_id');
    //         commit('getTvShowsByCategorySuccess', filteredTvShows)
    //
    //     } catch (error) {
    //         commit('getTvShowsByCategoryFailure')
    //         console.error(`Could not get tvShows: ${error}`);
    //     }
    // },
    //
    // getItemIdsListByCategory({ state, }, { category_id })  {
    //
    //     const findCategory = state.categories.filter(category => category.id === category_id)
    //     console.log('findCategory.infos')
    //     console.log(findCategory.infos)
    //
    // },
    async switchFavourite({ state }, { item_id, is_favorite }) {
      return new Promise((resolve) => {
        const promise = is_favorite ? deleteTvShow(item_id) : addTvShow(item_id);
        promise
          .then((response) => {
            // commit(mutationTypes.addToFavoritesTvShowSuccess, response)
            const itemIndex = state.tvShows.findIndex((item) => item.imdb_id === item_id);

            state.tvShows[itemIndex].is_favorite = !state.tvShows[itemIndex].is_favorite;
            resolve(response);
          })
          .catch(() => {
            // commit(mutationTypes.addToFavoritesTvShowFailure())
          });
      });
    },
  },
  mutations: {
    setTvShowSearchLoading(state, payload) {
      state.searchText = payload;
      state.isLoading = true;
    },
    setSearchTvShows(state, payload) {
      state.searchTvShows = payload;
      state.isLoading = false;
    },
    getAllCategoriesTvShowsStart(state) {
      state.isLoading = true;
      state.categories = [];
    },
    getAllCategoriesTvShowsSuccess(state, payload) {
      state.isLoading = false;
      state.categories = payload;
    },
    getAllCategoriesTvShowsFailure(state) {
      state.isLoading = false;
    },

    getTvShowsByCategoryStart(state) {
      state.isLoading = true;
      state.tvShows = null;
    },

    loadMoreCategoriesStart(state) {
      state.isLoading = true;
    },

    loadMoreCategoriesSuccess(state, payload) {
      state.isLoading = false;
      state.categories.push(...payload);
    },

    getTvShowsByCategorySuccess(state, payload) {
      state.isLoading = false;
      state.tvShows = payload;
    },
    getTvShowsByCategoryFailure(state) {
      state.isLoading = false;
    },
  },
  getters: {
    favouriteTvShows: (state) => {
      return state.tvShows?.filter((tvShow) => tvShow.is_favorite);
    },
  },
};
