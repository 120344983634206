<template>
  <svg
    class="card__icon"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      d="M12 0a1.5 1.5 0 011.5 1.5v9h9a1.5 1.5 0 110 3h-9v9a1.5 1.5 0 11-3 0v-9h-9a1.5 1.5 0 110-3h9v-9A1.5 1.5 0 0112 0z"
    />
  </svg>
</template>

<script>
export default {
  name: "Add",
};
</script>
<style lang="scss" scoped>
.card {
  &__icon {
    cursor: pointer;
    width: 0.6rem;
    height: 0.6rem;
    fill: $white;
    &--black {
      fill: $black;
    }
    @media (min-width: 768px) and (max-width: 1023px) {
      width: 0.8rem;
      height: 0.8rem;
    }
    @media (min-width: 1024px) {
      width: 1rem;
      height: 1rem;
    }
    &-sport {
      width: 1.2rem;
      height: 1.2rem;
    }
  }
}
</style>
