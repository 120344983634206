import { createApp } from "vue";
import { createHead } from '@unhead/vue';
import App from "@/App.vue";
import { store } from "@/store";
import i18n from "@/i18n";
import router from "@/router";
import "@/assets/styles/index.scss";
import VueVideoPlayer from '@videojs-player/vue';
import 'video.js/dist/video-js.css';
import VueI18n from 'vue-i18n';
import VScrollLock from 'v-scroll-lock';

const app = createApp(App);
const head = createHead();

app.use(store).use(router).use(VueVideoPlayer).use(VScrollLock).use(VueI18n).use(i18n).use(head).mount("#app");
