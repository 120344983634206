<template>
  <div v-if="unsupported" class="video-unsupported">
    <Unsupported></Unsupported>
  </div>
  <video
    ref="player"
    v-else
    autoplay
    controls
    :src="linkVideo"
    type="video/x-matroska"
    class="video-js video-player"
    @playing="onPlaying"
    @pause="$emit('pause')"
    @timeupdate="$emit('timeupdate', $event)"
  >
    <source :src="linkVideo" type="video/x-matroska" />

    <embed id="divxplayer" type="video/divx" width="1024" height="768" :src="linkVideo" autoPlay="true" pluginspage="http://go.divx.com/plugin/download/" />
  </video>
</template>

<script>
// import videojs from "video.js";
import 'video.js/dist/video-js.css';
import Unsupported from '@/views/Player/Unsupported';
import { getAudioTest } from '@/helpers/utils';

export default {
  name: 'PlayerMkv',
  components: {
    Unsupported,
  },
  props: {
    linkVideo: {
      type: String,
    },
    textTrack: {
      type: Array,
    },
  },
  data() {
    return {
      player: null,
      unsupported: false,
      videoSrc: '',
      type: 'video/webm',
      videoOptions: {
        autoplay: true,
        controls: true,
        preload: true,
        bigPlayButton: false,
        children: ['textTrackDisplay'],
      },
    };
  },
  mounted() {
    const player = this.$refs.player;
    player.addEventListener('error', () => {
      this.unsupported = true;
    });
  },
  methods: {
    async onPlaying(){
      const player = this.$refs.player;
      const audioIsWorking = await getAudioTest(player);
      if(!audioIsWorking) {
        // this.unsupported = true;
        console.error('audio is not working');
      } else {
        this.unsupported = false;
        this.$emit('playing');
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.video-unsupported {
  background-color: $black50;
  padding-top: 100px;
  z-index: 20;
  position: relative;
  top: 0;
  height: 100vh;
}
.video-player {
  background-color: $black;
  width: 100%;
  height: 100vh;
  &__select {
    display: flex;
    position: absolute;
    top: 20px;
    z-index: 100;
    left: 20px;
    &-episode {
      width: 100px !important;
      border-radius: 2px;
      margin-left: 10px;
    }
    &-season {
      width: 100px !important;
      border-radius: 2px;
    }
  }
}
</style>
