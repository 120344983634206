import axios from '@/api/axios';

const host = window.location.hostname;
let baseURL = process.env.VUE_APP_BACKOFFICE_URL ?? `https://public.${host}/`;
if (host.includes('webplayer') && !host.includes('testing')) {
  baseURL = `https://public.${host.replace('webplayer.', '')}/`;
}

//auth
export const guidLogin = (credentials) => {
  return axios.post(baseURL + 'api/public/guidLogin', credentials);
};

export const getTrialMovie = (id) => {
  return axios.post(baseURL + `api/public/getMovie?id=${id}`);
};

export const getBrandConfig = () => {
  return axios.post(baseURL + 'api/public/getBrandConfig');
};

export const updateWatchTime = ({ imdbId, durationInSec, leadId }) => {
  return axios.post(baseURL + `api/public/updateWatchTime`, {
    imdbId,
    durationInSec,
    leadId,
  });
};

export const leadSignup = ({ guid, leadId, payload, captchaToken }) => {
  return axios.post(baseURL + `api/public/leadSignup`, {
    guid,
    leadId,
    payload,
    captchaToken,
  });
};

export const sendPhoneVerificationCodeByGuid = ({ guid, leadId, phone, channel, captchaToken }) => {
  return axios.post(baseURL + `api/public/sendPhoneVerificationCodeByGuid`, {
    guid,
    leadId,
    phone,
    channel,
    captchaToken,
  });
};

export const getLcWidget = (brand) => {
  return axios.get(baseURL + `api/public/getLcWidget?brand=${brand}`);
};
