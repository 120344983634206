<template>
  <div
    class="profiles-gate-container"
    :class="{
      'profiles-gate-container--scroll':
        this.$store.state.profiles.isEditProfile,
    }"
  >
    <div
      class="centered-div animation"
      :class="{
        'animation-edit': this.$store.state.profiles.isEditProfile,
      }"
    >
      <AddProfile v-if="addProfile" />
      <AllProfiles v-if="!addProfile && !editProfile" />
      <EditProfile v-if="editProfile" />
    </div>
  </div>
</template>

<script>
// import { actionTypes } from "@/store/modules/manageProfile";
import { mapState } from "vuex";
import AllProfiles from "@/views/profiles/ManageProfiles/components/AllProfiles/AllProfiles";
import AddProfile from "@/views/profiles/ManageProfiles/components/AddProfile/AddProfile";
import EditProfile from "@/views/profiles/ManageProfiles/components/EditProfile/EditProfile";
import "./style.scss";

export default {
  name: "ManageProfiles",
  components: {
    AllProfiles,
    AddProfile,
    EditProfile,
  },
  computed: {
    ...mapState({
      addProfile: (state) => {
        return state.profiles.isAddProfile;
      },
      editProfile: (state) => {
        return state.profiles.isEditProfile;
      },
    }),
  },
};
</script>

<style lang="scss" scoped>
.profiles {
  &-gate {
    &-container {
      background: $black10;
      bottom: 0;
      left: 0;
      position: fixed;
      right: 0;
      text-align: center;
      top: 0;
      z-index: 0;

      &--scroll {
        position: static;
      }

      @include sm-max {
        max-width: none;
      }
    }
  }
}

.animation {
  opacity: 1;
  transform: scale(1);
  transition-duration: 450ms;
  transition-delay: 200ms;

  &-edit {
    position: initial;
  }
}
</style>
