// import { random } from "lodash";

const OnIntersect = {
  mounted($el, binding) {
    if (!('IntersectionObserver' in window)) {
      binding.instance[binding.value] = false;
      return;
    }
    binding.instance.observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].intersectionRatio) {
          binding.instance.observer.unobserve($el);
          binding.instance.observer.disconnect();
          binding.instance[binding.value] = false;//random(1);
        }
      },
      {
        root: null,
        threshold: "0",
      }
    );

    binding.instance.observer.observe($el);
  },
  unmounted($el, binding) {
    binding.instance.observer.unobserve($el);
    binding.instance.observer.disconnect();
  },
}

export default OnIntersect;