<template>
  <video-player
    id="CustomVideoPlayer"
    class="video-player video"
    crossorigin="anonymous"
    playsinline
    ref="elementRef"
    controls
    autoplay="true"
    :tracks="textTracks"
    :src="linkVideo"
    volume="0.4"
    @playing="$emit('playing')"
    @pause="$emit('pause')"
    @timeupdate="$emit('timeupdate', $event)"
    :children="[
      'mediaLoader',
      'loadingSpinner',
      'controlBar',
      'textTrackDisplay',
    ]"
    :control-bar="{
      volumePanel: true,
    }"
  />
</template>

<script>
import { defineComponent } from "vue";
import { VideoPlayer } from "@videojs-player/vue";
import "video.js/dist/video-js.css";

export default defineComponent({
  components: {
    VideoPlayer,
  },
  props: {
    linkVideo: {
      type: String,
    },
    textTracks: {
      type: Array,
    },
  },
});
</script>

<style lang="scss" scoped>
.video-player {
  background-color: $black;
  width: 100%;
  height: 100vh;
}
</style>
