export default [
  {
    name: 'English',
    value: 'en',
  },
  {
    name: 'Українська',
    value: 'uk',
  },
  {
    name: 'Dansk',
    value: 'da',
  },
  {
    name: 'polski',
    value: 'pl',
  },
  {
    name: '粵語',
    value: 'yue',
  }
]