import _ from "lodash";

export const getDefaultCountry = () => {
    const country = localStorage.getItem("defaultCountry");
    if (country) {
        return JSON.parse(country);
    }

    return { countryCode: 25, countryName: "US" };
};

const getCurrentClientCountryIp2c = async () => {
    try {
        const response = await fetch('https://ip2c.org/s');
        const res = await response.text();

        return {
            code: _.split(res, ';')[1] || null,
            name: _.split(res, ';')[3] || null
        };
    } catch {
        return null;
    }
};

const getCurrentClientCountryDbIp = async () => {
    try {
        const response = await fetch('https://api.db-ip.com/v2/free/self');
        const res = await response.json();
        if (res?.countryCode) {
            return {
                code: res.countryCode,
                name: res.countryName,
            };
        }
    } catch {
        return null;
    }
};

export const getDefaultCountryFromIp = async () => {
    const country = localStorage.getItem("defaultCountry");
    if (country) return null;
    try {
        const ipData = await Promise.race([getCurrentClientCountryIp2c(), getCurrentClientCountryDbIp()]);
        if (ipData?.code) {
            return { name: ipData?.name, code: ipData?.code }
        }
    } catch (ex) {
        console.error(ex);
        return null;
    }
};
