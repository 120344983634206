<template>
  <div class="hero-card">
    <div class="hero-card__text">
      <h1 class="story-title">{{ $t("hero.title") }}</h1>
      <h2 class="story-subtitle">{{ $t("hero.subTitle") }}</h2>
      <div>
        <EmailForm />
      </div>
    </div>
  </div>
</template>

<script>
import EmailForm from "@/components/EmailForm";

export default {
  name: "Hero",
  components: {
    EmailForm,
  },
};
</script>

<style lang="scss" scoped>
.hero-card {
  &__text {
    margin: 0 auto;
    max-width: 950px;
    padding: 75px 0;
    position: relative;
    text-align: center;
    width: 100%;
    z-index: 1;
  }
}
</style>
