<template>
  <div class="story-card-wrap">
    <AnimationCard
      :title="$t('cardAnimation.watchOnTv.title')"
      :subTitle="$t('cardAnimation.watchOnTv.subTitle')"
      :item="watchOnTv"
    />
  </div>
  <div class="story-card-wrap">
    <AnimationCard
      :title="$t('cardAnimation.downLoadAndWatch.title')"
      :subTitle="$t('cardAnimation.downLoadAndWatch.subTitle')"
      direction="row-reverse"
      :item="watchOnDevice"
    />
  </div>
  <div class="story-card-wrap">
    <AnimationCard
      :title="$t('cardAnimation.watchOnDevice.title')"
      :subTitle="$t('cardAnimation.watchOnDevice.subTitle')"
      :item="downloadAndWatch"
    />
  </div>
  <div class="story-card-wrap">
    <AnimationCard
      :title="$t('cardAnimation.kidsCard.title')"
      :subTitle="$t('cardAnimation.kidsCard.subTitle')"
      direction="row-reverse"
      :item="kidsCard"
      ><KidsCard
    /></AnimationCard>
  </div>
</template>

<script>
import { markRaw } from "vue";
import AnimationCard from "@/components/AnimationCard";
import KidsCard from "@/components/KidsCard";
import WatchOnTv from "@/components/WatchOnTv";
import WatchOnDevice from "@/components/WatchOnDevice";
import DownloadAndWatch from "@/components/DownloadAndWatch";

export default {
  name: "CardsSection",
  components: {
    AnimationCard,
  },
  computed: {
    kidsCard() {
      return markRaw(KidsCard);
    },
    watchOnTv() {
      return markRaw(WatchOnTv);
    },
    downloadAndWatch() {
      return markRaw(DownloadAndWatch);
    },
    watchOnDevice() {
      return markRaw(WatchOnDevice);
    },
  },
};
</script>

<style></style>
