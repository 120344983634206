import videojs from 'video.js';

export function isAndroid(){
	return videojs.browser.IS_ANDROID;
}

export function isIOS(){
	return videojs.browser.IS_IOS;
}

// https://stackoverflow.com/questions/21270048/html5-video-how-to-detect-when-there-is-no-audio-track
export function hasAudio (video) {
	return video.mozHasAudio ||
		Boolean(video.webkitAudioDecodedByteCount) ||
		Boolean(video.audioTracks && video.audioTracks.length);
}
export const getAudioTest = async (player) => {
  return new Promise ((resolve) => {
    const timeout = setTimeout(() => {
        if (
          (typeof player?.mozHasAudio !== "undefined" && player?.mozHasAudio) ||
          (typeof player?.webkitAudioDecodedByteCount !== "undefined" && player?.webkitAudioDecodedByteCount > 0) ||
          Boolean(player?.audioTracks?.length)
        ) {
          clearTimeout(timeout);
          resolve(true);
        } else {
          clearTimeout(timeout);
          resolve(false);
        }
    }, 2000);
  });
};