<template>
  <div class="explations">
    <article class="article-body" id="article-body" rel="image-enlarge">
      <p>
        {{ $t('installation.android.useApollo') }}
        <strong>"{{ $t('installation.appName') }}"</strong>
        {{ $t('installation.android.useAndInstall') }}
      </p>
      <p><br /></p>

      <p v-if="credentials?.m3uLink">
        {{ $t('installation.YourlinkLookLike') }}: <a>{{ credentials?.m3uLink }}</a>
      </p>
      <p>
        {{ $t('installation.android.toLogin') }}
        <strong>{{ $t('installation.linkUpdateDate') }}</strong>
      </p>

      <p>
        <a href="tvnow.best/api/list/EMAIL/PASSWORD">
          tvnow.best/api/list/<strong> {{ $t('installation.email').toLocaleUpperCase() }}</strong
          >/<strong>{{ $t('installation.password').toLocaleUpperCase() }}</strong></a
        >
      </p>
      <p><br /></p>
      <p>{{ $t('instalation.exampleBob') }}</p>
      <p>
        {{ $t('installation.email') }}:
        <a class="email" href="/cdn-cgi/l/email-protection#caa8a5a8f9f98aada7aba3a6e4a9a5a7"><span>bob33@gmail.com</span></a>
      </p>
      <p>{{ $t('installation.password') }}: 123bb1</p>
      <p>
        <br />
      </p>
      <p v-if="credentials?.m3uLink">
        {{ $t('installation.YourlinkLookLike') }}: <a>{{ credentials?.m3uLink }}</a>
      </p>
      <br />
      <p>{{ $t('installation.linkLookLike') }}:</p>
      <p>
        <a href="tvnow.best/api/list/EMAIL/PASSWORD"> tvnow.best/api/list/<span>bob33@gmail.com</span>/123bb1</a>
      </p>
      <p><br /></p>
      <p>
        {{ $t('installation.android.addHttp') }}
      </p>
      <p>
        <a href="tvnow.best/api/list/EMAIL/PASSWORD">tvnow.best/api/list/{{ $t('installation.email').toLocaleUpperCase() }}/{{ $t('installation.password').toLocaleUpperCase() }}</a>
      </p>
      <p><br /></p>
      <p><br /></p>
      <p>
        <img src="@/assets/img/androidImg.png" width="589" />
      </p>
    </article>
  </div>
</template>

<script>
export default {
  name: 'Android',
  props: {
    credentials: {
      type: Object,
      default: () => null,
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  margin-top: 5px;
  img {
    width: 100%;
  }
}
.email {
  color: $gray70;
}
</style>
