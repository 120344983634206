import {
  getTodaysTvGuideChannels,
  getTvGuide,
  getTvGuideChannels
} from "@/api/index.js";

export default {
  state: {
    tvGuide: null,
    isLoadingGuid: null,
    isLoadingChannels: null,
    tvGuideChannels: null,
  },

  actions: {
    async getInfoTvGuide({ commit, dispatch }, { id }) {
      commit('getTvGuideStart')
      try {
        const response = await getTvGuide(id);

        if (response.status !== 200) {
          throw new Error(`HTTP error: ${response.status}`);
        }
        const ids = response.data.data.map((item) => item.id).join(",");
        dispatch('getInfoTvGuideChannelsTodays', { ids })

        // dispatch('getInfoTvGuideChannels', { id })

        commit('getTvGuideSuccess', response.data.data)
      } catch (error) {
        commit('getTvGuideFailure')
        console.error(`Could not get products: ${error}`);
      }
    },

    async getInfoTvGuideChannels({ commit }, { id }) {

      try {
        commit('getTvGuideChannelsStart')
        const response = await getTvGuideChannels({ categoryId: id });
        if (response.status !== 200) {
          throw new Error(`HTTP error: ${response.status}`);
        }

        fetch(response.data.data.link)
          .then(r => r.json())
          .then(json => {
            commit('getTvGuideChannelSuccess', json.data)
          });
      } catch (error) {

        console.error(`Could not get products: ${error}`);
      }
    },
    async getInfoTvGuideChannelsTodays({ commit }, { ids }) {

      try {
        const response = await getTodaysTvGuideChannels(ids);
        if (response.status !== 200) {
          throw new Error(`HTTP error: ${response.status}`);
        }
        commit('getTvGuideChannelSuccess', response.data.data)
      } catch (error) {

        console.error(`Could not get products: ${error}`);
      }
    }
  },
  mutations: {
    getTvGuideStart(state) {
      state.isLoadingGuild = true
      state.tvGuide = null
    },
    getTvGuideSuccess(state, payload) {
      state.tvGuide = payload
      state.isLoadingGuild = false
    },
    getTvGuideFailure(state) {
      state.isLoadingGuild = false
    },
    getTvGuideChannelsStart(state) {
      state.isLoadingChannels = true
      state.tvGuideChannels = null
    },

    getTvGuideChannelSuccess(state, payload) {
      state.isLoadingChannels = false
      state.tvGuideChannels = payload
    },

  },
}



