<template>
  <div class="wrapper wrapper-bottom" @mouseenter="loadVideo" @mouseleave="clearVideo" v-on-intersect="'isHidden'">
    <div class="card card--rounded wrapper__back">
      <div class="card__header" :id="videoId" @click="showInfo">
        <img :src="getFanArt" :alt="item?.title" loading="lazy" />
      </div>
      <span class="item_title">{{ item.title }}</span>
      <div :class="mouseOn ? 'card__body' : 'hide'">
        <div class="flex justify-between">
          <div class="card__body--left">
            <button @click="handlePlayVideo(item.type)" class="play-btn round-btn">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" class="card__icon" fill="none" viewBox="0 0 24 24">
                <path d="M21.44 10.72L5.96 2.98A1.38 1.38 0 004 4.213v15.474a1.373 1.373 0 002 1.233l15.44-7.74a1.38 1.38 0 000-2.467v.007z" />
              </svg>
            </button>
            <div>
              <button @click="handleAddFavoriteMovie(item.type, item.imdb_id, item.is_favorite)" class="info-btn round-btn">
                <Ok v-if="item.is_favorite" class="card__icon" />
                <svg v-if="!item.is_favorite" class="card__icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                  <path d="M12 0a1.5 1.5 0 011.5 1.5v9h9a1.5 1.5 0 110 3h-9v9a1.5 1.5 0 11-3 0v-9h-9a1.5 1.5 0 110-3h9v-9A1.5 1.5 0 0112 0z" />
                </svg>
              </button>
            </div>
          </div>
          <button @click="showInfo" class="info-btn round-btn">
            <svg xmlns="http://www.w3.org/2000/svg" class="card__icon" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
              <path fill-rule="evenodd" d="M2.469 6.969a.75.75 0 011.062 0L12 15.439l8.469-8.47a.75.75 0 111.062 1.062l-9 9a.75.75 0 01-1.062 0l-9-9a.75.75 0 010-1.062z" clip-rule="evenodd" />
            </svg>
          </button>
        </div>
        <div class="card__body--tags-item">
          <div class="card__body--second-line">
            <div class="year">
              {{ item.meta?.year }}
            </div>
            <span v-if="item.meta?.mppa" class="videoMetadata__maturity-rating">
              <span class="maturity-number">
                {{ item.meta?.mppa }}
              </span>
            </span>
            <div>
              <span v-if="Movie($route.path)" class="duration">{{ getTimeFromMins }}</span>
              <span v-if="TvShow($route.path)" class="duration">{{ getEpisodes }}</span>
            </div>
            <span class="player-feature-badge">HD</span>
          </div>
        </div>
        <div v-show="!item?.meta?.genres" class="card__body--tags-item">
          <p>{{ item?.meta?.genres }}</p>
        </div>
      </div>
    </div>
    <div class="card card--rounded wrapper__front" :class="hasImage ? '' : 'loading'">
      <div class="card__header">
        <img :src="getFanArt" :alt="item?.title" loading="lazy" />
      </div>
      <span class="item_title">{{ item.title }}</span>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { videoTeleportStore } from "@/components/Slider/VideoTeleport";
import OnIntersect from "@/components/OnIntersect";
import Ok from "@/assets/icons/svg/ok.svg";
import noImage from "@/assets/img/noImage.png";

export default {
  name: "SliderItem",
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    category: {
      type: String,
      default: "",
    },
    categoryId: {
      type: Number,
    },
    click: {
      type: Function,
      default: () => {},
    },
    brandImage: {
      type: String,
      default: noImage,
    },
  },
  directives: {
    OnIntersect,
  },
  components: {
    Ok,
  },
  data() {
    return {
      videoTeleportStore,
      defaultLanguage: "en",
      isHidden: true,
      hasImage: false,
      mouseOn: false,
    };
  },

  watch: {
    // whenever question changes, this function will run
    isHidden(isHidden) {
      if (!isHidden) {
        const img = new Image();
        img.src = this.item.meta?.fanart || "/no-image-available.png";
        img.onload = () => {
          this.hasImage = true;
        };
      }
    },
  },
  computed: {
    getFanArt() {
      return this.hasImage && this.item.meta?.fanart ? this.item.meta?.fanart : this.$props.brandImage;
    },
    getEpisodes() {
      if (this.item?.meta?.seasons) {
        const seasonsLength = Object.keys(this.item?.meta.seasons).length;
        if (seasonsLength === 1) {
          return `${seasonsLength} Season`;
        }
        return `${seasonsLength} Seasons`;
      } else return "0 Seasons";
    },
    getTimeFromMins() {
      let hours = Math.trunc(this.item.meta?.runtime / 60);
      let minutes = this.item.meta?.runtime % 60;
      return hours + "h " + minutes + "m";
    },
    videoId() {
      return `video-wrapper-${this.category.toLowerCase().replace(" ", "-")}-${this.item.trakt_id}`;
    },
  },
  methods: {
    loadVideo() {
      this.mouseOn = true;
      // if (!this.item.meta.trailer) {
      //   return;
      // }
      // videoTeleportStore.setId(this.videoId);
      // videoTeleportStore.setData(this.item.meta);
    },
    clearVideo() {
      setTimeout(() => {
        this.mouseOn = false;
      }, 650);
      videoTeleportStore.setId(``);
      videoTeleportStore.setData({});
    },
    showInfo() {
      this.$store.commit("handleGetInfoMovie", this.item);
      this.$store.commit("handleShowInfoMovie", true);
    },
    async handleAddFavoriteMovie(typeCard, id, favorite) {
      if (typeCard === "movie") {
        this.$store.dispatch("toggleMovieInFavorite", {
          section: "movies",
          id,
        });
        this.$store.dispatch("switchFavouriteMovies", {
          item_id: id,
          category: this.categoryId,
          is_favorite: favorite,
        });
      }
      if (typeCard === "tvshow") {
        this.$store.dispatch("toggleMovieInFavorite", {
          section: "tvShows",
          id,
        });
        this.$store.dispatch("switchFavourite", {
          item_id: id,
          is_favorite: favorite,
        });
      }
    },
    handlePlayVideo(type) {
      type === "movie" ? this.handlePlayVideoMovie() : this.handlePlayVideoTvShows();
    },
    handlePlayVideoMovie() {
      this.$router.push({
        name: "Video",
        params: { slug: this.item.meta.title },
        query: {
          trackId: this.item.imdb_id,
          type: "getLinkMovie",
        },
      });
    },
    handlePlayVideoTvShows() {
      this.$router.push({
        name: "Video",
        params: { slug: this.item.meta.title },
        query: {
          trackId: this.item.imdb_id,
          season: 1,
          episode: 1,
          type: "getLinkTvShow",
        },
      });
    },
    Movie(route) {
      if (route === "/movies") {
        return true;
      }
    },
    TvShow(route) {
      if (route === "/tvshows") {
        return true;
      }
    },
    ...mapState({
      autoReview: (state) => {
        return state.profiles.autoStartPreview;
      },
      getBrandLogo: (state) => `/img/${state.config?.brandName}-logo.png`,
    }),
  },
  mounted() {
    console.log(this.$props.brandImage);
  },
};
</script>
<style>
.wrapper__front > .card__header,
.wrapper__front.loading > .card__header {
  min-height: 125px;
  & > img {
    width: 100% !important;
    object-fit: scale-down;
    aspect-ratio: 16/9;
  }
}
.card__header .item_title {
  font-size: 14px;
  /* position: relative; */
  text-align: center;
  width: 100%;
  top: 5px;
}
</style>
<style scoped>
.hide {
  display: none;
}
</style>
