<template>
  <div class="focus-trap-wrapper previewModal--wrapper detail-modal show">
    <div class="previewModal--container detail-modal mm">
      <div class="previewModal--player_container">
        <iframe :src="getSource()" width="100%"></iframe>
      </div>
      <div class="previewModal-close">
        <Close class="previewModal-close-icon" @click="click" />
      </div>
      <div class="previewModal--info">
        <div class="detail-modal-container"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Close from "@/assets/icons/svg/close.svg";
import { mapState } from "vuex";

export default {
  name: "TrialOver",
  props: {
    click: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...mapState({
      lead: (state) => {
        return state.auth.lead;
      },
    }),
  },
  components: {
    Close,
  },
  methods: {
    getSource() {
      // return `http://localhost:8082/Register?closeOnSignIn=true&leadId=${this.lead}`; //&username=${this.lead.email}&firstName=${this.lead.firstName}&phone=${this.lead.phone}&lastName=${this.lead.lastName}&subSource=${this.lead.subSource}&promoCode=${this.lead.promoId}`;
      return `${this.$store.config?.portalLink}/Register?closeOnSignIn=true&leadId=${this.lead}`;
    },
  },
  mounted() {
    window.addEventListener("message", this.click);
  },
  beforeUnmount() {
    window.removeEventListener("message", this.click);
  },
};
</script>

<style lang="scss" scoped>
.previewModal--container {
  width: 85vw;
  max-height: 85vh;
  min-height: 80vh;
  top: 80px;
  background-color: #000000;
  iframe {
    height: 80vh;
  }
}
.show {
  opacity: 1;
  visibility: visible;
  transition: all 0.5s;
}
</style>
