<template>
  <div class="story-image-container">
    <div class="download-and-watch">
      <div class="download-and-watch__image">
        <img src="@/assets/img/device-pile.png" alt="watch on tv" />
      </div>
      <div class="download-and-watch__video">
        <video
          src="https://assets.nflxext.com/ffe/siteui/acquisition/ourStory/fuji/desktop/video-devices.m4v"
        ></video>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DownloadAndWatch",
};
</script>

<style lang="scss" scoped>
.download-and-watch {
  overflow: hidden;
  position: relative;
  &__image {
    position: relative;
    z-index: 2;
    img {
      border: 0;
      height: auto;
      max-width: 100%;
    }
  }
  &__video {
    height: 100%;
    left: 50%;
    max-height: 47%;
    max-width: 63%;
    position: absolute;
    top: 34%;
    transform: translate(-50%, -50%);
    width: 100%;
    video {
      height: 100%;
      width: 100%;
    }
  }
}
</style>
