<template>
  <div class="content">
    <div class="guid_selector">
      <div>
        <div>{{ $t('comingSoon.installationGuid') }}</div>
        <div class="types" :class="[activetab === 2 ? 'reverse' : '']">
          <div class="device_type" @click="setActiveTab(1)" :class="[activetab === 1 ? 'active' : '']">
            {{ $t('comingSoon.apple') }}
            <Apple class="previewModal--icon" />
          </div>
          <div class="device_type" @click="setActiveTab(2)" :class="[activetab === 2 ? 'active' : '']">
            {{ $t('comingSoon.android') }}
            <Android class="previewModal--icon" />
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <AppleInstallationGuid v-if="activetab === 1" :credentials="credentials"></AppleInstallationGuid>
      <AndroidInstallationGuid v-if="activetab === 2" :credentials="credentials"></AndroidInstallationGuid>
    </div>
  </div>
</template>

<script>
import AppleInstallationGuid from '@/components/InstallationGuides/AppleInstallationGuid.vue';
import AndroidInstallationGuid from '@/components/InstallationGuides/AndroidInstallationGuid.vue';
import Android from '@/assets/icons/svg/android.svg';
import Apple from '@/assets/icons/svg/apple.svg';
import { mapGetters } from 'vuex';


export default {
  name: 'Installation',
  components: {
    Android,
    Apple,
    AppleInstallationGuid,
    AndroidInstallationGuid,
  },
  data() {
    return {
      activetab: 0,
      lockButton: false,
    };
  },
  computed: {
    ...mapGetters({ leadId: 'leadId', credentials: '[auth] credentials' }),
  },
  methods: {
    setActiveTab(tab) {
      this.activetab = tab;
    },
  },
};
</script>

<style lang="scss" scoped>
.guid_selector {
  text-align: center;
  color: $text-red;
  font-size: 22px;
  .types {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    color: $gray500;
    font-size: 18px;
    margin: auto;
    &.reverse {
      flex-direction: row-reverse;
    }
    .device_type {
      cursor: pointer;
    }
    .active {
      color: $text-red;
    }
    .previewModal--icon {
      height: 30px;
      margin: auto;
    }
  }
}

.mainView {
  margin: auto 0;
  padding-top: 230px;
  padding-inline-start: 4%;
  padding-inline-end: 4%;

  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mainView.active {
  display: block;
  .mainView-aligned {
    font-size: 16px;
    align-items: flex-start;
  }
  .device_type {
    font-size: 16px;
    cursor: pointer;
  }
  .active {
    font-size: 22px;
    color: $text-red;
  }
  .guid_selector {
    align-items: flex-start;
    text-align: start;
  }
  .types {
    justify-content: space-between;
  }
}
.mainView-aligned {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  flex-direction: column;
  font-size: 24px;
}

.content {
  margin-top: 20px;
  font-size: 14px;
  color: $gray70;
  width: 600px;
}
.unsupported-ending {
  color: $white10;
  margin: auto 0;
  text-align: center;
  font-size: 14px;
}
</style>
