<script setup>
import { ref, defineProps, defineEmits, defineExpose } from 'vue';
import Close from '@/assets/icons/svg/close.svg';

const dialog = ref();

const props = defineProps({
  confirmText: {
    type: String,
    default: 'Confirm'
  },
  cancelText: {
    type: String,
    default: 'Cancel'
  },
  hideConfirm: {
    type: Boolean,
    default: false
  },
  showCancel: {
    type: Boolean,
    default: false
  },
  classes: {
    type: String,
    default: ''
  }
});

const emit = defineEmits(['confirm', 'cancel']);

const cancel = () => {
  dialog.value?.close();
  emit('cancel');
};

const confirm = () => {
  dialog.value?.close();
  emit('confirm');
};

const visible = ref(false);

const showModal = () => {
  dialog.value?.showModal();
  visible.value = true;
};

defineExpose({
  show: showModal,
  close: (returnVal) => dialog.value?.close(returnVal),
  visible
});
</script>

<template>
  <dialog
      ref="dialog"
      class="generic-dialog"
      @close="visible = false"
  >
    <Close width="20" height="20" viewBox="0 0 24 24" class="close-btn" v-on:click="cancel"/>
    <div
        v-if="visible"
        method="dialog"
        :class="{
        'modal-box rounded-none p-4': true,
        [props.classes]: props.classes,
      }"
    >
      <slot/>

      <div class="modal-action" v-if="!props.hideConfirm || props.showCancel">
        <slot name="footer"/>
        <slot name="actionButtons">
          <Button
              v-if="!props.hideConfirm"
              value="true"
              class="btn btn-primary"
              :label="props.confirmText"
              @click.prevent="confirm"
          >
          </Button>
        </slot>
      </div>
    </div>
  </dialog>
</template>

<style scoped lang="scss">
.generic-dialog {
  margin: auto;
  max-width: 600px;
  padding: 45px;
  border: solid 1px white;
  border-radius: 5px;
  background-color: rgba(0, 0, 0);
  color: white;
}

.close-btn {
  cursor: pointer;
  color: white;
  position: absolute;
  right: 15px;
  top: 15px;
}

.modal-action {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  gap: 10px;
}
</style>
