<template>
  <div class="sub-header">
    <div class="sub-header-wrapper">
      <div class="aro-gallery-header">
        <div class="aro-genre-details">
          <span class="genreTitle">{{ title }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SubHeader",
  props: {
    title: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.sub-header {
  height: 41px;

  position: relative;
  margin-top: 70px;
  background-color: $black40;
  z-index: 1;
  @media screen and (min-width: 950px) {
    height: 68px;
  }
  &-wrapper {
    align-items: center;
    display: flex;
    height: 41px;
    left: 0;
    padding: 0 4%;
    position: absolute;
    right: 0;
    @media screen and (min-width: 950px) {
      height: 68px;
    }
  }
}
.aro-gallery-header {
  align-items: center;
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  margin: 0;
  min-height: 0;
  padding: 0;
}
.aro-genre-details {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: flex-start;
}
.genreTitle {
  font-size: 38px;
  font-weight: 700;
  line-height: 38px;
  margin-right: 15px;
  @media only screen and (max-width: 740px) {
    font-size: 18px;
    line-height: 18px;
  }
  @media only screen and (max-width: 400px) {
    font-size: 14px;
    line-height: 14px;
  }
}
</style>
