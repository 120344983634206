<template>
  <div class="previewModal--container detail-modal mm">
    <div class="previewModal--player_container">
      <div class="storyArt detail-modal">
        <img
          class="playerModel--player__storyArt detail-modal has-smaller-buttons"
          :src="item.meta?.fanart"
          :alt="item.meta?.title"
        />
      </div>
      <div class="previewModal--player-titleTreatmentWrapper">
        <div
          class="previewModal--player-titleTreatment-left previewModal--player-titleTreatment detail-modal has-smaller-buttons detail-modal has-smaller-buttons"
        >
          <p class="title-movie">
            {{ item.meta?.title }}
            <!-- {{ item.defaultLang ? language : $t(item.meta?.title) }} -->
          </p>
          <div class="buttonControls--container">
            <button @click="handlePlayVideo(item.type)" class="play-link">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  class="card__icon--black"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M21.44 10.72L5.96 2.98A1.38 1.38 0 004 4.213v15.474a1.373 1.373 0 002 1.233l15.44-7.74a1.38 1.38 0 000-2.467v.007z"
                  />
                </svg>
              </div>
              <span class="play-text">{{ $t("play") }}</span>
            </button>
            <div>
              <button
                @click="
                  handleAddFavoriteMovie(
                    item.type,
                    item.imdb_id,
                    item.is_favorite
                  )
                "
                class="info-btn info-btn-large round-btn"
              >
                <Ok v-if="item.is_favorite" class="card__icon" />
                <svg
                  v-if="!item.is_favorite"
                  class="card__icon"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M12 0a1.5 1.5 0 011.5 1.5v9h9a1.5 1.5 0 110 3h-9v9a1.5 1.5 0 11-3 0v-9h-9a1.5 1.5 0 110-3h9v-9A1.5 1.5 0 0112 0z"
                  />
                </svg>
              </button>
            </div>
            <button class="share-btn" @click="handleShare()">
              <Share class="card__icon" />
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="previewModal-close">
      <Close class="previewModal-close-icon" @click="click" />
    </div>
    <div class="previewModal--info">
      <div class="detail-modal-container">
        <div class="ptrack-container">
          <div
            class="previewModal--detailsMetadata detail-modal has-smaller-buttons"
          >
            <div class="previewModal--detailsMetadata-left">
              <div class="previewModal--detailsMetadata-info">
                <div class="videoMetadata--container">
                  <div class="videoMetadata--second-line">
                    <div class="year">
                      {{ item.meta?.year }}
                    </div>
                    <span class="videoMetadata__maturity-rating">
                      <span class="maturity-number">
                        {{ item.meta?.mppa }}
                      </span>
                    </span>
                    <div>
                      <span v-if="Movie($route.path)" class="duration">{{
                        getTimeFromMins
                      }}</span>
                      <span v-if="TvShow($route.path)" class="duration">{{
                        getEpisodes
                      }}</span>
                    </div>
                    <span class="player-feature-badge">HD</span>
                  </div>
                </div>
              </div>
              <div
                v-if="isShowInfo"
                class="preview-modal-synopsis previewModal--text"
              >
                <div class="ptrack-content">
                  {{ item.meta?.plot }}
                  <!-- {{ item.defaultLang ? language : $t(item?.meta?.plot) }} -->
                </div>
              </div>
            </div>
            <div v-if="isShowInfo" class="previewModal--detailsMetadata-right">
              <div class="previewModal--tags">
                <span class="previewModal--tags-label">
                  {{ item.defaultLang ? language : $t("moviesModal.cast") }}:
                </span>
                <span
                  class="previewModal--tags-item"
                  v-for="cast in getCast"
                  :key="cast"
                >
                  {{ cast }},</span
                >
                <!-- <span>
                  <router-link to="/"> more </router-link>
                </span> -->
              </div>
              <div class="previewModal--tags">
                <span class="previewModal--tags-label">
                  {{ item.defaultLang ? language : $t("moviesModal.genres") }}:
                </span>
                <span class="previewModal--tags-item">{{
                  this.item?.meta?.genres
                }}</span>
              </div>
              <div class="previewModal--tags">
                <div class="">
                  <div class="previewModal--tags-column">
                    <Like class="previewModal--icon" />
                    <span class="previewModal--tags-item">
                      {{ this.item?.meta?.votes }}</span
                    >
                  </div>
                  <div class="previewModal--tags-column">
                    <Ranking class="previewModal--icon" />
                    <span class="previewModal--tags-item">
                      {{ this.item?.meta?.rank }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="isShowInfo">
          <div class="ptrack-container">
            <div class="trailersAndMore--wrapper">
              <div class="trailersAndMore--header">
                <h3 class="previewModal--section-header">
                  {{ $t("moviesModal.trailers&more") }}
                </h3>
              </div>
              <div class="trailersAndMore--container">
                <div
                  class="titleCard--container trailers-and-more-item"
                  role="button"
                  tabindex="0"
                >
                  <div class="titleCard-imageWrapper">
                    <div class="ptrack-content">
                      <img :src="item.meta?.fanart" :alt="item.meta?.title" />
                    </div>
                    <div class="titleCard-playIcon">
                      <a :href="item.meta?.trailer" target="_blank">
                        <Play
                      /></a>
                    </div>
                  </div>
                  <div class="titleCard--metadataWrapper">
                    <div class="titleCard-title">
                      {{ item.meta?.title }}
                      <!-- {{ item.defaultLang ? language : $t(item?.meta?.title) }} -->
                      <span>({{ $t("moviesModal.trailer") }})</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="TvShow($route.path)" class="ptrack-container episodes">
            <div class="trailersAndMore--header">
              <h3 class="previewModal--section-header">
                {{ $t("moviesModal.seasonsTitle") }}
              </h3>
            </div>
            <div class="episodes-wrapper">
              <div v-for="episode in item.meta_episodes" :key="episode.index">
                <div v-for="episodeInfo in episode" :key="episodeInfo?.imdb_id">
                  <div class="episodes__item">
                    <div class="episodes__item-num">
                      {{ episodeInfo?.episode }}
                    </div>
                    <div class="episodes__item-image">
                      <img
                        :src="episodeInfo?.screenshot"
                        :alt="episodeInfo?.title"
                      />
                    </div>
                    <div class="episodes__item-content">
                      <div class="episodes__item-title">
                        <div>
                          {{ episodeInfo?.title }}
                        </div>
                        <div>{{ episodeInfo?.duration }} minutes</div>
                      </div>
                      <div class="episodes__item-plot">
                        {{ episodeInfo?.plot }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="ptrack-container">
            <div class="about-wrapper">
              <div class="about-header">
                <h3 class="previewModal--section-header">
                  {{ $t("moviesModal.about") }}
                  <strong>
                    {{ item.meta?.title }}
                    <!-- {{ item.defaultLang ? language : $t(item?.meta?.title)}} -->
                  </strong>
                </h3>
              </div>
              <div class="about-container">
                <div class="previewModal--tags">
                  <span class="previewModal--tags-label">
                    {{ $t("moviesModal.director") }}:
                  </span>
                  <span class="tag-item">
                    {{ item.meta?.director }}
                    <!-- {{ item.defaultLang ? language : $t(item?.meta?.director) }} -->
                  </span>
                </div>
                <div class="previewModal--tags">
                  <span class="previewModal--tags-label">
                    {{ $t("moviesModal.company") }}:
                  </span>
                  <span class="tag-item">
                    {{ item.meta?.company }}
                    <!-- {{ item.defaultLang ? language : $t(item?.meta?.company) }} -->
                  </span>
                </div>
                <div class="previewModal--tags">
                  <span class="previewModal--tags-label">
                    {{ $t("header.accountDropdown.manageProfiles") }}
                    {{ $t("moviesModal.cast") }}:
                  </span>
                  <span class="tag-item">
                    {{ item.meta?.cast }}
                    <!-- {{ item.defaultLang ? language : $t(item?.meta?.cast) }} -->
                  </span>
                </div>
                <div class="previewModal--tags">
                  <span class="previewModal--tags-label">
                    {{ $t("moviesModal.writer") }}:
                  </span>
                  <span class="tag-item">
                    {{ item.meta?.writer }}
                    <!-- {{ item.defaultLang ? language : $t(item?.meta?.writer) }} -->
                  </span>
                </div>
                <div class="previewModal--tags">
                  <span class="previewModal--tags-label">
                    {{ $t("moviesModal.genres") }}:
                  </span>
                  <span class="tag-item">
                    {{ item.meta?.genres }}
                    <!-- {{ item.defaultLang ? language : $t(item?.meta?.genres) }} -->
                  </span>
                </div>
                <div class="previewModal--tags">
                  <span class="previewModal--tags-label">
                    {{ $t("moviesModal.genres") }}:
                  </span>
                  <span class="tag-item">
                    {{ item.meta?.mppa }}
                    <!-- {{ item.maturityRating ? language : $t(item?.meta?.mppa) }} -->
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "./style.scss";
import Ranking from "@/assets/icons/svg/ranking.svg";
import Like from "@/assets/icons/svg/like.svg";
import Close from "@/assets/icons/svg/close.svg";
import Play from "@/assets/icons/svg/play.svg";
import Ok from "@/assets/icons/svg/ok.svg";
import Share from "@/assets/icons/svg/share.svg";

import { shareLink } from "@/helpers/loading";

export default {
  name: "InfoMovie",
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    click: {
      type: Function,
      default: () => {},
    },
    isShowInfo: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      defaultLanguage: "en",
    };
  },
  components: {
    Ranking,
    Like,
    Close,
    Play,
    Ok,
    Share,
  },
  methods: {
    async handleAddFavoriteMovie(typeCard, id, favorite) {
      if (typeCard === "movie") {
        this.$store.dispatch("switchFavouriteMovies", {
          item_id: id,
          is_favorite: favorite,
        });
      }
      if (typeCard === "tvshow") {
        this.$store.dispatch("switchFavourite", {
          item_id: id,
          is_favorite: favorite,
        });
      }
    },
    handlePlayVideo(type) {
      type === "movie"
        ? this.handlePlayVideoMovie()
        : this.handlePlayVideoTvShows();
      this.click();
    },
    handlePlayVideoMovie() {
      this.$store.commit("handleShowInfoMovie", false);
      this.$router.push({
        name: "Video",
        params: { slug: this.item.meta.title },
        query: {
          trackId: this.item.imdb_id,
          type: "getLinkMovie",
        },
      });
    },
    handlePlayVideoTvShows() {
      this.$router.push({
        name: "Video",
        params: { slug: this.item.meta.title },
        query: {
          trackId: this.item.imdb_id,
          season: 1,
          episode: 1,
          type: "getLinkTvShow",
        },
      });
    },
    handleShare() {
      shareLink(this.$props.item);
    },
    Movie(route) {
      return route === "/movies";
    },
    TvShow(route) {
      return route === "/tvshows";
    },
  },
  computed: {
    getTimeFromMins() {
      let hours = Math.trunc(this.item.meta?.runtime / 60);
      let minutes = this.item.meta?.runtime % 60;
      return hours + "h " + minutes + "m";
    },
    getEpisodes() {
      if (this.item?.meta?.seasons) {
        const seasonsLength = Object.keys(this.item?.meta.seasons).length;
        if (seasonsLength === 1) {
          return `${seasonsLength} Season`;
        }
        return `${seasonsLength} Seasons`;
      } else return "0 Seasons";
    },
    getCast() {
      return (this.item.meta?.cast).split(", ", 3);
    },
    selectedLanguage() {
      return this.$i18n.locale || this.defaultLanguage;
    },
  },
};
</script>

<style lang="scss" scoped>
.title-movie {
  color: $gray500;
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 20px;
  @media only screen and (max-width: 349px),
    only screen and (min-width: 350px) and (max-width: 399px),
    only screen and (min-width: 400px) and (max-width: 768px) {
    font-size: 24px;
    margin-bottom: 25px;
  }
}
</style>
