import { createProfile  } from '@/api/index.js'

const state = {
  isSubmitting: false,
  validationErrors: null
}

export const mutationTypes = {
  createProfileStart: '[createProfile] Create profile start',
  createProfileSuccess: '[createProfile] Create profile success',
  createProfileFailure: '[createProfile] Create profile failure',

}

export const actionTypes = {
  createProfile: '[createProfile] Create profile',
}

const mutations = {
  [mutationTypes.createProfileStart](state) {
    state.isSubmitting = true
  },
  [mutationTypes.createProfileSuccess](state) {
    state.isSubmitting = false
  },
  [mutationTypes.createProfileFailure](state, payload) {
    state.isSubmitting = false
    state.validationErrors = payload
  }
}
const actions = {
  [actionTypes.createProfile](context, profileInput) {
    return new Promise(resolve => {
      context.commit(mutationTypes.createProfileStart)
      createProfile(profileInput)
        .then(profile => {
          context.commit(mutationTypes.createProfileSuccess, profile)
          resolve(profile)
        })
        .catch(result => {
          context.commit(
            mutationTypes.createArticleFailure,
            result.response.data.errors
          )
        })
    })
  }
}

export default {
  state,
  actions,
  mutations
}
