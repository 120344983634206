<template>
  <div class="footer" :class="'footer--' + theme">
    <div class="footer-card" :class="'footer-card--' + theme">
      <div class="site-footer-wrapper">
        <div class="site-footer">
          <!-- <p class="footer-top">
            Questions? Call 0800-022-5173
            <a class="footer-top-a" href="tel:0800-509-417">0800-509-417</a>
          </p>
          <FooterNav /> -->
          <div v-show="!showLangPicker" class="lang-selection-container">
            <LanguagePicker theme="medium" />
          </div>
          <!-- <div class="footer--copyright">WebPlay</div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import FooterNav from "@/components/Footer/FooterNav.vue";
import LanguagePicker from "@/components/LanguagePicker";
import { getItem } from "@/helpers/storage";
export default {
  name: "Footer",
  components: {
    // FooterNav,
    LanguagePicker,
  },
  props: {
    theme: {
      type: String,
      default: "",
    },
  },
  methods: {
    showLangPicker() {
      if (getItem("accessToken")) {
        return true;
      }
    },
  },
};
</script>

<style lang="scss">
.footer {
  background-color: $black30;
  &-card {
    &--home {
      margin: 0 auto;
      background: transparent;
      color: #fff;
      margin-bottom: 0;
      position: relative;
      max-width: 1000px;
      padding: 50px 5%;
    }
  }
  &--home {
    background-color: $black;
    @media only screen and (min-width: 1450px),
      only screen and (min-width: 550px) and (max-width: 949px),
      only screen and (min-width: 950px) and (max-width: 1449px) {
      padding: 70px 45px;
    }
  }
  &--gray {
    background-color: #f3f3f3;
  }
  &--login {
    background: rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px 5%;
    opacity: 0.4;
  }
  &-country {
    font-size: 13px;
    margin-top: 24px;
  }
  &__social {
    display: flex;
    margin-bottom: 1rem;
    &-link {
      fill: $white;
      margin-right: 15px;
      cursor: pointer;
    }
  }
  &--copyright {
    font-size: 15px;
    margin-bottom: 15px;
  }
}

.site-footer {
  padding-top: 0;
  margin: 0 auto;
  padding-top: 30px;
  width: 90%;
  &-wrapper {
    margin-top: 0;
    color: #737373;
    font-size: 1em;
    min-width: 190px;
    padding-bottom: 20px;
    position: relative;
    width: 100%;
  }
}
.footer-top {
  margin: 0 0 30px;
  padding: 0;
  font-size: 16px;
  color: $dark-gray;
  &-a {
    color: #737373;
    text-decoration: none;
  }
}

.lang-selection-container {
  margin-top: 20px;
  width: 170px;
}
</style>
