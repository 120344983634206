import { getProfiles, deleteProfile } from '@/api/index.js'
import {  removeItem } from '@/helpers/storage'

const state = {
  data: null,
  isLoading: false,
  error: null,
  isAddProfile: false,
  isEditProfile: false,
}

export const mutationTypes = {
  getProfilesStart: '[profiles] Get profiles start',
  getProfilesSuccess: '[profiles] Get profiles success',
  getProfilesFailure: '[profiles] Get profiles failure',

  deleteProfilesStart: '[profiles] Delete profiles start',
  deleteProfilesSuccess: '[profiles] Delete profiles success',
  deleteProfilesFailure: '[profiles] Delete profiles failure'
}

export const actionTypes = {
  getProfiles: '[profiles] Get profiles',
  deleteProfiles: '[profiles] Delete profiles'
}

const mutations = {
  [mutationTypes.getProfilesStart](state) {
    state.isLoading = true
    state.data = null
  },
  [mutationTypes.getProfilesSuccess](state, payload) {
    state.isLoading = false
    state.data = payload
  },
  [mutationTypes.getProfilesFailure](state) {
    state.isLoading = false
  },
  [mutationTypes.deleteProfilesStart]() {},
  [mutationTypes.deleteProfilesSuccess]() {},
  [mutationTypes.deleteProfilesFailure]() {},

  handleAddProfile(state, value) {
    state.isAddProfile = value;
  },
  handleEditProfile(state, value) {
    state.isEditProfile = value;
  },
}

const actions = {
  [actionTypes.getProfiles](context) {
    return new Promise(resolve => {
      context.commit(mutationTypes.getProfilesStart)
      getProfiles()
        .then(profiles => {
          context.commit(mutationTypes.getProfilesSuccess, profiles.data.data)
          resolve(profiles)
        })
        .catch(() => {
          context.commit(mutationTypes.getProfilesFailure)
        })
    })
  },
  [actionTypes.deleteProfiles](context, {id}) {
    return new Promise(resolve => {
      context.commit(mutationTypes.deleteProfilesStart)
      deleteProfile(id)
        .then(() => {
          context.commit(mutationTypes.deleteProfilesSuccess)
          removeItem('currentProfile')
          resolve()
        })
        .catch(() => {
          context.commit(mutationTypes.deleteProfilesFailure)
        })
    })
  }
}

export default {
  state,
  actions,
  mutations
}
