<template>
  <YouTube
    :src="src"
    width="100%"
    height="100%"
    :vars="videoSettings"
    :origin="getOrigin()"
    @ready="onVideoReady"
    ref="youtube"
  />
</template>

<script>
import { defineComponent } from "vue";
import YouTube from "vue3-youtube";

export default defineComponent({
  components: { YouTube },
  props: {
    videoSettings: {
      type: Object,
      default: () => {},
    },
    onReady: {
      type: Function,
      default: () => {},
    },
    onInit: {
      type: Function,
      default: () => {},
    },
    src: {
      type: String,
      default: () => "",
    },
  },
  methods: {
    getOrigin() {
      return window.location.origin;
    },
    onVideoReady() {
      return this.onReady(this.$refs.youtube);
    },
  },
});
</script>
