<template>
  <div class="wrapper wrapper-sport">
    <div class="card card--rounded card-sport">
      <div class="card-sport">
        <div class="card-sport__image-wrapper">
          <img :src="item.category?.logo_url" :alt="item?.title" />
        </div>
        <div class="card-sport__body">
          <div class="card-sport-title">
            {{ item?.title }}
          </div>
          <div class="card-sport-date">
            {{ convertDate }}
          </div>
        </div>
      </div>
      <div class="card-sport-buttons">
        <button @click="handlePlayVideo" class="play-btn round-btn">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" class="card__icon card__icon-sport" fill="none" viewBox="0 0 24 24">
            <path d="M21.44 10.72L5.96 2.98A1.38 1.38 0 004 4.213v15.474a1.373 1.373 0 002 1.233l15.44-7.74a1.38 1.38 0 000-2.467v.007z" />
          </svg>
        </button>
        <button @click="handleAddFavoriteMovie(item.id, item.is_favorite)" class="info-btn round-btn">
          <Ok v-if="item.is_favorite" class="card__icon" />
          <svg v-if="!item.is_favorite" class="card__icon-sport" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
            <path d="M12 0a1.5 1.5 0 011.5 1.5v9h9a1.5 1.5 0 110 3h-9v9a1.5 1.5 0 11-3 0v-9h-9a1.5 1.5 0 110-3h9v-9A1.5 1.5 0 0112 0z" />
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Ok from "@/assets/icons/svg/ok.svg";
export default {
  name: "SliderItemSport",
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    click: {
      type: Function,
      default: () => {},
    },
  },
  methods: {
    handlePlayVideo() {
      this.$router.push({
        name: "Video",
        params: { slug: this.item.title },
        query: { trackId: this.item.id, type: "getLinkSportVod" },
      });
    },
    async handleAddFavoriteMovie(id, favorite) {
      this.$store.dispatch("toggleMovieInFavorite", {
        section: "sportChannel",
        id,
      });
      this.$store.dispatch("switchFavouriteSport", {
        id: id,
        is_favorite: favorite,
      });
    },
  },
  components: {
    Ok,
  },
  computed: {
    convertDate() {
      let convertStringDate = Date.parse(this.item?.event_date);
      let date = new Date(convertStringDate),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      return [day, mnth, date.getFullYear()].join("-");
    },
  },
};
</script>
<style lang="scss">
.card-sport {
  position: relative;
  padding: 2% 0;
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    border-radius: 10px;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    opacity: 0;
  }
  &:hover:before,
  &:hover:after {
    opacity: 1;
  }

  &:hover {
    transform: scale(1.1);
    z-index: 11;
  }
  &__image-wrapper {
    object-fit: cover;
    height: 60px;
    display: flex;
    margin: auto;
    justify-content: center;
    img {
      max-width: 50%;
      height: auto;
    }
  }
  &__body {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 5px 0;
  }
  &-buttons {
    position: absolute;
    opacity: 0;
    visibility: hidden;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    display: flex;
    z-index: 22;
  }
  &:hover .card-sport-buttons {
    opacity: 1;
    visibility: visible;
    cursor: pointer;
  }
  &-title {
    text-align: center;
    word-break: break-word;
    font-size: 13px;
    color: $white10;
    @media only screen and (max-width: 349px), only screen and (min-width: 350px) and (max-width: 399px), only screen and (min-width: 400px) and (max-width: $mobile) {
      font-size: 11px;
    }
  }
  &-date {
    word-break: break-word;
    font-size: 13px;
    color: $white10;
    padding-top: 5px;
    @media only screen and (max-width: 349px), only screen and (min-width: 350px) and (max-width: 399px), only screen and (min-width: 400px) and (max-width: $mobile) {
      font-size: 11px;
    }
  }
}
</style>
