<template>
  <div ref="scrollComponent" class="slider-container" v-if="shouldDisplay">
    <div class="search-title">
      <Back class="tvguide-picker__back" @click="setCategory(null)"></Back>
      {{ searchText }}
    </div>

    <div v-for="(componentData, index) in sortedComponentsArray" :key="index" class="globalSearchResults">
      <div :style="{ order: order.movies }" v-if="componentData.data && componentData.name === 'movies'">
        <div class="search-header">In Movies</div>
        <div class="lists-content justify-content">
          <Loader class="loader-billboard" v-if="isLoadingCategory" />
          <div v-for="item in componentData.data" :key="item.id">
            <SliderItem :brandImage="brandImage" :item="item" />
          </div>
        </div>
      </div>

      <div :style="{ order: order.tvshow }" v-if="componentData.data && componentData.name === 'tvshow'">
        <div class="search-header">In Tv Shows</div>
        <div class="lists-content justify-content">
          <Loader class="loader-billboard" v-if="isLoadingCategory" />
          <div v-for="item in componentData.data" :key="item.id">
            <SliderItem :brandImage="brandImage" :item="item" />
          </div>
        </div>
      </div>
      <div :style="{ order: order.livetv }" v-if="componentData.data && componentData.name === 'livetv'">
        <div class="search-header">In Live TV</div>
        <div class="lists-content justify-content">
          <Loader class="loader-billboard" v-if="isLoadingCategory" />
          <div v-for="item in componentData.data" :key="item.id">
            <SliderLiveTv :item="item" />
          </div>
        </div>
      </div>
      <div :style="{ order: order.sports }" v-if="componentData.data && componentData.name === 'sports'">
        <div class="search-header">In Sports</div>
        <div class="lists-content justify-content">
          <Loader class="loader-billboard" v-if="isLoadingCategory" />
          <div v-for="item in componentData.data" :key="item.id">
            <SliderItemSport :item="item" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import SliderItem from "@/components/Slider/SliderItem.vue";
import SliderItemSport from "@/components/Slider/SliderItemSport.vue";
import SliderLiveTv from "@/components/Slider/SliderLiveTv.vue";
import Back from "@/assets/icons/svg/back.svg";

export default {
  name: "GlobalSearchView",
  components: {
    SliderItem,
    SliderItemSport,
    SliderLiveTv,
    Back,
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState({
      searchMovies: (state) => state.movies.searchMovies,
      searchTvShows: (state) => state.tvShows.searchTvShows,
      searchSports: (state) => state.sportsVod.searchSports,
      searchLiveTv: (state) => state.liveTv.searchLiveTv,
      searchText: (state) => state.movies.searchText,
      isLoadingCategory: (state) => state.movies.isLoading,
      brandImage: (state) => `/img/${state.config?.brandName}-logo.png`,
    }),
    shouldDisplay() {
      return this.searchMovies || this.searchTvShows || this.searchSports || this.searchLiveTv
    },
    componentsArray() {
      return [
        { name: "movies", component: SliderItem, data: this.searchMovies },
        { name: "tvshow", component: SliderItem, data: this.searchTvShows },
        { name: "sports", component: SliderItemSport, data: this.searchSports },
        { name: "livetv", component: SliderLiveTv, data: this.searchLiveTv },
      ];
    },
    sortedComponentsArray() {
      return this.componentsArray.slice().sort((a, b) => this.$props.order[a.name] - this.$props.order[b.name]);
    },
  },
  methods: {
    setCategory(category) {
      this.$store.commit("setSearchMovies", {
        text: category?.name,
        movies: category?.infos,
      });
    },
  },
  data() {
    return {
      offset: 0,
      isActive: false,
      lazyLoading: false,
      randomMovie: null,
    };
  },
};
</script>
<style lang="scss">
.search-header:nth-child(n + 2) {
  background-color: yellow;
}

.slider-container-newmovies {
  display: flex;
  column-gap: 50px;
  flex-wrap: wrap;
  row-gap: 60px;
  justify-content: center;
  margin-left: 100px;
  margin-right: 100px;

  // &:after {
  //   content: "";
  //   flex: auto;
  // }
  .lolomoRow {
    max-width: 300px;

    .wrapper__back:hover {
      display: none;
    }

    .wrapper__front:hover {
      opacity: 1;
    }

    li {
      min-width: 120%;
    }
  }

  .category-slider-hider {
    width: 95%;
    height: 100%;
    position: relative;
    top: -91%;
    cursor: pointer;
  }
}

.slider-container {
  margin-top: 5rem;

  .search-title {
    .tvguide-picker__back {
      width: 30px;
      height: 30px;
      cursor: pointer;
    }
  }

  .search-header {
    margin: auto;
    max-width: 300px;
    font-size: 2rem;
    text-align: center;
  }
}

.loader {
  &-billboard {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
  }

  &-categories {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
  }
}

.row-header-title {
  cursor: pointer;
}

.globalSearchResults:nth-child(3) {
  margin-top: 8rem;
}
</style>
