<template>
  <div class="db">
    <div class="header-wrap">
      <!-- <Header :hideSearch="true" /> -->
      <!-- <SubHeader :title="$t(`${pageName}.titlePage`)" /> -->
    </div>
    <div class="mainView" :class="[activetab > 0 ? 'active' : '']">
      <div class="lolomo">
        <div class="top">
          <img class="phone-img" :src="backgroundImage" alt="" />
          <div class="unsupported-title">
            <h1>
              {{ $t('unsupported.title') }} <a href="\installation">{{ $t('unsupported.mobileApp') }}</a>
            </h1>
            <div class="mainView-aligned unsupported-browsers-end">
              <div class="browser-text">
                {{ $t('unsupported.textEnd') }}
                <div class="icon-text">
                  <img src="../../assets/img/chrome.png" alt="" />
                  <span>Chrome</span>
                </div>
              </div>
              <div class="icon-text">
                or
                <img src="../../assets/img/edge.png" alt="" />
                <span>Microsoft Edge</span>
                version.
              </div>
            </div>
          </div>
          <div></div>
          <LeadSignup v-if="leadId" @created="onLeadRegister" />
          <a v-else href="\installation" class="installation_instruction">{{ $t('comingSoon.installationGuid') }}</a>
        </div>
      </div>
    </div>
    <Footer class="member-footer" />
  </div>
</template>

<script>
import Footer from '@/components/Footer/Footer.vue';
import LeadSignup from '@/components/LeadSignup/LeadSignup.vue';
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'Unsupported',
  components: {
    LeadSignup,
    Footer,
  },
  data() {
    return {
      done: false,
    };
  },
  computed: {
    ...mapState({
      backgroundImage: (state) => `/img/${state.config?.brandName}-popup.png`,
    }),
    ...mapGetters({ leadId: '[auth] guidCredentials' }),
  },
  methods: {
    onLeadRegister() {
      this.done = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.lolomo {
  overflow: hidden;
  padding: 0 0 50px;
  z-index: 0;
  margin-top: -60px;
  width: 400px;
  .top {
    position: relative;
    background-color: $ocean-blue;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 25px;
    border-radius: 10px;
    .phone-img {
      position: absolute;
      width: 250px;
      margin-top: 30px;
    }
  }
}
.guid_selector {
  text-align: center;
  font-size: 22px;
}

.mainView {
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mainView.active {
  display: block;
  .mainView-aligned {
    font-size: 16px;
    align-items: flex-start;
  }

  .guid_selector {
    align-items: flex-start;
    text-align: start;
  }
}
.unsupported-browsers-end {
  margin-top: 10px;
  font-size: 16px;
  .icon-text {
    display: flex;
    align-items: center;
    img {
      width: 15px;
      margin-left: 5px;
      margin-right: 3px;
    }
    span {
      font-weight: 600;
      margin-right: 3px;
    }
  }
  .browser-text {
    display: flex;
  }
}
.content {
  margin-top: 20px;
  font-size: 14px;
  color: $gray70;
  width: 380px;
}
.unsupported-title {
  z-index: 100;
  padding-top: 270px;
  color: $white10;
  margin: auto 0;
  text-align: center;
  font-size: 24px;
  h1 {
    font-size: 30px;
    font-weight: 600;
  }
  a {
    color: $white10;
    text-decoration: none;
  }
}
.unsupported-ending {
  color: $white10;
  margin: auto 0;
  text-align: center;
  font-size: 14px;
}

[data-theme='apollogroup'] {
  .installation_instruction {
    color: #1caee5;
  }
}

[data-theme='pizzatime'] {
  .installation_instruction {
    color: #fb8b00;
  }
}

.installation_instruction {
  font-size: 16px;
  margin-top: 20px;
  color: #fb8b00;
  text-decoration: underline;
  margin-bottom: 20px;
}
</style>
