<template>
  <div class="signup__steps">
    <div class="step-one">
      <div class="step-one__logo"></div>
      <div>
        <span>STEP 1 OF 3</span>
        <h1 class="signup__title">{{ $t("signUp.stepOne.stepTitle") }}</h1>
      </div>
      <div class="step-one__text">
        {{ $t("signUp.stepOne.stepText") }}
      </div>
      <Button
        class="signup__button"
        theme="red-large"
        @click="changeStep"
        :label="$t('signUp.button')"
      />
    </div>
  </div>
</template>

<script>
import Button from "@/components/Button";
export default {
  name: "StepOne",
  props: ["changeStep"],
  components: {
    Button,
  },
};
</script>

<style lang="scss" scoped>
.step-one {
  margin: 0 auto;
  max-width: 340px;
  text-align: center;
  &__logo {
    background: url(https://assets.nflxext.com/ffe/siteui/acquisition/simplicity/Devices.png)
      no-repeat 50% 50%;
    background-size: 260px;
    display: inline-block;
    height: 90px;
    margin: 100px 0 20px;
    width: 260px;
    margin: 100px 0 20px;
  }
  &__text {
    font-size: 16px;
    max-width: 300px;
    display: inline-block;
  }
}
</style>
