<template>
  <div class="db">
    <div class="header-wrap">
      <Header />
    </div>
    <div class="mainView">
      <div class="mainView-aligned">
        <Loader class="loader-categories" v-if="isLoading" />
      </div>
      <div id="tabs" class="live-tv__select" v-if="!searchLiveTv">
        <SelectCountry :categories="countries"></SelectCountry>
      </div>
      <div class="top" v-if="!searchLiveTv && defaultCountry">
        <div v-if="liveTvCards && !searchLiveTv && defaultCountry">
          <div v-for="category in categories" :key="category.id">
            <div class="top" v-if="getFilteredCategory(category, country)?.length">
              <p>{{ category.name }}</p>
              <Slider :category="category" :cards="getFilteredCategory(category, country)" :isLiveTv="true" />
            </div>
          </div>
          <Slider :category="country" :cards="liveTvCards" :isLiveTv="true" />
        </div>
      </div>
      <GlobalSearchView :order="{ movies: 2, tvshow: 3, sports: 4, livetv: 1 }" />
    </div>
    <Footer id="footer" class="member-footer" />
  </div>
</template>

<script>
import Header from "@/components/Header/Header";
// import countries from "@/data/countries.js";
import Footer from "@/components/Footer/Footer.vue";
// import SubHeader from "@/components/Header/SubHeader.vue";
import SelectCountry from "../LiveTv/SelectCountry.vue";
import Slider from "@/components/Slider/Slider";
import Loader from "@/components/Loader/Loader";
import GlobalSearchView from "@/components/GlobalSearchView.vue";

import { mapState } from "vuex";
export default {
  name: "LiveTv",
  components: {
    Header,
    Footer,
    Slider,
    SelectCountry,
    Loader,
    GlobalSearchView,
  },
  data() {
    return {
      videoSrc: "",
      open: false,
    };
  },
  methods: {
    switchCategory(category) {
      this.$store.dispatch("switchCategory", category);
    },
    getFilteredCategory(category, country) {
      return {
        ...category,
        infos: country.infos.filter((i) => category.infos.some((currInfo) => currInfo === i)),
      };
    },
  },
  computed: {
    ...mapState({
      searchText: (state) => state.liveTv.searchText,
      countries: (state) => state.liveTv.firstCategories?.filter((i) => i.is_genre),
      categories: (state) => state.liveTv.firstCategories?.filter((i) => !i.is_genre),
      searchLiveTv: (state) => state.liveTv.searchLiveTv,
      firstCategories: (state) => state.liveTv.firstCategories,
      linkVideoState: (state) => state.playVideo.data,
      isLoading: (state) => state.liveTv.isLoading,
      defaultCountry: (state) => state.liveTv.defaultCountry,
      country(state) {
        return state.liveTv.firstCategories?.find((item) => item.id === this.defaultCountry?.countryCode);
      },
      liveTvCards(state) {
        if (!this.country) return state.liveTv.liveTvCards;
        const items = state.liveTv.liveTvCards.filter((item) => {
          if (item.imdb_id) {
            return !this.country?.infos?.includes(item.imdb_id);
          } else {
            return this.country?.infos?.includes(item.id);
          }
        });
        return items;
      },
      // billboardLiveTv() {
      //   if (!this.liveTvCards?.length) {
      //     return null;
      //   }
      //   const randomId = Math.floor(Math.random() * this.liveTvCards.length);
      //   return this.liveTvCards[randomId];
      // },
    }),
  },
  mounted() {
    this.$emit("input", this.defaultCountry?.countryName);
    this.$store.dispatch("getLiveTvCards");
  },
};
</script>

<style lang="scss" scoped>
.search-title {
  margin: auto;
  text-align: center;
  font-size: 28px;
  padding-top: 20px;
}
.mainView {
  padding-top: 4%;
}
.mainView-aligned {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  flex-direction: column;
}
.tvguide-picker {
  padding: 5px 10px;
  display: block;
  color: $white;
  width: auto;
  height: auto;
  position: relative;
  border: 1px solid $gray;
  outline: none;
  &__selected-option {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__text {
    font-size: 10px;
    color: $gray10;
    padding: 0.3rem 1.375rem 0.3rem 0.2rem;
    white-space: nowrap;
    cursor: pointer;
    user-select: none;
  }
  &__down {
    fill: $white;
  }
  &__options {
    color: $white;
    position: absolute;
    margin-top: 6.5px;
    overflow: auto;
    background-color: $bg-gray;
    left: 0;
    right: 0;
    height: 260px;
    z-index: 1;
    border: 1px solid $bg-gray;
  }
  &__option {
    padding: 0.5rem 1.375rem;
    &:hover {
      background-image: linear-gradient(180deg, #0080ff, #0277ec);
    }
  }
}
.width-250 {
  width: 250px;
}
.selectHide {
  display: none;
}
.tvguide-picker__text {
  color: #fff;
  font-size: 16px;
  font-weight: bold;
}
.live-tv {
  &__select {
    margin: 30px auto;
  }
  &__text {
    padding: 0 4%;
    display: flex;
    gap: 10px;
    font-size: 20px;
    font-weight: 400;
    margin-right: 20px;
    align-items: center;
    color: $white;
    @media screen and (max-width: 740px) {
      align-items: center;
    }
    p {
      margin-bottom: 20px;
    }
  }
}
.mainView {
  background-color: $black50;
}
</style>
