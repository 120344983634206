<template>
  <div class="login-other" v-if="false">
    <div class="login-other__terms">
      <p class="login-other__text">
        {{ $t("loginForm.formInfo") }}

        <Button
          class="float"
          :class="{ hide: isActive }"
          :label="$t('loginForm.btnLearnMore')"
          @click="handleOpenInfo"
          theme="link"
        />
      </p>
      <div :class="{ open: isActive }" class="login-other__terms">
        {{ $t("loginForm.tips") }}
        <Button
          theme="primary"
          link
          :label="$t('loginForm.btnPrivacy')"
        ></Button>
        and
        <Button
          link
          theme="primary"
          :label="$t('loginForm.btnTermsOfService')"
        ></Button
        >, {{ $t("loginForm.tipsNext") }}
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/Button";
export default {
  name: "LoginOther",
  components: {
    Button,
  },
  data() {
    return {
      isActive: false,
    };
  },
  methods: {
    handleOpenInfo() {
      return (this.isActive = true);
    },
  },
};
</script>

<style lang="scss" scoped>
.login-other {
  &__text {
  }
  &__terms {
    color: $gray;
    padding-top: 11px;
    span {
      font-size: 13px;
      margin-right: 5px;
    }
    &-disclosure {
      transition: height 0.5s ease, opacity 0.5s ease;
      color: $gray;
      font-size: 13px;
      padding-top: 11px;
      opacity: 0;
      visibility: hidden;
    }
  }
}
.open {
  opacity: 1;
  visibility: visible;
}
.hide {
  display: none;
}
.float {
  float: inherit;
}
</style>
