<template>
  <div class="db">
    <div class="header-wrap">
      <Header :hideSearch="true" />
      <!-- <SubHeader :title="$t(`${pageName}.titlePage`)" /> -->
    </div>
    <div class="mainView" :class="[activetab > 0 ? 'active' : '']">
      <div class="lolomo intallation">
        <div class="top" v-if="pageName !== 'installation'">
          <div class="mainView-aligned">
            {{ $t("comingSoon.title") }}
          </div>
          <div class="mainView-aligned">
            {{ $t("comingSoon.text") }}
          </div>
        </div>
        <Installation></Installation>
      </div>
    </div>
    <Footer class="member-footer" />
  </div>
</template>

<script>
import Header from "@/components/Header/Header";
import Footer from "@/components/Footer/Footer.vue";
import Installation from "./Installation.vue";

export default {
  name: "ComingSoon",
  props: {
    pageName: String,
  },
  components: {
    Header,
    Footer,
    Installation,
  },
};
</script>

<style lang="scss" scoped>
.mainView {
  margin: auto 0;
  padding-top: 230px;
  padding-inline-start: 4%;
  padding-inline-end: 4%;

  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mainView.active {
  display: block;
  .mainView-aligned {
    font-size: 16px;
    align-items: flex-start;
  }
}
.intallation {
  padding-top: 70px;
}
.mainView-aligned {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  flex-direction: column;
  font-size: 24px;
}

.content {
  margin-top: 20px;
  font-size: 14px;
  color: $gray70;
}
</style>
