<template>
  <LoginLayout :item="logout" />
</template>

<script>
import { markRaw } from "vue";
import LogoutModal from "@/components/LogoutModal";
import LoginLayout from "@/layouts/LoginLayout";

export default {
  name: "Logout",
  components: {
    LoginLayout,
  },
  computed: {
    logout() {
      return markRaw(LogoutModal);
    },
  },
};
</script>

<style lang="scss" scoped></style>
