const state = {
  data: null,
  isActive: false
}

const mutations = {
  handleGetInfoMovie(state, payload) {
    state.data = payload;
  },
  handleShowInfoMovie(state, payload) {
    state.isActive = payload;
  },
}

export default {
  state,
  mutations
}
