<template>
  <div :class="{ 'cookie-disclosure-hide': isOpen }" class="cookie-disclosure">
    <span class="cookie-disclosure-close" @click="handleHide"></span>
    <div class="cookie-disclosure__body">
      <p class="cookie-disclosure__text">
        webPlayer and
        <span> <Button link theme="gray" label="third parties" /></span> use
        <span>
          <Button link theme="gray" label=" cookies and similar technologies"
        /></span>
        on this website to collect information about your browsing activities,
        which we use to analyse your use of the website, to personalise our
        services and to customise our online advertisements. When your consent
        is required, you can accept, refuse or personalise your choices. You can
        also change your preferences at any time by clicking “Cookie
        Preferences” in the footer of each page. webPlayer supports the Digital
        Advertising Alliance Principles.
        <Button
          link
          theme="gray"
          label="Learn more about our use of cookies and information."
        />.
      </p>
      <div class="cookie-disclosure__buttons">
        <Button theme="cookie" label="Accept"></Button>
        <Button theme="cookie" label="Reject"></Button>
        <Button theme="cookie-gray" label="Personalise my choices"></Button>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/Button";
export default {
  name: "CookieDisclosure",
  components: {
    Button,
  },
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    handleHide() {
      return (this.isOpen = true);
    },
  },
};
</script>

<style lang="scss" scoped>
.cookie-disclosure {
  left: 0;
  position: fixed;
  right: 0;
  top: auto;
  width: 100%;
  z-index: 1000;
  &-hide {
    display: none;
  }
  &-close {
    &:after {
      content: "+";
      color: $black80;
      z-index: 2;
      right: 10px;
      position: absolute;
      transform: rotate(45deg);
      font-size: 32px;
    }
  }
  &__body {
    background-color: hsla(0, 0%, 100%, 0.95);
    border-top: 1px solid #ccc;
    padding: 15px;
    position: relative;
  }
  &__text {
    color: $black;
    font-size: 14px;
    line-height: 18px;
    margin: 0 auto;
    padding: 10px 0 15px 0;
    text-align: left;
  }
  &__buttons {
    margin-top: -10px;
    padding: 0 15px 15px 0;
  }
}
</style>
