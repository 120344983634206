import axios from "@/api/axios"
import _ from "lodash"

//auth
export const login = (credentials) => {
  return axios.post("api/login", credentials, { headers: { 'X-Client-Type': 'web' } })
}

export const getCurrentUser = () => {
  return axios.get("api/user")
}

export const logout = () => {
  return axios.get("/api/logout")
}

//movie
export const getMoviesBillboard = () => {
  return axios.get(`/api/info/movies/items/2/0/20`)
}
export const getSearchMovieBillboard = (searchValue) => {
  return axios.get(`/api/info/movie/search/${searchValue}`)
}
export const getSearchMovie = (searchValue) => {
  return axios.get(`/api/info/movie/search/${searchValue}/10`)
}

export const getMoviesCategories = (offset, size) => {
  return axios.get(`/api/info/movies/categories/${offset}/${size || 3}`)
}

export const getMoviesCategory = (category_id) => {
  return axios.get(`api/info/movies/items/${category_id}/0/10`)
}

export const getMoviesFavorite = () => {
  return axios.get(`/api/favorite/movie/list/0/20`)
}

export const addMovie = (id) => {
  return axios.post(`api/favorite/movie/${id}`)
}

export const deleteMovie = (id) => {
  return axios.delete(`/api/favorite/movie/${id}`)
}

export const getPlayMovie = (id) => {
  return axios.get(`/api/play/movie/${id}`)
}
export const getMovieSubtitles = (id) => {
  return axios.get(`/api/subtitles/movie/${id}?direct_link=1`)
}

//tshows
export const getFavoriteTvShows = () => {
  return axios.get("/api/favorite/tvshow/list/0/20")
}

export const getTvShowsCategories = (offset, size) => {
  return axios.get(`/api/info/tvshows/categories/${offset}/${size || 3}`, { headers: { 'X-API-VERSION': 'V3' } })
}

export const getTvShowsCategory = (category_id) => {
  return axios.get(`/api/info/tvshows/items/${category_id}/0/20`)
}

export const getTvShowsBillboard = () => {
  return axios.get(`/api/info/tvshows/items/53/0/25`)
}

export const getTvShowBillboard = (searchValue) => {
  return axios.get(`/api/info/movie/search/${searchValue}`)
}

export const getSearchTvShow = (searchValue) => {
  return axios.get(`/api/info/tvshow/search/${searchValue}/10`)
}

export const addTvShow = (id) => {
  return axios.post(`/api/favorite/tvshow/${id}`)
}

export const deleteTvShow = (id) => {
  return axios.delete(`/api/favorite/tvshow/${id}`)
}

export const getPlayTvShow = (id, season, episode) => {
  return axios.get(`/api/play/tvshow/${id}/${season}/${episode}`)
}
export const getSubtitlesTvShow = (id, season, episode) => {
  return axios.get(`/api/subtitles/tvshow/${id}/${season}/${episode}`)
}
export const getInfoTvShow = (id) => {
  return axios.get(`/api/info/tvshows/${id}`)
}

//sportsVOD
export const getSportsVodCategories = () => {
  return axios.get(`/api/event/categories`)
}

export const getSportsCategory = (category_id) => {
  return axios.get(`/api/event/files/${category_id}/0/200`)
}

export const getFavoriteSportsVod = () => {
  return axios.get(`/api/event/favorite/list/0/20`)
}

export const getSearchSportsVod = (searchValue) => {
  return axios.get(`/api/event/search/${searchValue}/10`)
}

export const addTSportsVod = (id) => {
  return axios.post(`/api/event/favorite/${id}`)
}

export const deleteSportsVod = (id) => {
  return axios.delete(`/api/event/favorite/${id}`)
}

export const getPlayEvent = (id) => {
  return axios.get(`/api/play/event/${id}`)
}

//liveTv
export const getLiveTvCategories = () => {
  return axios.get(`/api/info/livetv/categories`)
}
export const getLiveTvCountryChannels = (id) => {
  return axios.get(`/api/info/livetv/categories/${id}/0/20`)
}
export const getLiveTvCategory = (category_id) => {
  return axios.get(`/api/info/livetv/categories/${category_id}/0/100`)
}

export const getFavoriteLiveTv = () => {
  return axios.get("/api/favorite/livetv/list/0/20")
}

export const getSearchLiveTv = (searchValue) => {
  return axios.get(`/api/info/livetv/search/${searchValue}/10`)
}

export const addLiveTv = (id) => {
  return axios.post(`/api/favorite/livetv/${id}`)
}

export const deleteLiveTv = (id) => {
  return axios.delete(`/api/favorite/livetv/${id}`)
}

// запрос на видео
export const getPlayLiveTv = (id) => {
  return axios.get(`/api/play/livetv.epg/${id}`)
}

// запрос на видео на страничке LiveTv
// export const getVideoLiveTvBillboard = () => {
//   return axios.get('/api/play/livetv.epg/5.star.max.eastern.us.m3u8');
// }
// запрос на видео на страничке LiveTv
export const getLiveTvBillboard = () => {
  return axios.get("/api/info/livetv/epg/5.star.max.eastern.us")
}

//profile
export const getProfiles = () => {
  return axios.get("/api/profiles")
}

export const getProfilesPictures = () => {
  return axios.get("/api/profiles/pictures")
}

export const createProfile = (profile) => {
  return axios.post("/api/profiles", profile)
}

export const editProfile = (id, profile) => {
  return axios.put(`/api/profiles/${id}`, profile)
}
export const deleteProfile = (id) => {
  return axios.delete(`/api/profiles/${id}`)
}

export const deleteProfileInfo = (id) => {
  return axios.delete(`/api/profiles/${id}/info`)
}

//tvGuide
export const getTvGuide = (id) => {
  return axios.get(`/api/info/livetv/categories/${id}/0/10?with_tv_guide=1`)
}

export const getTodaysTvGuideChannels = (ids) => {
  return axios.get(`/api/epg/${ids}`)
}
export const getTvGuideChannels = ({ categoryId }) => {
  return axios.get(`/api/epg/${categoryId}/0/2`)
}

export const getSearchGlobal = async (searchValue) => {
  try {
    const urls = [
      { name: "movies", api: getSearchMovie },
      { name: "tvShow", api: getSearchTvShow },
      { name: "sports", api: getSearchSportsVod },
      { name: "livetv", api: getSearchLiveTv },
    ]
    const promises = urls.map((item) => item.api(searchValue))
    const results = await Promise.all(promises)
    const response = _.zipObject(
      urls.map((item) => item.name), // keys
      results.map((result) => result.data) // values
    )
    return response
  } catch (error) {
    console.error("Could not complete search", error)
  }
}
