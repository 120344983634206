export default {
  "common": {
    "appStoreBanner": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We've noticed you're on a mobile device"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For a better experience, consider downloading our app (login credentials have been emailed to you)."])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download APP"])}
    },
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
    "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone Number"])},
    "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name"])},
    "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
    "userName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
    "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resend"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
    "verify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verify"])},
    "headerVerification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verify Your Account"])},
    "subtext1Verification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We've sent you a "])},
    "subtext2Verification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["message with a secure verification code."])},
    "placeholderVerification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the code here"])},
    "btnTextVerification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verify"])},
    "questionVerification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haven't received code or don't use WatsApp?"])},
    "getSms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get Code via SMS"])}
  },
  "headerNav": [
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Movie"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/movies"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TV Shows"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/tvshows"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Live TV"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/livetv"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SportsVOD"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/sports"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TV Guide"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/tvguide"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/search"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My List"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/mylist"])}
    }
  ],
  "cookieDisclosure": {
    "thirdParties": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["third parties "])},
    "cookiesAndSimilarTechnologies ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cookies and similar technologies "])},
    "LearnMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn more about our use of cookies and information."])},
    "Accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acept"])},
    "Reject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reject"])},
    "PersonaliseMyChoices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalise my choices"])}
  },
  "searchPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titles, peoples, genres"])},
  "header": {
    "accountDropdown": {
      "manageProfiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage Profiles"])},
      "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account"])},
      "helpCenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help Center"])}
    },
    "buttonKids": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kids"])}
  },
  "email": {
    "emailFormTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ready to watch? Enter your email to create or restart your membership."])},
    "input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Address"])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get Started"])}
  },
  "unsupported": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This show is available only in our "])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can continue watching using our mobile app."])},
    "textEnd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can also watch the latest "])},
    "mobileApp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" mobile app"])},
    "youHaveTrialAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have a trial account, to use our mobile app please create an account by clicking below."])}
  },
  "comingSoon": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coming Soon,"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currently available on the mobile and tv applications"])},
    "installationGuid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show installation instructions"])},
    "apple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" iPhone/iPad "])},
    "android": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Android Mobile/Tablet "])}
  },
  "signUp": {
    "stepOne": {
      "stepTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finish setting up your account"])},
      "stepText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["webPlayer is personalised for you. Create a password to watch on any device at any time."])}
    },
    "stepTwo": {
      "stepTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a password to start your membership"])},
      "stepTextOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Just a few more steps and you're finished!."])},
      "stepTextTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We hate paperwork, too."])},
      "inputEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add password"])},
      "checkbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes, please email me webPlayer special offers."])}
    },
    "stepThree": {
      "stepTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose your plan."])},
      "list": {
        "firstTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No commitments, cancel at any time."])},
        "nextTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Everything on webPlayer for one low price."])},
        "lastTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unlimited viewing on all your devices."])}
      }
    },
    "createAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Account"])},
    "createTrialAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Trial Account"])}
  },
  "loginForm": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign In"])},
    "inputEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "inputUsername": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
    "inputPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "inputRememberMe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remember me"])},
    "btnSignIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign In"])},
    "btnNeedHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot Password?"])},
    "btnSignUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign Up"])},
    "btnRegister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in for a free trial."])},
    "btnLearnMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn More"])},
    "btnPrivacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
    "btnTermsOfService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms of Service"])},
    "formInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This page is protected by Google reCAPTCHA to ensure you're not a bot."])},
    "errorLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User/Password are incorrect"])},
    "tips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The information collected by Google reCAPTCHA is subject to the Google"])},
    "tipsNext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["and is used for providing, maintaining and improving the reCAPTCHA service and for general security purposes (it is not used forpersonalised advertising by Google)."])},
    "myAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Account"])},
    "subscriptionExpired": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscription Expired"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your subscription has expired. Please renew your subscription to continue enjoying our services."])},
      "renewSubscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renew my Subscription"])}
    },
    "registerLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don't have an account?"])}
  },
  "forgotPassword": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot Email/Password"])},
    "subheaderStepOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How would you like to reset your password?"])},
    "subheaderStepTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please provide this information to help us find your account (all fields required):?"])},
    "labelEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "labelTextMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text Message(SMS)"])},
    "emailTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We will send you an email with instructions on how to reset your password."])},
    "btnStepOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email me"])},
    "inputFirstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name on account"])},
    "inputLastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name on account"])},
    "inputCredit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credit or debit card number on file"])},
    "btnStepSecond": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find Account"])},
    "btnCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I can't remember my email address or phone number."])}
  },
  "hero": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unlimited films, TV programmes and more."])},
    "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Watch anywhere. Cancel at any time."])}
  },
  "cardAnimation": {
    "watchOnTv": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enjoy on your TV."])},
      "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Watch on smart TVs, PlayStation, Xbox, Chromecast, Apple TV, Blu-ray players and more."])}
    },
    "downLoadAndWatch": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download your programmes to watch offline."])},
      "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save your favourites easily and always have something to watch."])}
    },
    "watchOnDevice": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Watch everywhere."])},
      "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stream unlimited films and TV programmes on your phone, tablet, laptop and TV without paying more."])}
    },
    "kidsCard": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create profiles for children"])},
      "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send children on adventures with their favourite characters in a space made just for them – free with your membership."])}
    }
  },
  "faq": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frequently Asked Questions."])},
    "faqQuestions": {
      "q1": {
        "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What can I watch on webPlayer?"])},
        "answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["webPlayer has an extensive library of feature films, documentaries, TV programmes, anime, award-winning webPlayer originals and more. Watch as much as you want, anytime you want."])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check out some of our content"])}
      },
      "q2": {
        "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What is webPlayer?"])},
        "answer_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["webPlayer is a streaming service that offers a wide variety of award-winning TV programmes, films, anime, documentaries and more – on thousands of internet-connected devices."])},
        "answer_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can watch as much as you want, whenever you want, without a single advert – all for one low monthly price. There's always something new to discover, and new TV programmes and films are added every week!"])}
      },
      "q3": {
        "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How much does webPlayer cost?"])},
        "answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Watch webPlayer on your smartphone, tablet, smart TV, laptop or streaming device, all for one fixed monthly fee. Plans range from €7,99 to €15,99 a month. No extra costs, no contracts."])}
      },
      "q4": {
        "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Where can I watch?"])},
        "answer_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["webPlayer has an extensive library of feature films, documentaries, TV programmes, anime, award-winning webPlayer originals and more. Watch as much as you want, anytime you want."])},
        "answer_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can also download your favourite programmes with the iOS, Android, or Windows 10 app. Use downloads to watch while you're on the go and without an internet connection. Take webPlayer with you anywhere."])}
      },
      "q5": {
        "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How do I cancel?"])},
        "answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["webPlayer is flexible. There are no annoying contracts and no commitments. You can easily cancel your account online in two clicks. There are no cancellation fees – start or stop your account at any time."])}
      },
      "q6": {
        "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is webPlayer good for children?"])},
        "answer_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The webPlayer Children's experience is included in your membership to give parents control while children enjoy family-friendly TV programmes and films in their own space."])},
        "answer_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Children's profiles come with PIN-protected parental controls that let you restrict the maturity rating of content children can watch and block specific titles you don’t want children to see."])}
      }
    }
  },
  "/ProfilesGate": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Who's watching?"])}
  },
  "/profiles/manage": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage Profiles:"])},
    "editProfile": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Profile"])},
      "sectionOneTitle": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maturity Settings"])},
        "firstString": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Maturity Ratings"])},
        "secondString": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Show titles of  <b>", _interpolate(_named("msg")), "</b> for this profile."])}
      },
      "sectionTwoTitle": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autoplay controls"])},
        "firstString": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autoplay next episode in a series on all devices."])},
        "secondString": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autoplay previews while browsing on all devices."])}
      }
    }
  },
  "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Done"])},
  "addProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Profile"])},
  "addProfileDescr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a profile for another person watching webPlayer."])},
  "kid?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kid?"])},
  "kidsTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If selected, this profile will only see TV shows and movies rated for ages 12 and under."])},
  "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "profileName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile Name"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
  "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
  "deleteProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Profile"])},
  "share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share"])},
  "watchNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Watch now"])},
  "withYourFriends": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["with your friends"])},
  "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
  "logout": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you leaving so soon?"])},
    "textFirst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You don't have to sign out of webPlayer every time. This is only necessary if you are using a public or shared computer."])},
    "textNext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will be redirected to webPlayer.com in 30 seconds. "])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go now"])}
  },
  "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help"])},
  "pageNotFound": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lost your way?"])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["webPlayer Home"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry, we can't find that page. You'll find lots to explore on the home page."])}
  },
  "moviesModal": {
    "cast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cast"])},
    "genres": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genres"])},
    "trailers&more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trailers & More"])},
    "seasonsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seasons"])},
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])},
    "director": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Director"])},
    "writer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Writer"])},
    "maturityRating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maturity Rating"])},
    "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company"])},
    "trailer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trailer"])}
  },
  "movies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Movies"])},
  "notifications": {
    "EmptyNotifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No resent notification"])}
  },
  "myLists": {
    "titlePage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lists"])},
    "titleMovie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Movies"])},
    "titlelTvShows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TV Shows"])},
    "titleChannels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Channels"])},
    "titleSportsVOD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sports VOD"])}
  },
  "search": {
    "titlePage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search by categories"])},
    "movies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Movies"])},
    "tvShows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TV Shows"])},
    "channels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Channels"])},
    "sportsVOD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sports VOD"])},
    "searchHere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search Here..."])},
    "searchmovies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search Movies..."])},
    "searchallmovies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search Movies..."])},
    "searchtvshows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search TV Shows..."])},
    "searchlivetv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search Channels..."])},
    "searchtvguide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search Channels..."])},
    "searchsearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search..."])},
    "searchmylist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search mylist..."])},
    "searchsports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search Games..."])}
  },
  "liveTv": {
    "titlePage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Live Tv"])},
    "selectedChannel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Country"])}
  },
  "sportsVOD": {
    "titlePage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sports VOD"])}
  },
  "TvShows": {
    "titlePage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TV Shows"])}
  },
  "tvGuide": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TV Guide"])},
    "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Channel"])},
    "tabs": {
      "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Today"])},
      "tomorrow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tomorrow"])},
      "dayAfterTomorrow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Day After tomorrow"])}
    },
    "selectedChannel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected Channel"])}
  },
  "logOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign out of WebPlayer"])},
  "play": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play"])},
  "moreInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More Info"])},
  "AccountPage": {
    "Account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account"])},
    "MembershipBilling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membership & Billing"])},
    "planDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PLAN DETAILS"])},
    "productInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Info"])}
  },
  "installation": {
    "appName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startup Show"])},
    "apple": {
      "example": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Example:"])},
      "toUse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To use"])},
      "goAppStore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["go to your App Store"])},
      "searchFor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for"])},
      "downloadApp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["and download the app"])},
      "toLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To login type in your"])},
      "m3uLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["M3U link"])},
      "top": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["top"])},
      "enterM3Uurl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter M3U URL"])},
      "leaveBottom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leave the bottom line BLANK. Do "])},
      "not": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NOT"])},
      "typeAny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["type anything in the bottom line "])},
      "pressBlue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Press the blue"])},
      "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["download"])},
      "enterEPG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter EPG URL (Optional)"])},
      "whereM3u": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["line where it says"])},
      "useM3U": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["which is found in your trial or subscription email. Type the M3U link in the"])},
      "ifAppDontLoad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If the app does not load or you get a spinning circle, force close the app and try again."])},
      "doubleCheck": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make sure to double check that the format of your m3u link is entered correctly."])},
      "passswordCaseSens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwords are Case-Sensitive."])}
    },
    "android": {
      "useApollo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To use ApolloGroup service service in your Android device, open Google Play Store, search for"])},
      "useAndInstall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["and install it."])},
      "toLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" To login type in the the following:"])},
      "addHttp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*You might need to add https:// at the start if you're unable to login."])}
    },
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["email"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["password"])},
    "linkUpdateDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Link updated October 2021)"])},
    "exampleBob": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Example: Bob's account info is:"])},
    "linkLookLike": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The link will look like"])},
    "YourlinkLookLike": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is your new link: "])}
  },
  "yourAccount": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Details"])}
  },
  "leadSignup": {
    "missingFields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In order to watch our free content please provide the following information"])},
    "credentialSentToYou1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Congratulations!"])},
    "credentialSentToYou2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your free trial is now activated."])},
    "credentialSentToYou3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have sent you an email with your user, password and link for the app for higher quality streaming."])},
    "credentialSentToYou4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you still prefer to use the web version, bear in mind that some content will not be available (only with Microsoft Edge)."])},
    "credentialSentToYou5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thanks and enjoy watching"])},
    "otpDialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We sent you a code to your phone number, please enter it below to continue."])}
  },
  "registerMobile": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This content is exclusively available on our app."])},
    "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For the best experience, download"])},
    "easyInstallation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For easy installation, follow our quick guide"])},
    "here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["here"])},
    "webAccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For web access, please download"])}
  }
}