<template>
  <div class="password-reset">
    <div class="password-reset__wrapper">
      <h1 class="password-reset__header">
        {{ $t("forgotPassword.title") }}
      </h1>
      <form @submit.prevent="" v-show="step === 1">
        <p class="password-reset__subheader">
          {{ $t("forgotPassword.subheaderStepOne") }}
        </p>
        <div class="password-reset__choice-container">
          <Checkbox
            type="radio"
            size="large"
            :label="$t('forgotPassword.labelEmail')"
          />
          <Checkbox
            type="radio"
            size="large"
            :label="$t('forgotPassword.labelTextMessage')"
          />
        </div>
        <div>
          <p class="password-reset__email-description">
            {{ $t("forgotPassword.emailTitle") }}
          </p>
          <Input
            class="password-reset__input"
            :placeholder="$t('forgotPassword.labelEmail')"
            v-model.trim="v$.email.$model"
          />
          <span
            v-for="(error, index) of v$.email.$errors"
            :key="index"
            class="inputError"
            >{{ error.$message }}</span
          >
        </div>
        <Button
          class="password-reset__button"
          theme="primary"
          :label="$t('forgotPassword.btnStepOne')"
        ></Button>
        <Button
          link
          theme="primary-large"
          :label="$t('forgotPassword.link')"
          @click="nextStep"
        />
      </form>
      <div>
        <form :model="form" @submit.prevent="" v-show="step === 2">
          <p class="password-reset__subheader">
            {{ $t("forgotPassword.subheaderStepTwo") }}
          </p>
          <br />
          <Input
            theme="default"
            class="password-reset__input"
            :label="$t('forgotPassword.inputFirstName')"
            v-model.trim="v$.form.firstName.$model"
          />
          <Input
            theme="default"
            class="password-reset__input"
            :label="$t('forgotPassword.inputLastName')"
            v-model.trim="v$.form.lastName.$model"
          />
          <Input
            theme="default"
            class="password-reset__input"
            :label="$t('forgotPassword.inputCredit')"
            v-model.trim="v$.form.cardNumber.$model"
          />

          <div class="password-reset__buttons">
            <Button
              class="password-reset__button"
              theme="primary-small"
              :label="$t('forgotPassword.btnStepSecond')"
              @click="handleSubmit"
              :disabled="v$.$invalid"
            ></Button>
            <Button
              theme="default"
              :label="$t('forgotPassword.btnCancel')"
              @click="backStep"
            />
          </div>
        </form>
      </div>
    </div>
  </div>
  <LoginOther class="login-info" />
</template>

<script>
import Checkbox from "@/components/Checkbox";
import Button from "@/components/Button";
import Input from "@/components/Input";
import LoginOther from "@/components/LoginOther";
import useVuelidate from "@vuelidate/core";
import { required, helpers, email } from "@vuelidate/validators";
export default {
  name: "PasswordResetForm",
  data() {
    return {
      v$: useVuelidate(),
      step: 1,
      email: "",
      form: {
        firstName: "",
        lastName: "",
        cardNumber: "",
      },
    };
  },
  validations() {
    return {
      email: helpers.withMessage("Please enter a valid email address", email),
      form: {
        firstName: { required },
        lastName: { required },
        cardNumber: { required },
      },
    };
  },
  components: {
    Checkbox,
    Button,
    Input,
    LoginOther,
  },
  methods: {
    handleSubmit() {
      this.v$.$validate();
    },
    nextStep() {
      if (this.step < 2) {
        this.step++;
      }
    },
    backStep() {
      if (this.step > 1) {
        this.step--;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.password-reset {
  background-color: $bg-light;
  color: $black80;
  @media only screen and (min-width: 1024px) {
    padding: 40px;
  }
  @media only screen and (min-width: 500px) {
    min-width: 380px;
    padding: 40px;
  }
  &__header {
    margin-bottom: 20px;
    font-size: 2rem;
  }
  &__subheader {
    margin: 16px 0;
    font-size: 1rem;
  }
  &__choice-container {
    margin-left: 25px;
  }
  &__email-description {
    margin: 16px 0;
    font-size: 1rem;
  }
  &__buttons {
    width: auto;
    display: flex;
  }
  &__button {
    margin-right: 0.5em;
  }
  &__input {
    padding-bottom: 32px;
    margin-top: 16px;
  }
}
.login-info {
  margin-top: 11px;
  font-size: 16px;
}
.inputError {
  color: $error;
  font-size: 13px;
  position: relative;
  padding: 6px 3px;
  margin-top: -12px;
  margin-bottom: 10px;
  display: flex;
}
</style>
