<template>
  <div>
    <GenericModal ref="signupModal" @cancel="onCancel">
      <p class="dialog-header">{{ $t('leadSignup.missingFields') }}</p>
      <div v-if="props.fields.includes('firstName')" :class="{ error: errors.firstName }">
        <Input class="form-container__inpts" type="text" theme="login" :label="$t(`common.firstName`)" v-bind="firstName" />
        <div class="input-errors">
          <div class="error-msg">{{ errors.firstName }}</div>
        </div>
      </div>
      <div v-if="props.fields.includes('lastName')" :class="{ error: errors.lastName }">
        <Input class="form-container__inpts" type="text" theme="login" :label="$t(`common.lastName`)" v-bind="lastName" />
        <div class="input-errors">
          <div class="error-msg">{{ errors.lastName }}</div>
        </div>
      </div>
      <div v-if="props.fields.includes('username')" :class="{ error: errors.username }">
        <Input class="form-container__inpts" type="text" theme="login" :label="$t(`common.username`)" v-bind="username" />
        <div class="input-errors">
          <div class="error-msg">{{ errors.username }}</div>
        </div>
      </div>
      <div v-if="props.fields.includes('email')" :class="{ error: errors.email }">
        <Input class="form-container__inpts" type="text" theme="login" :label="$t(`common.email`)" v-bind="email" />
        <div class="input-errors">
          <div class="error-msg">{{ errors.email }}</div>
        </div>
      </div>
      <div v-if="props.fields.includes('phone')" :class="{ error: errors.phone }">
        <PhoneNumberInput v-bind="phone" />
        <div class="input-errors">
          <div class="error-msg">{{ errors.phone }}</div>
        </div>
      </div>

      <template v-slot:actionButtons>
        <Button :label="$t('signUp.createAccount')" class="action-btn" @click="onSubmit"></Button>
      </template>
    </GenericModal>
    <OtpSignup ref="otpModal" @submit="onOtpSubmit" :phone="phone.modelValue" />
  </div>
</template>

<script setup>
import GenericModal from '@/components/dialogs/GenericModal.vue';
import Button from '@/components/Button.vue';
import PhoneNumberInput from '@/components/PhoneNumberInput.vue';
import Input from '@/components/Input.vue';

import { computed, defineEmits, defineExpose, defineProps, ref } from 'vue';
import { toTypedSchema } from '@vee-validate/zod';
import { z } from 'zod';
import { useForm } from 'vee-validate';
import { createRecaptchaToken } from '@/helpers/recaptcha';
import { actionTypes } from '@/store/modules/auth';
import { useStore } from 'vuex';
import OtpSignup from '@/components/LeadSignup/OtpSignup.vue';
import { isValidPhoneNumber } from 'libphonenumber-js';

const props = defineProps({
  fields: {
    type: Object,
  },
});

const signupModal = ref(null);
const otpModal = ref(null);
const store = useStore();

const schema = {
  firstName: z.string().min(1),
  lastName: z.string().min(1),
  username: z.string().regex(/^[0-9A-Za-z_/-@.]{6,16}$/, 'Username must be between 6 and 16 characters and can only contain letters, numbers, and the following symbols: _/-@.'),
  email: z.string().email(),
  phone: z.string().refine((val) => isValidPhoneNumber(val), 'Valid phone number is required'),
};

const validationSchema = computed(() => {
  const fieldsSchema = props.fields.reduce(
    (aac, cur) => ({
      ...aac,
      [cur]: schema[cur],
    }),
    {}
  );

  return toTypedSchema(z.object(fieldsSchema));
});

const { meta, errors, values, validate, defineComponentBinds, handleReset, setErrors } = useForm({
  validationSchema,
});

const firstName = defineComponentBinds('firstName');
const lastName = defineComponentBinds('lastName');
const username = defineComponentBinds('username');
const email = defineComponentBinds('email');
const phone = defineComponentBinds('phone');

const onSubmit = async () => {
  await validate();
  if (!meta.value.valid) {
    return;
  }
  await onLeadRegister(values);
};

const onCancel = () => {
  handleReset();
};

const emit = defineEmits(['success']);

async function onOtpSubmit(otp) {
  const errors = await onLeadRegister({
    ...values,
    otp,
  });
  if (errors) {
    otpModal.value.setError(errors);
  }
}

async function onLeadRegister(values = {}) {
  try {
    const token = await createRecaptchaToken('lead_signup_register');
    const response = await store.dispatch(actionTypes.leadRegistration, {
      clientData: values,
      captchaToken: token,
    });

    if (response.error?.fieldErrors) {
      if (response.error.fieldErrors.otp) {
        if (values.otp) {
          otpModal.value.setError(response.error.fieldErrors.otp);
        } else {
          otpModal.value.show();
        }
        return response.error.fieldErrors;
      } else {
        setErrors(response.error.fieldErrors);
        return;
      }
    }
    otpModal.value.close();
    emit('success', response.credentials);
  } catch (err) {
    console.error(err);
  }
}

defineExpose({
  show: () => signupModal.value?.show(),
  close: () => signupModal.value?.close(),
});
</script>

<style lang="scss" scoped>
.input-errors {
  margin-top: 5px;
}

.error-msg {
  text-align: left;
  font-size: 12px;
}

.error {
  color: red;
}

.dialog-header{
  font-size: 20px;
  margin-bottom: 20px;
  text-align: center;
}

.action-btn {
  background-color: #fb8b00;
  border-radius: 5px;
  border: none;
  color: white;
  padding: 5px 15px;
  font-size: 18px;
  font-weight: 500;
  margin-top: 15px;
  cursor: pointer;
}

[data-theme="apollogroup"] {
  .action-btn {
    background-color: #1caee5;
  }
}

[data-theme="pizzatime"] {
  .action-btn {
    background-color: #fb8b00;
  }
}

</style>
