<template>
  <header class="header" :class="'header--' + theme">
    <div class="header__container">
      <Logo class="header__logo" />
      <div v-if="showNavigation($route.path)" class="header__navigation">
        <HeaderNav />
        <!-- <div class="header_right"> -->
        <AppSearch v-if="!hideSearch" />
        <SecondaryNav />
        <!-- </div> -->
      </div>
      <div class="header__buttons" v-if="showHomeButtons($route.path)">
        <LanguagePicker class="header__lang-picker" />
        <!-- <Button link :label="$t('email.button')" theme="red" to="/home" /> -->
      </div>
      <Button
        v-if="showLoginButton($route.path)"
        link
        class="header__button-login"
        theme="login"
        :label="$t('loginForm.btnSignIn')"
        to="/"
      />
      <Button
        v-if="showHelpButton($route.path)"
        link
        class="header__button-login"
        theme="gray-large"
        label="Help"
        to="/Help"
      />
    </div>
  </header>
</template>

<script>
import Logo from "@/components/Logo";
import AppSearch from "./Search.vue";
import HeaderNav from "./HeaderNav";
import LanguagePicker from "@/components/LanguagePicker";
import Button from "@/components/Button";
import SecondaryNav from "@/components/Header/SecondaryNav";
import { getItem } from "@/helpers/storage";
import "./style.scss";
export default {
  name: "app-Header",
  components: {
    Logo,
    LanguagePicker,
    Button,
    HeaderNav,
    AppSearch,
    SecondaryNav,
  },
  props: {
    theme: {
      type: String,
      default: "",
    },
    hideSearch: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    showHomeButtons(route) {
      if (route === "/") {
        return true;
      }
    },
    showLoginButton(route) {
      if (route === "/loginHelp" || route === "/home") {
        return true;
      }
    },
    showHelpButton(route) {
      if (route === "/profiles/languagesetup") {
        return true;
      }
    },
    showNavigation(route) {
      const token = getItem("accessToken");
      if (token && !(route === "/logout")) {
        return true;
      }
    },
  },
};
</script>

<style lang="scss">
.header {
  &--login {
    background-color: rgba(0,0,0,0.4);
  }
}
</style>
