<template>
  <ul class="account-dropdown">
    <li class="account-dropdown__header-item">
      <router-link class="header-item-link" to="/YourAccount">
        <img class="account-dropdown-image" :alt="user?.name" :src="userAvatar" />
      </router-link>
      <ul class="sub-menu account-dropdown__sub-menu">
        <Down class="sub-menu__icon" />
        <div class="sub-menu__topbar"></div>
        <li class="sub-menu__item" v-if="!isTrial">
          <ul class="sub-menu__profile-list">
            <li class="sub-menu__profile-item padding-5" v-for="user in users" :key="user?.name">
              <router-link to="/YourAccount" class="sub-menu__buttons-link">
                <img class="account-dropdown-image" :alt="user.name" :src="user?.avatar" />
                <span>{{ user?.name }}</span>
              </router-link>
            </li>
          </ul>
        </li>
        <li class="sub-menu__item">
          <ul class="sub-menu__buttons-list">
            <!-- <li class="sub-menu__kids-item">
              <Button
                class="sub-menu__kids-button"
                link
                :label="$t('header.buttonKids')"
                to="/kids"
              />
            </li> -->
            <li class="sub-menu__buttons-item" v-if="!isTrial">
              <router-link to="/profiles/manage" class="sub-menu__buttons-link">
                <Profile class="sub-menu__buttons-icon" /><span> {{ $t("header.accountDropdown.manageProfiles") }}</span>
              </router-link>
            </li>
            <!-- <li class="sub-menu__buttons-item">
              <router-link to="/YourAccount" class="sub-menu__buttons-link">
                <Account class="sub-menu__buttons-icon" /><span>
                  {{ $t("header.accountDropdown.account") }}</span
                >
              </router-link>
            </li> -->
            <li class="sub-menu__buttons-item">
              <router-link to="/movies" class="sub-menu__buttons-link">
                <Help class="sub-menu__buttons-icon" /><span> {{ $t("header.accountDropdown.helpCenter") }}</span>
              </router-link>
            </li>
          </ul>
        </li>
        <li class="sub-menu__item">
          <Button class="account-dropdown__button-exit" link to="/logout" :label="$t('logOut')" />
        </li>
      </ul>
    </li>
  </ul>
</template>

<script>
import { actionTypes } from "@/store/modules/profile/profiles";
import { mapGetters, mapState } from "vuex";
import { getItem } from "@/helpers/storage";

import Button from "@/components/Button";
import Down from "@/assets/icons/svg/down.svg";
import Profile from "@/assets/icons/svg/profile.svg";
import Help from "@/assets/icons/svg/help.svg";
// import Account from "@/assets/icons/svg/account.svg";
import avatar from "@/assets/img/default_avatar.png";

export default {
  name: "AccountDropdown",
  components: {
    Button,
    Down,
    // Account,
    Help,
    Profile,
  },
  data() {
    return {
      user: getItem("currentProfile"),
      avatar: null,
    };
  },
  computed: {
    ...mapState({
      users: (state) => {
        return state.profiles.data;
      },
    }),
    ...mapGetters({ isTrial: "isTrial" }),
    userAvatar() {
      return this.avatar || avatar;
    },
  },
  mounted() {
    const img = new Image();
    img.src = this.user?.avatar;
    img.onload = () => {
      this.avatar = this.user?.avatar;
    };
    this.$store.dispatch(actionTypes.getProfiles);
  },
};
</script>

<style lang="scss">
.padding-5 {
  padding: 5px;
}
</style>
