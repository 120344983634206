<template>
  <div class="container">
    <Signup ref="formDialog" :fields="fields" @success="onSuccess"/>
    <p class="credential-sent" v-if="!success">{{ $t('unsupported.youHaveTrialAccount') }}</p>
    <button class="create-btn" @click="onLeadRegister">Create account</button>
    <Loader v-if="isLoading"/>
    <GenericModal ref="successDialog">
      <h1 class="dialog-header">{{ $t('leadSignup.credentialSentToYou1') }}</h1>
      <div class="dialog-content">
        <h2 class="dialog-header">{{ $t('leadSignup.credentialSentToYou2') }}</h2>
        <p>{{ $t('leadSignup.credentialSentToYou3') }}</p>
        <div class="dialog-content-smaller">
          <p>{{ $t('leadSignup.credentialSentToYou4') }}</p>
          <p class="content-bottom">{{ $t('leadSignup.credentialSentToYou5') }}</p>
        </div>
      </div>
      <template v-slot:actionButtons>
        <Button class="create-btn" :label="$t('common.close')" @click="closeSuccessDialog"/>
      </template>
    </GenericModal>
  </div>
</template>
<script setup>
import Button from '@/components/Button.vue';
import { defineEmits, ref } from 'vue';
import { actionTypes } from '@/store/modules/auth';
import { useStore } from 'vuex';
import Loader from '@/components/Loader/Loader.vue';
import { createRecaptchaToken } from '@/helpers/recaptcha';
import Signup from './Signup.vue';
import GenericModal from '@/components/dialogs/GenericModal.vue';

const successDialog = ref(null);
const formDialog = ref(null);
const isLoading = ref(false);
const success = ref(false);
const fields = ref([]);
const emit = defineEmits(['created']);
const store = useStore();

async function onSuccess() {
  formDialog.value.close();
  successDialog.value.show();
  success.value = true;
  emit('created');
}

async function onLeadRegister(values = {}) {
  try {
    isLoading.value = true;
    const token = await createRecaptchaToken('lead_signup_register');
    const response = await store.dispatch(actionTypes.leadRegistration, {
      clientData: values,
      captchaToken: token
    });
    if (response.error?.fieldErrors) {
      fields.value = Object.keys(response.error.fieldErrors);
      openFormModal();
      return;
    }
  } catch (err) {
    console.error(err);
  } finally {
    isLoading.value = false;
  }
}

function closeSuccessDialog() {
  successDialog.value.close();
}

function openFormModal() {
  formDialog.value.show();
}
</script>

<style lang="scss" scoped>
dialog::backdrop {
  background-color: rgba(0, 0, 0, 0.5);
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .form-container {
    max-width: 600px;
    margin: auto;
    padding: 45px;
    border: solid 1px white;
    border-radius: 5px;
    background-color: rgba(0, 0, 0);
    color: white;

    &__form-header {
      margin-bottom: 15px;
    }

    &__inpts {
      margin-bottom: 10px;
    }

    .btn--cancel {
      margin: 0 auto;
      margin-top: 30px;
    }

    &__disabled {
      pointer-events: none;
      opacity: 50%;
    }

    .error {
      color: red;
    }
  }

  .create-btn {
    background-color: #fb8b00;
    border-radius: 5px;
    border: none;
    color: white;
    padding: 5px 15px;
    font-size: 18px;
    font-weight: 500;
    margin-top: 15px;
    cursor: pointer;
  }

  .close-btn {
    cursor: pointer;
    color: white;
    position: absolute;
    right: 15px;
    top: 15px;
  }

  .credential-sent {
    margin-top: 30px;
    text-align: center;
    font-size: 14px;
  }

  .error-msg {
    text-align: left;
    font-size: 12px;
  }

  .dialog-button {
    margin-top: 40px;
  }
}

.dialog-header {
  font-size: 20px;
  margin-bottom: 10px;
  text-align: center;
}

.dialog-subheader {
  font-size: 16px;
  margin-bottom: 20px;
  text-align: center;
}

.dialog-content {
  font-size: 16px;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.dialog-content-smaller {
  margin-top: 30px;
  font-size: 13px;
}

.content-bottom {
  font-size: 16px;
  margin-top: 20px;
}

[data-theme="apollogroup"] {
  .create-btn {
    background-color: #1caee5;
  }
}

[data-theme="pizzatime"] {
  .create-btn {
    background-color: #fb8b00;
  }
}
</style>
