// import { getTrialMovie } from '@/api/backoffice';
import {
  getPlayMovie,
  getPlayTvShow,
  getPlayEvent,
  getPlayLiveTv,
  getSubtitlesTvShow,
  getMovieSubtitles,
  getInfoTvShow
} from '@/api/index.js';
import { convertToTracks } from "@/helpers/storage";
export default {
  state: {
    subtitles: [],
    infoTvShow: null,
    linkPlay: null,
    isLoading: false,
    error: null
  },
  actions: {
    async getLinkMovie({ commit, dispatch }, { id }) {
      commit('getInitStart')
      try {
        // const func = rootGetters.isTrial ? getTrialMovie : getPlayMovie;
        const response = await getPlayMovie(id);
        if (response.status !== 200) {
          throw new Error(`HTTP error: ${response.status}`);
        }
        commit('getLinkSuccess', response.data.data)
        dispatch('getSubtitlesMovie', { id })
      } catch (error) {
        commit('getInitFailure')
        console.error(`Could not get products: ${error}`);
      }
    },
    async getSubtitlesMovie({ commit }, { id }) {
      try {
        const response = await getMovieSubtitles(id);
        if (response.status !== 200) {
          throw new Error(`HTTP error: ${response.status}`);
        }
        let textTracks = convertToTracks(response.data.data)
        commit('getSubtitlesSuccess', textTracks)
      } catch (error) {
        console.error(`Could not get products: ${error}`);
      }
    },
    async getLinkTvShow({ commit, dispatch }, { id, season, episode }) {
      commit('getInitStart')
      try {
        const response = await getPlayTvShow(id, season, episode);
        if (response.status !== 200) {
          throw new Error(`HTTP error: ${response.status}`);
        }
        commit('getLinkSuccess', response.data.data)
        dispatch('getSubtitlesTvShows', { id, season, episode })
        dispatch('getInfoTvShows', { id })
      } catch (error) {
        commit('getInitFailure')
        console.error(`Could not get products: ${error}`);
      }
    },
    async getInfoTvShows({ commit }, { id }) {
      try {
        const response = await getInfoTvShow(id);
        if (response.status !== 200) {
          throw new Error(`HTTP error: ${response.status}`);
        }
        commit('getInfoTvShowSuccess', response.data.data)
      } catch (error) {
        console.error(`Could not get products: ${error}`);
      }
    },
    async getSubtitlesTvShows({ commit }, { id, season, episode }) {
      try {
        const response = await getSubtitlesTvShow(id, season, episode);
        if (response.status !== 200) {
          throw new Error(`HTTP error: ${response.status}`);
        }

        let textTracks = convertToTracks(response.data.data)
        console.log('textTracks', textTracks)
        commit('getSubtitlesSuccess', textTracks)
      } catch (error) {
        console.error(`Could not get products: ${error}`);
      }
    },
    async getLinkSportVod({ commit }, { id }) {
      commit('getInitStart')
      try {
        const response = await getPlayEvent(id);
        if (response.status !== 200) {
          throw new Error(`HTTP error: ${response.status}`);
        }
        commit('getLinkSuccess', response.data.data)

      } catch (error) {
        commit('getInitFailure')
        console.error(`Could not get products: ${error}`);
      }
    },
    async getLinkLive({ commit }, { id }) {
      commit('getInitStart')
      try {
        const response = await getPlayLiveTv(id);
        if (response.status !== 200) {
          throw new Error(`HTTP error: ${response.status}`);
        }
        commit('getLinkSuccess', response.data.data)
      } catch (error) {
        commit('getInitFailure')
        console.error(`Could not get products: ${error}`);
      }
    },

  },
  mutations: {
    getInitStart(state) {
      state.isLoading = true
      state.linkPlay = false
      state.subtitles = null,
        state.infoTvShow = null
    },
    getInitFailure(state) {
      state.isLoading = false
    },
    getSubtitlesSuccess(state, payload) {
      state.isLoading = false
      state.subtitles = payload
    },
    getLinkSuccess(state, payload) {
      state.isLoading = false
      state.linkPlay = payload
    },
    getInfoTvShowSuccess(state, payload) {
      state.isLoading = false
      state.infoTvShow = payload
    },
  },


}