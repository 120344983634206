<template>
  <Header theme="register" :hideSearch="true" />
  <div class="signup">
    <StepOne :changeStep="stepNext" v-show="step === 1" />
    <StepTwo v-show="step === 2" />
    <StepThree v-show="step === 3" />
  </div>
  <Footer theme="gray" />
</template>

<script>
import StepOne from "@/components/Signup/StepOne";
import StepTwo from "@/components/Signup/StepTwo";
import StepThree from "@/components/Signup/StepThree";
import Header from "@/components/Header/Header";
import Footer from "@/components/Footer/Footer";

export default {
  name: "Signup",
  components: {
    StepOne,
    StepTwo,
    StepThree,
    Header,
    Footer,
  },

  data() {
    return {
      step: 1,
    };
  },
  methods: {
    stepNext() {
      this.step++;
    },
  },
};
</script>

<style lang="scss">
.signup {
  background-color: white;
  padding-bottom: 95px;
  &__steps {
    box-sizing: border-box;
    margin: 0 auto 15px;
    max-width: 978px;
    padding: 20px 3% 60px;
    color: $black;
  }
  &__title {
    font-size: 32px;
    font-weight: 700;
    margin: 0 0 0.4em;
    color: $black;
  }
  &__button {
    margin-top: 20px;
  }
}
</style>
