export default [
  {
    name: 'Movies',
    link: '/movies',
  },
  {
    name: 'TV Shows',
    link: '/tvshows',
  },
  {
    name: 'Live TV',
    link: '/livetv',
  },
  {
    name: 'SportsVOD',
    link: '/sports',
  },
  {
    name: 'TV Guide',
    link: '/tvguide',
  },
  {
    name: 'Search',
    link: '/search',
  },
  {
    name: 'My List',
    link: '/mylist',
  },
]


