<template>
  <form class="email-form" action="">
    <h3 class="email-form__title">{{ $t("email.emailFormTitle") }}</h3>
    <div class="email-form__wrapper">
      <!-- <div>
        <Input
          class="email-form__input"
          type="email"
          theme="email"
          id="email"
          :messages="{ required: 'Please fill in the Name field' }"
          :label="$t('email.input')"
          v-model.trim="v$.form.email.$model"
        />
        <span
          class="inputError"
          v-for="(error, index) of v$.form.email.$errors"
          :key="index"
        >
          {{ error.$message }}</span
        >
      </div> -->
      <a
        class="email-form__button"
        target="_blank"
        v-bind:href="this.registerLink + '/Register'"
        >{{ $t("signUp.createAccount") }}</a
      >
      <a
        class="email-form__button"
        target="_blank"
        v-bind:href="this.registerLink + '/register?trial=true'"
        >{{ $t("signUp.createTrialAccount") }}</a
      >
    </div>
  </form>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required, email, helpers } from "@vuelidate/validators";
import { mapState } from "vuex";

export default {
  name: "EmailForm",
  data() {
    return {
      v$: useVuelidate(),
      form: {
        email: "",
      }
    };
  },
  computed: {
    ...mapState({
      registerLik: (state) => state.config?.portalLink
    }),
  },
  handleSubmit() {
    this.v$.$validate();
  },
  validations() {
    return {
      form: {
        email: {
          required: helpers.withMessage("Email is required!", required),
          email: helpers.withMessage(
            "Please enter a valid email address",
            email
          ),
        },
      },
    };
  },
};
</script>
<style lang="scss" scoped>
.email-form {
  display: flex;
  flex-direction: column;
  @media only screen and (min-width: 1450px),
    only screen and (min-width: 950px) and (max-width: 1449px) {
    max-width: 950px;
    padding-top: 0.85rem;
  }
  &__wrapper {
    padding-top: 10px;
    @media only screen and (min-width: 1450px),
      only screen and (min-width: 950px) and (max-width: 1449px) {
      display: flex;
      flex-direction: row;
      margin: 0 auto;
      text-align: center;
    }
  }
  &__button {
    background-color: $bg-red;
    font-weight: 400;
    padding: 4px 10px;
    display: inline-flex;
    font-size: 16px;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    margin: 1em auto;
    min-height: 40px;
    &:hover {
      text-decoration: none;
    }
    &:first-child {
      margin-right: 20px;
    }
  }
  &__title {
    font-weight: 400;
    margin: 0 auto;
    max-width: 450px;
    color: $white;
    @media only screen and (min-width: 400px) and (max-width: 549px),
      only screen and (min-width: 550px) and (max-width: 949px) {
      font-size: 23px;
    }
    @media only screen and (min-width: 1450px),
      only screen and (min-width: 950px) and (max-width: 1449px) {
      font-size: 1.2rem;
      max-width: none;
      padding-bottom: 20px;
    }
  }
  &__input {
    max-width: 500px;
    margin: 0 auto;

    @media only screen and (min-width: 950px) and (max-width: 1449px) {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
      height: 60px !important;
      min-width: 450px;
    }
    @media only screen and (min-width: 1450px) {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
      height: 70px !important;
      min-width: 500px;
    }
    @media screen and (min-width: 740px) {
      height: 60px;
    }
  }
  // &__button {
  //   margin: 1em auto;
  //   min-height: 40px;
  //   @media only screen and (min-width: 950px) and (max-width: 1449px) {
  //     font-size: 1.625rem;
  //     min-height: 60px;
  //   }
  //   @media only screen and (min-width: 1450px),
  //     only screen and (min-width: 950px) and (max-width: 1449px) {
  //     border-bottom-left-radius: 0;
  //     border-top-left-radius: 0;
  //     margin: 0 !important;
  //   }
  //   @media only screen and (min-width: 1450px) {
  //     font-size: 1.875rem;
  //     min-height: 70px;
  //   }
  //   @media only screen and (min-width: 1450px),
  //     only screen and (min-width: 950px) and (max-width: 1449px) {
  //     margin: 0.5em auto;
  //     padding: 0 1em;
  //   }
  // }
}
.inputError {
  color: #ffa00a;
  font-size: 15px;
  display: flex;
  margin-bottom: -6px;
  padding: 6px 3px;
  max-width: 500px;
  justify-content: center;
  @media only screen and (min-width: 1450px),
    only screen and (min-width: 950px) and (max-width: 1449px) {
    position: absolute;
  }
}
</style>
