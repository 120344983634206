<template class="login-wrapper">
  <LoginLayout :item="pswReset" />
</template>

<script>
import { markRaw } from "vue";
import PasswordResetForm from "@/components/PasswordResetForm";
import LoginLayout from "@/layouts/LoginLayout";
export default {
  name: "LoginHelp",
  components: {
    LoginLayout,
  },
  computed: {
    pswReset() {
      return markRaw(PasswordResetForm);
    },
  },
};
</script>

<style lang="scss" scoped>
.login-wrapper {
  min-height: 100%;
  margin: 0;
  padding: 0;
  position: relative;
  z-index: 0;
}
</style>
