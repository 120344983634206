<template>
  <div class="logout">
    <h1 class="logout__title">{{ $t("logout.header") }}</h1>
    <p class="logout__body">{{ $t("logout.textFirst") }}</p>
    <div class="buttons">
      <Button
        class="logout__button"
        theme="logout"
        @click="logout"
        link
        :label="$t('logout.button')"
      />
      <Button
        class="logout__button"
        theme="logout"
        link
        to="/"
        :label="$t('back')"
      />
    </div>
  </div>
</template>

<script>
import { actionTypes as authActionTypes } from "@/store/modules/auth";
import { removeItem } from "@/helpers/storage";
import Button from "@/components/Button";
export default {
  name: "LogoutModal",
  components: {
    Button,
  },
  methods: {
    logout() {
      this.$store.dispatch(authActionTypes.logout).then(() => {
        removeItem("accessToken");
        removeItem("currentProfile");
        removeItem("savedPath");
        this.$router.push("/");
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.logout {
  .buttons {
    display: flex;
    justify-content: space-between;
  }
  color: $black;
  background-color: $white;
  padding: 3em 4em;
  margin: 20px auto 0;
  line-height: 1.5;
  &__title {
    margin-bottom: 0.5em;
    font-size: 1.6rem;
  }
  &__body {
    margin: 16px 0;
    font-size: 1rem;
  }
}
</style>
