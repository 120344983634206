<template>
  <div class="story-image-container">
    <div class="kids-card">
      <img class="kids-card__image" src="@/assets/img/kids.png" alt="kids" />
    </div>
  </div>
</template>

<script>
export default {
  name: "KidsCard",
};
</script>

<style lang="scss" scoped>
.kids-card {
  overflow: hidden;
  position: relative;
  &__image {
    height: auto;
    max-width: 100%;
  }
}
</style>
