<template>
  <div class="db">
    <div class="header-wrap">
      <Header :hideSearch="true" />
      <!-- <SubHeader :title="$t('myLists.titlePage')" /> -->
    </div>
    <div class="mainView">
      <Loader class="loader" v-if="isLoading" />
      <div>
        <div class="lists-wrapper">
          <div v-if="favoriteMovies.length">
            <h2 class="rowHeader ltr-0">
              {{ $t("myLists.titleMovie") }}
            </h2>
            <div class="lists-content">
              <div v-for="movie in favoriteMovies" :key="movie.imdb_id">
                <SliderItem :brandImage="brandImage" :item="movie" />
              </div>
            </div>
          </div>
          <div v-if="favouriteTvShows.length">
            <h2 class="rowHeader ltr-0">
              {{ $t("myLists.titlelTvShows") }}
            </h2>
            <div class="lists-content">
              <div v-for="tvShow in favouriteTvShows" :key="tvShow.imdb_id">
                <SliderItem :brandImage="brandImage" :item="tvShow" />
              </div>
            </div>
          </div>
          <div v-if="favouriteLisTvSports.length">
            <h2 class="rowHeader ltr-0">
              {{ $t("myLists.titleSportsVOD") }}
            </h2>
            <div class="lists-content">
              <div v-for="sportItem in favouriteLisTvSports" :key="sportItem.id">
                <SliderItemSport :item="sportItem" />
              </div>
            </div>
          </div>
          <div class="my-35" v-if="favouriteLiveTv.length">
            <h2 class="rowHeader ltr-0">
              {{ $t("myLists.titleChannels") }}
            </h2>
            <div class="lists-content">
              <div v-for="liveTv in favouriteLiveTv" :key="liveTv.id">
                <SliderLiveTv :item="liveTv" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer class="member-footer" />
  </div>
</template>

<script>
import Header from "@/components/Header/Header";
import Footer from "@/components/Footer/Footer";
// import SubHeader from "@/components/Header/SubHeader";
import SliderItem from "@/components/Slider/SliderItem";
import Loader from "@/components/Loader/Loader";
import SliderItemSport from "@/components/Slider/SliderItemSport";
import SliderLiveTv from "@/components/Slider/SliderLiveTv";
import { mapState } from "vuex";

export default {
  name: "MyList",
  components: {
    Header,
    Footer,
    // SubHeader,
    SliderItem,
    SliderItemSport,
    SliderLiveTv,
    Loader,
  },
  data() {
    return {
      isActive: false,
    };
  },

  computed: {
    ...mapState({
      favoriteMovies: (state) => state.favorite.movies,
      favouriteTvShows: (state) => state.favorite.tvShows,
      favouriteLiveTv: (state) => state.favorite.liveChannel,
      favouriteLisTvSports: (state) => state.favorite.sportChannel,
      isLoading: (state) => state.favorite.isLoading,
      brandImage: (state) => `/img/${state.config?.brandName}-logo.png`,
    }),
  },
  mounted() {
    this.$store.dispatch("getFavorites");
  },
};
</script>

<style lang="scss">
.lists {
  &-wrapper {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    padding: 0 4%;

    h2 {
      padding: 0 0 10px 10px;
      font-size: 24px;
    }
  }

  &-content {
    flex-wrap: wrap;
    display: flex;
    gap: 40px 10px;
    align-items: center;
    justify-content: flex-start;
    margin: 0 0 50px;

    @media only screen and (max-width: $mobile) {
      justify-content: center;
    }

    touch-action: pan-y;

    &>div {
      padding: 5px;
      width: 250px;
      height: 150px;
    }
  }
}

.my-35 {
  margin-top: 35px;
}

.loader {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
}
</style>
