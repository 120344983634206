<template>
  <div class="signup__steps">
    <div class="step-three">
      <div class="step-three__logo"></div>
      <div>
        <span>STEP 1 OF 3</span>
        <h1 class="signup__title">{{ $t("signUp.stepThree.stepTitle") }}</h1>
        <ul class="step-three__list">
          <li v-for="item in lists" :key="item" class="step-three__list-item">
            <Search />
            <Checkmark class="step-three__list-icon" />
            <p class="step-three__list-text">
              {{ $t(`signUp.stepThree.list.${item}`) }}
            </p>
          </li>
        </ul>
        <Button
          class="signup__button"
          theme="red-large"
          :label="$t('signUp.button')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Checkmark from "@/assets/icons/svg/checkmark.svg";
import Button from "@/components/Button";

export default {
  name: "StepThree",
  data() {
    return {
      lists: ["firstTitle", "nextTitle", "lastTitle"],
    };
  },
  components: {
    Checkmark,
    Button,
  },
};
</script>

<style lang="scss" scoped>
.step-three {
  max-width: 340px;
  margin: 0 auto;
  text-align: center;
  &__logo {
    margin: 100px 0 20px;
    height: 60px;
    width: 60px;
    background: url(https://assets.nflxext.com/ffe/siteui/acquisition/simplicity/Checkmark.png)
      no-repeat 50% 50%;
    background-size: 60px;
    height: 60px;
    display: inline-block;
    text-align: center;
  }

  &__list {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 30px;
    list-style: none;
    margin-top: 25px;
    text-align: left;
    &-item {
      align-items: flex-start;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      margin: 0;
      margin-top: 20px;
    }
    &-text {
      margin-left: 10px;
      font-size: 18px;
      color: $black;
    }
    &-icon {
      align-self: flex-start;
      color: $text-red;
      flex: none;
      height: 26px;
      width: 26px;
    }
  }
}
</style>
