<template>
  <div class="focus-trap-wrapper previewModal--wrapper detail-modal show">
    <div class="previewModal--container detail-modal mm">
      <div v-if="portalLink" class="previewModal--player_container">
        <iframe v-bind:src="`${portalLink}/Register?closeOnSignIn=true&trial=true&originTarget=${originTarget}`" width="100%" loading="eager"></iframe>
      </div>
      <div class="previewModal-close">
        <Close class="previewModal-close-icon" @click="click" />
      </div>
      <div class="previewModal--info">
        <div class="detail-modal-container"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Close from "@/assets/icons/svg/close.svg";

export default {
  name: "RegisterFree",
  props: {
    click: {
      type: Function,
      default: () => {},
    },
  },
  components: {
    Close,
  },
  data() {
    return {};
  },
  methods: {},
  mounted() {
    window.addEventListener("message", this.click);
  },
  beforeUnmount() {
    window.removeEventListener("message", this.click);
  },
  computed: {
    ...mapState({
      portalLink: (state) => state.config?.portalLink
    }),
    originTarget: () => window.location.href
  },
};
</script>

<style lang="scss" scoped>
.previewModal--container {
  width: 85vw;
  max-height: 85vh;
  min-height: 80vh;
  top: 80px;
  background-color: #000000;
  iframe {
    height: 80vh;
  }
}
.show {
  opacity: 1;
  visibility: visible;
  transition: all 0.5s;
}
</style>
