<template class="wrapper">
  <router-view :data-theme="theme" />
  <div class="focus-trap-wrapper previewModal--wrapper detail-modal" :class="{ show: isActive }" v-if="infoMovie">
    <InfoMovie :click="hideInfo" :item="infoMovie" />
  </div>
  <div @click.self="hideInfo" :class="{ showTemplate: isActive }" class="previewModal--backDrop"></div>
  <DownloadOurAppDialog v-if="!loading" />
</template>

<script>
import { actionTypes, mutationTypes } from '@/store/modules/auth';
import { actionTypes as configActionTypes } from '@/store/modules/config';
import { mapState } from 'vuex';
import InfoMovie from '@/components/InfoMovie/InfoMovie';
import './style.scss';
import DownloadOurAppDialog from '@/components/dialogs/DownloadOurAppDialog.vue';
import getChatProvider from '@/helpers/chats';
import { getItem } from '@/helpers/storage';
import { useHead } from '@unhead/vue'

export default {
  name: 'App',
  components: { DownloadOurAppDialog, InfoMovie },
  mounted() {
    this.$store.dispatch(actionTypes.getCurrentUser);
    this.$store.dispatch(configActionTypes.loadBrandConfig);
    this.$store.commit(mutationTypes.setGuidCredentials, getItem('guidCredentials'))
  },
  computed: {
    ...mapState({
      infoMovie: (state) => state.infoMovie.data,
      isActive: (state) => state.infoMovie.isActive,
      loading: (state) => !state.config.isConfigLoaded,
      chatProvider: (state) => state.config.chatProvider,
      brandName: (state) => state.config?.brandName,
      theme: (state) => {
        return state.config?.brandName || "";
      },
    }),
  },
  methods: {
    hideInfo() {
      this.$store.commit('handleShowInfoMovie', false);
    },
  },
  watch: {
    chatProvider: function (provider) {
      try {
        const injectChat = getChatProvider(provider);
        injectChat(this.brandName);
      } catch {
        //
      }
    },
    brandName() {
      useHead({
        link: [
          {
            rel: "icon",
            href: `${this.brandName.toLowerCase()}.png`
          }
        ]
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  overflow-x: hidden;
  width: 100%;
  min-height: 100%;
  margin: 0;
  display: flex;
  padding: 0;
  position: relative;
}

.show {
  opacity: 1;
  visibility: visible;
  transition: all 0.5s;
}

.showTemplate {
  opacity: 0.7;
  visibility: visible;
}
</style>
