<template>
  <Teleport
    :to="`#${videoTeleportStore.slideId}`"
    v-if="
      isMounted &&
      videoTeleportStore.slideId &&
      videoTeleportStore.videoData.trailer
    "
  >
    <div id="teleport" class="video-teleport" :class="{ show: this.showVideo }">
      <Player
        :video-settings="videoSettings"
        :onReady="onReady"
        :src="videoTeleportStore.videoData.trailer"
      />
      <div class="cover" />
    </div>
  </Teleport>
</template>

<script>
import Player from "@/components/YoutubePlayer.vue";
import { debounce } from "lodash";
import { reactive } from "vue";

export const videoTeleportStore = reactive({
  videoData: {},
  slideId: null,
  setId(id) {
    // return null;
    if (!id) {
      this.slideId = null;
      this.videoData = {};
      this.isLoadVideo = false;
    }
    const deb = debounce((id) => {
      this.slideId = id;
    }, 1500);
    return deb(id);
  },
  setData(data) {
    // return null;
    const deb = debounce((data) => {
      this.videoData = data;
      this.isLoadVideo = true;
    }, 1500);
    return deb(data);
  },
});

export default {
  name: "VideoTeleport",
  components: {
    Player,
  },
  data() {
    return {
      isMounted: false,
      videoTeleportStore,
      src: "",
      videoSettings: {
        autoplay: 1,
        controls: 0,
        disablekb: 1,
        enablejsapi: 1,
        fs: 0,
        loop: 1,
        modestbranding: 1,
        showinfo: 0,
        autohide: 1,
        playsinline: 1,
        rel: 0,
      },
      videoPlayerState: 0,
      videoRef: null,
      isLoadVideo: false,
    };
  },
  computed: {
    showVideo() {
      return this.videoPlayerState === -1 || this.videoPlayerState === 1;
    },
  },
  watch: {
    $route() {
      videoTeleportStore.setId(null);
    },
  },
  mounted() {
    this.isMounted = true;
  },
  methods: {
    onReady(videoRef) {
      this.lookVideoState(videoRef);
    },
    lookVideoState(videoRef) {
      let interval = null;
      if (!this.videoPlayerState || this.videoPlayerState === -1) {
        interval = setInterval(() => {
          this.videoPlayerState = videoRef.getPlayerState();
        }, 500);
      } else {
        clearInterval(interval);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.video-teleport {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #000;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 0.5s linear;
  overflow: hidden;
  &.show {
    opacity: 1;
  }
  .cover {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }
}
</style>
