import { editProfile } from '@/api/index.js'
import {  setItem, getItem } from '@/helpers/storage'
const state = {
  isSubmitting: false,
  validationErrors: null,
  isLoading: false,
  profile: getItem('currentProfile') || null,
}

export const mutationTypes = {
  updateProfileStart: '[editProfile] Update profile start',
  updateProfileSuccess: '[editProfile] Update profile success',
  updateProfileFailure: '[editProfile] Update profile failure',
}

export const actionTypes = {
  updateProfile: '[editProfile] Update profile',
}

const mutations = {
  [mutationTypes.updateProfileStart](state) {
    state.isSubmitting = true
  },
  [mutationTypes.updateProfileSuccess](state) {
    state.isSubmitting = false
  },
  [mutationTypes.updateProfileFailure](state, payload) {
    state.isSubmitting = false
    state.validationErrors = payload
  }
}

const actions = {
  [actionTypes.updateProfile](context, {id, profile}) {
    return new Promise(resolve => {
      context.commit(mutationTypes.updateProfileStart)
      editProfile(id, profile)
        .then(res => {
          context.commit(mutationTypes.updateProfileSuccess, res.data.data)
          setItem('currentProfile', res.data.data)
          resolve(res)
        })
        .catch(result => {
          context.commit(
            mutationTypes.updateProfileFailure,
            result.response.data.errors
          )
        })
    })
  },
}

export default {
  state,
  actions,
  mutations
}
