<template>
  <div class="billboard-row">
    <div class="ptrack-container billboard-presentation-tracking">
      <div
        class="billboard billboard-pane billboard-originals trailer-billboard"
      >
        <div class="billboard-motion dismiss-mask">
          <div class="motion-background-component bottom-layer full-screen">
            <div v-if="true">
              <Player
                :v-if="autoReview"
                class="hero-player"
                :on-ready="videoOnReadyState"
                :video-settings="videoSettings"
                :src="item.meta.trailer"
              />
            </div>
            <div class="hero-image-wrapper">
              <img
                class="static-image image-layer"
                :class="{ hide: isHidePoster }"
                :src="item.meta?.fanart"
                alt="Video poster"
              />
              <div class="trailer-vignette vignette-layer"></div>
              <div class="hero-vignette vignette-layer"></div>
              <div class="embedded-components button-layer"></div>
            </div>
            <div class="embedded-components button-layer">
              <span class="ActionButtons" v-if="showVideoTrailer($route.path)">
                <button
                  class="round"
                  v-if="!videoPlayerState"
                  @click="reStartVideo"
                >
                  <Repeat />
                </button>
                <button
                  class="round sound"
                  ref="soundButton"
                  v-if="videoPlayerState > 0"
                  @click="toggleSound"
                >
                  <SoundOn v-if="isSoundOn" />
                  <SoundOff v-if="!isSoundOn" />
                </button>
              </span>
              <span v-if="item.meta.mppa" class="maturity-rating">
                <span class="maturity-number">
                  {{ item.meta.mppa }}
                  <!-- {{ item.defaultLang ? language : $t(item.meta.mppa) }} -->
                </span>
              </span>
            </div>
          </div>
        </div>
        <div class="fill-container">
          <div class="info meta-layer">
            <div class="logo-and-text meta-layer">
              <div class="info-wrapper" :class="{ hide: hideDescription }">
                <div class="synopsis-fade-container">
                  <div class="synopsis no-supplemental">
                    <div class="ptrack-content">
                      <p class="title-movie">
                        {{ item.meta?.title }}
                        <!-- {{ item.defaultLang ? language : $t(item.meta?.title) }} -->
                      </p>
                      <p class="ptrack-content-plot">
                        {{ plot }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="billboard-links button-layer forward-leaning">
                <button @click="handlePlayVideo(item.type)" class="play-link">
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      class="card__icon--black"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M21.44 10.72L5.96 2.98A1.38 1.38 0 004 4.213v15.474a1.373 1.373 0 002 1.233l15.44-7.74a1.38 1.38 0 000-2.467v.007z"
                      />
                    </svg>
                  </span>
                  <span class="play-text">{{ $t("play") }}</span>
                </button>
                <button @click="click" class="info-button ltr-1jtux27">
                  <Info />
                  <span class="play-text">{{ $t("moreInfo") }}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "./style.scss";
import { videoTeleportStore } from "@/components/Slider/VideoTeleport";
import Player from "@/components/YoutubePlayer.vue";

import Info from "@/assets/icons/svg/info.svg";
import Repeat from "@/assets/icons/svg/repeat.svg";
import SoundOn from "@/assets/icons/svg/soundOn.svg";
import SoundOff from "@/assets/icons/svg/soundOff.svg";
import { mapState } from "vuex";

export default {
  name: "Billboard",
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    click: {
      type: Function,
      default: () => {},
    },
  },
  components: {
    Info,
    Player,
    Repeat,
    SoundOn,
    SoundOff,
  },
  data() {
    return {
      defaultLanguage: "en",
      videoTeleportStore,
      videoSettings: {
        disablekb: 1,
        controls: 0,
        enablejsapi: 1,
        fs: 0,
        iv_load_policy: 3,
        modestbranding: 1,
        rel: 0,
        showinfo: 0,
        mute: 1,
        autoplay: 1,
      },
      videoPlayerState: -1,
      videoRef: null,
      isSoundOn: false,
    };
  },
  computed: {
    selectedLanguage() {
      return this.$i18n.locale || this.defaultLanguage;
    },
    isHidePoster() {
      if (videoTeleportStore.slideId && this.videoRef) {
        this.videoRef.pauseVideo();
      } else if (
        !videoTeleportStore.slideId &&
        this.videoRef &&
        this.videoRef.getPlayerState() > 0
      ) {
        this.videoRef.playVideo();
      }
      return this.videoPlayerState > 0;
    },
    plot() {
      const plot = this.item?.meta.plot;
      return plot.length > 150 ? plot.slice(0, 150) + "..." : plot;
    },
    hideDescription() {
      return this.videoPlayerState > 0 && this.videoPlayerState !== 2;
    },
  },
  methods: {
    handlePlayVideo(type) {
      type === "movie"
        ? this.handlePlayVideoMovie()
        : this.handlePlayVideoTvShows();
    },
    handlePlayVideoMovie() {
      this.$router.push({
        name: "Video",
        params: { slug: this.item.meta.title },
        query: {
          trackId: this.item.imdb_id,
          type: "getLinkMovie",
        },
      });
    },
    handlePlayVideoTvShows() {
      this.$router.push({
        name: "Video",
        params: { slug: this.item.meta.title },
        query: {
          trackId: this.item.imdb_id,
          season: 1,
          episode: 1,
          type: "getLinkTvShow",
        },
      });
    },
    showVideoTrailer(route) {
      if (route === "/movies") {
        return true;
      }
    },
    videoOnReadyState(videoRef) {
      this.videoRef = videoRef;
      videoRef.playVideo();
      this.lookVideoState();
    },
    lookVideoState() {
      let interval = null;
      if (!this.videoPlayerState || this.videoPlayerState === -1) {
        interval = setInterval(() => {
          this.videoPlayerState = this.videoRef.getPlayerState();
        }, 1000);
      } else {
        clearInterval(interval);
      }
    },
    reStartVideo() {
      this.videoRef.playVideoAt(1);
      this.videoRef.playVideo();
      this.lookVideoState();
    },
    toggleSound() {
      this.isSoundOn = !this.isSoundOn;
      this.videoRef[this.isSoundOn ? "unMute" : "mute"]();
    },
    ...mapState({
      autoReview: (state) => {
        return +state.movies.autoStartPreview;
      },
    }),
  },
};
</script>

<style lang="scss" scoped>
.billboard-play {
  &-text {
    padding-left: 5px;
    color: $black;
    font-weight: bold;
  }
}
.title-movie {
  color: $gray500;
  font-size: 32px;
  line-height: 36px;
  font-weight: 700;
  margin-bottom: 20px;
  @media only screen and (max-width: 349px),
    only screen and (min-width: 350px) and (max-width: 399px),
    only screen and (min-width: 400px) and (max-width: 768px) {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 25px;
  }
}
.info-wrapper {
  opacity: 1;
  transform: translateX(0px);
  transition-delay: 1s;
  &.hide {
    opacity: 0;
    transform: translateX(-30px);
  }
}
</style>
