<template>
  <div class="phone-number-input">
    <select
        class="countrycode-input"
        v-model="countryCode"
    >
      <option style="color: #8c8c8c" value="" disabled>
        select country code
      </option>
      <option v-for="country in countries" :key="country" :value="country.phoneCode">
        {{ country.name }} +{{ country.phoneCode }}
      </option>
    </select>
    <Input type="number" class="input-phone" theme="login" :label="$t('common.phoneNumber')" v-model="phoneNumber"/>
  </div>
</template>

<script setup>
import { getCountries, getPhoneCode } from 'libphonenumber-js';
import { getName } from 'country-list';
import { ref, defineEmits, defineProps, watch } from 'vue';

import Input from '@/components/Input.vue';

const countries = getCountries()
    .map((country) => {
      return {
        name: getName(country),
        code: country,
        phoneCode: getPhoneCode(country)
      };
    })
    .filter((country) => country.name);

const countryCode = ref('');
const phoneNumber = ref('');

// eslint-disable-next-line no-unused-vars
const props = defineProps({
  modelValue: String
});

const emit = defineEmits(['update:modelValue']);

watch(() => {
  emit('update:modelValue', `+${countryCode.value}${phoneNumber.value}`);
});
//
// function updateCountryCode(e){
//   countryCode.value = e.target.value;
//   emit('update:modelValue', `+${countryCode.value}${phoneNumber.value}`);
// }
//
// function updatePhoneNumber(value) {
//   phoneNumber.value = value;
//   emit('update:modelValue', `+${countryCode.value}${phoneNumber.value}`);
// }

</script>


<style lang="scss" scoped>
.countrycode-input {
  border-radius: 0;
  color: #ffffff;
  height: 50px;
  border: none;
  border-bottom: solid 1px white;
  line-height: 50px;
  padding: 16px 5px 0;
  background: rgba(0, 0, 0, 0.8);
  width: 150px;

  &:focus-visible {
    outline: none;
  }

  margin-bottom: 10px;
}

.phone-number-input {
  display: flex;
}

.input-phone {
  width: 100%;
}
</style>
