<template>
  <div class="input-field">
    <input
      :type="type"
      class="input-field__input"
      :class="'input-field__input--' + theme"
      :id="id"
      required
      :placeholder="placeholder"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
    />
    <label :for="id" :class="'input-field__label--' + theme" class="input-field__label">{{ label }} </label>
  </div>
</template>

<script>
export default {
  name: 'Input',

  props: {
    label: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    placeholder: {
      type: String,
      default: ' ',
    },
    theme: {
      type: String,
      default: 'text',
    },
    modelValue: String,
  },
};
</script>

<style lang="scss" scoped>
.input-field {
  position: relative;
  &__label {
    color: $gray;
    font-size: 1rem;
    left: 11px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: 0.1s ease, top 0.1s ease, transform 0.1s ease;
    &--login {
      left: 5px;
      top: 40%;
    }
    &--default {
      bottom: 50%;
      left: 0;
      position: absolute;
      top: 0;
    }
  }
  &__input {
    box-sizing: border-box;
    color: $black;
    border-radius: 2px;
    border: 1px solid $gray;
    display: block;
    font-size: 16px;
    padding: 18px 11px 3px;
    width: 100%;
    outline: none;
    &--email {
      height: 100%;
    }
    &--login {
      border-radius: 0;
      color: $white;
      height: 50px;
      border: 0;
      border-bottom: 1px solid $gray60;
      line-height: 50px;
      padding: 16px 5px 0;
      background: rgba(0, 0, 0, 0.8);
    }
    &--login,
    &--email {
      &:focus ~ label,
      &:not(:placeholder-shown) ~ label {
        font-size: 13px;
        font-weight: 700;
        top: 6px;
        transform: translateY(0%);
        border: none;
      }
    }
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
