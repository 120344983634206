import english from "../locales/English.json";
import _ from "lodash"
export const getTimeFromMins = (time) => {
  let hours = Math.trunc(time / 60);
  let minutes = time % 60;
  return hours + "h " + minutes + "m";
};

export const createShareLink = (type, trackID, title) => {
  if (_.toLower(type) === "movie") {
    return `${window.location.origin}/video/${title}?trackId=${trackID}&type=getLinkMovie`;
  }
  if (_.toLower(type) === "tvshow") {
    return `${window.location.origin}/video/${title}?trackId=${trackID}&season=1&episode=1&type=getLinkTvShow`;
  }
};

export const shareLink = async (item) => {
  const url = createShareLink(item.type, item.imdb_id, item.meta.title);
  
  const title = `${english.watchNow} ${item.meta.title} ${
    item.type === "tvshow" ? "S1:EP1" : ""
  }`;
  
  if (navigator.share) {
    await navigator
      .share({
        title,
        text: `${english.share} ${item.meta.title} ${english.withYourFriends}!`,
        url,
      })
      .catch((error) => {
        console.log("Browser not supporting sharing.", error);
      });
  } else {
    // Fallback for not supporting browsers through whatsapp
    const encodedText = encodeURIComponent(title);
    const encodedUrl = encodeURIComponent(url);
    const shareUrl = `https://api.whatsapp.com/send?text=${encodedText}%20${encodedUrl}`;
    window.open(shareUrl, "_blank");
  }
};
