import { getLcWidget } from "../api/backoffice";

const injectFreshchat = (brand) => {
	const e = document.createElement('script');
	e.id = 'Freshdesk Messaging-js-sdk';
	e.async = !0;
	e.src = 'https://wchat.eu.freshchat.com/js/widget.js';
	document.head.appendChild(e);
	setTimeout(() =>  initiateFreshchat(brand), 5000);
};

const initiateFreshchat = async (brand) => {
	window.fcWidget.init({
		token: '6c7d603f-46bf-486b-b3d2-1cb7de762472',
		host: 'https://wchat.eu.freshchat.com'
	});
	try {
		const response = await getLcWidget(brand);
		await window.fcWidget.user.setProperties({
			origin: response.data?.data
		});
	} catch {
		console.error('Chat is unavailable.')
	}
};
/* eslint-disable */

const injectLiveChat = () => {
	(function(n, t, c) {
		function i(n) {
			return e._h ? e._h.apply(null, n) : e._q.push(n);
		}
		var e = {
			_q: [],
			_h: null,
			_v: '2.0',
			on: function() {
				i(['on', c.call(arguments)]);
			},
			once: function() {
				i(['once', c.call(arguments)]);
			},
			off: function() {
				i(['off', c.call(arguments)]);
			},
			get: function() {
				if (!e._h) throw new Error("[LiveChatWidget] You can't use getters before load.");
				return i(['get', c.call(arguments)]);
			},
			call: function() {
				i(['call', c.call(arguments)]);
			},
			init: function() {
				var n = t.createElement('script');
				(n.async = !0), (n.type = 'text/javascript'), (n.src = 'https://cdn.livechatinc.com/tracking.js'), t.head.appendChild(n);
			}
		};
		window.__lc = window.__lc || {};
		window.__lc.license = 14964534;
		!n.__lc.asyncInit && e.init(), (n.LiveChatWidget = n.LiveChatWidget || e);
	})(window, document, [].slice);
};




const getChatProvider = provider => {
	switch (provider) {
    case "freshchat":
      return injectFreshchat;
    case "livechat":
      return injectLiveChat;
    default:
      return  () => {};
  }
}

export default getChatProvider;