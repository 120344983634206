<template>
  <div class="secondary-nav">
    <!-- <Button
      class="secondary-nav__item"
      link
      :label="$t('header.buttonKids')"
      to="/kids"
    /> -->
    <Notifications class="secondary-nav__item" v-if="!isTrial" />
    <AccountDropdown class="secondary-nav__item" />
  </div>
</template>

<script>
import Notifications from "@/components/Notifications";
import AccountDropdown from "@/components/AccountDropdown";
import { mapGetters } from "vuex";

export default {
  name: "SecondaryNav",
  components: {
    // Button,
    Notifications,
    AccountDropdown,
  },
  computed: {
    ...mapGetters({ isTrial: "isTrial" }),
  },
};
</script>

<style lang="scss" scoped>
.secondary-nav {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: flex-end;
  position: relative;
  top: 0;
  &__item {
    margin-left: 20px;
    @media (max-width: 480px) {
      margin-left: 10px;
    }
  }
}
</style>
