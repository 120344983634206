import { getFavoriteTvShows, getMoviesFavorite, getFavoriteSportsVod, getFavoriteLiveTv } from "@/api/index.js";
import axios from "axios";

export default {
  state: {
    movies: [],
    tvShows: [],
    liveChannel: [],
    sportChannel: [],
    isLoading: false,
  },
  actions: {
    async getFavorites({ commit }) {
      commit("getFavoritesStart");
      const requests = [getMoviesFavorite(), getFavoriteTvShows(), getFavoriteSportsVod(), getFavoriteLiveTv()];
      try {
        const responses = await axios.all(requests);
        const moviesResp = responses[0];
        const tvShowsResp = responses[1];
        const sportVod = responses[2];
        const liveTv = responses[3];

        if (moviesResp.status === 200) {
          commit("getFavoriteMoviesSuccess", moviesResp.data.data);
        }
        if (tvShowsResp.status === 200) {
          commit("getFavoriteTvShowsSuccess", tvShowsResp.data.data);
        }
        if (sportVod.status === 200) {
          commit("getFavoriteSportChannelSuccess", sportVod.data.data);
        }
        if (liveTv.status === 200) {
          commit("getFavoriteliveChannelSuccess", liveTv.data.data);
        }
        commit("getFavoritesSuccess");
      } catch (error) {
        commit("getFavoritesFailure");
        console.error(`Could not get products: ${error}`);
      }
    },
    toggleMovieInFavorite({ commit, state }, { section, id }) {
      if (section === "movies") {
        commit(
          "getFavoriteMoviesSuccess",
          state.movies.filter((movie) => movie.imdb_id !== id)
        );
      }
      if (section === "tvShows") {
        commit(
          "getFavoriteTvShowsSuccess",
          state.tvShows.filter((movie) => movie.imdb_id !== id)
        );
      }
      if (section === "sportChannel") {
        commit(
          "getFavoriteSportChannelSuccess",
          state.sportChannel.filter((movie) => movie.id !== id)
        );
      }
      if (section === "liveChannel") {
        commit(
          "getFavoriteliveChannelSuccess",
          state.liveChannel.filter((movie) => movie.id !== id)
        );
      }
    },
  },
  mutations: {
    getFavoritesStart(state) {
      state.isLoading = true;
    },
    getFavoritesSuccess(state) {
      state.isLoading = false;
    },
    getFavoritesFailure(state) {
      state.isLoading = false;
    },
    getFavoriteMoviesSuccess(state, payload) {
      state.movies = payload;
    },
    getFavoriteTvShowsSuccess(state, payload) {
      state.tvShows = payload;
    },
    getFavoriteliveChannelSuccess(state, payload) {
      state.liveChannel = payload;
    },
    getFavoriteSportChannelSuccess(state, payload) {
      state.sportChannel = payload;
    },
  },
};
