<template>
  <div class="faq">
    <h1 class="story-title">
      {{ $t("faq.title") }}
    </h1>
    <Accordion />
  </div>
</template>

<script>
import Accordion from "@/components/Accordion/Accordion";

export default {
  name: "Faq",
  components: {
    Accordion,
  },
};
</script>

<style lang="scss" scoped>
.faq {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  @media only screen and (min-width: 1450px),
    only screen and (min-width: 550px) and (max-width: 949px),
    only screen and (min-width: 950px) and (max-width: 1449px) {
    padding: 70px 45px;
  }
  @media only screen and (max-width: 349px),
    only screen and (min-width: 350px) and (max-width: 399px),
    only screen and (min-width: 400px) and (max-width: 549px) {
    padding: 50px 0;
  }
}
</style>
