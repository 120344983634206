<template>
  <div class="language-picker" @blur="open = false">
    <div
      class="language-picker__selected-option"
      :class="{ open: open }"
      @click="open = !open"
    >
      <Global
        class="language-picker__global"
        :class="'language-picker__global--' + theme"
      />
      <div
        class="language-picker__text"
        :class="'language-picker__text--' + theme"
      >
        {{ selected }}
      </div>
      <Down
        class="language-picker__down"
        :class="'language-picker__down--' + theme"
      />
    </div>
    <div class="language-picker__options" :class="{ selectHide: !open }">
      <div
        class="language-picker__option"
        :class="'language-picker__option--' + theme"
        v-for="locale in locales"
        :key="locale"
        @click="
          switchLocale(locale);
          selected = locale;
          open = false;
          $emit('input', locale);
        "
      >
        {{ locale }}
      </div>
    </div>
  </div>
</template>

<script>
import Down from "@/assets/icons/svg/down.svg";
import Global from "@/assets/icons/svg/global.svg";
export default {
  name: "LanguagePicker",
  props: {
    theme: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      selected: "English",
      open: false,
      locales: (
        process.env.VUE_APP_I18N_SUPPORTED_LOCALE || "English,Ukrainian"
      )?.split(","),
    };
  },
  components: {
    Down,
    Global,
  },
  methods: {
    switchLocale(locale) {
      if (this.$i18n.locale !== locale) {
        this.$i18n.locale = locale;
      }
    },
  },
  mounted() {
    this.$emit("input", this.selected);
  },
};
</script>

<style lang="scss" scoped>
.language-picker {
  padding: 5px 10px;
  display: block;
  color: $white;
  width: auto;
  height: auto;
  position: relative;
  border: 1px solid $gray;
  outline: none;
  &__selected-option {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  &__text {
    font-size: 11px;
    color: $white;
    padding: 0.3rem 1.375rem 0.3rem 0.2rem;
    white-space: nowrap;
    cursor: pointer;
    user-select: none;
    &--medium {
      font-size: 16px;
      line-height: 2;
      padding-left: 1.7rem;
      color: $dark-gray;
    }
  }
  &__global {
    fill: $white;
    width: 1rem;
    &--medium {
      fill: $dark-gray;
    }
  }
  &__down {
    fill: $white;
    &--medium {
      fill: $dark-gray;
    }
  }
  &__options {
    color: $white;
    position: absolute;
    margin-top: 6.5px;
    background-color: transparent;
    left: 0;
    right: 0;
    z-index: 1;
    border: 1px solid $white;
  }
  &__option {
    padding: 0.5rem 1.375rem;
    &--medium {
      font-size: 1rem;
      background-color: $black;
    }
    &:hover {
      background-image: linear-gradient(180deg, #0080ff, #0277ec);
    }
  }
}
.selectHide {
  display: none;
}
</style>
