<template>
  <div class="view-webplayer__tabs">
    <button
      v-for="tab in tabs"
      class="view-webplayer__tabs-button"
      @click="setActiveTab(tab.id)"
      :class="[activetab === tab.id ? 'active' : '']"
      :key="tab.id"
    >
      {{ $t(tab.text) }}
    </button>
  </div>
</template>

<script>
import "./style.scss";
export default {
  name: "SportsVOD",
  props: {
    tabs: Array,
    activetab: {
      default: 1,
      type: Number,
    },
    setActiveTab: Function,
  },
};
</script>
