<template>
  <div class="login-wrapper">
    <BgTemplate class="login-bg" />
    <Header theme="login" />
    <TrialOver v-if="lead" :lead="lead" :click="closeRegistration" />
    <div class="login">
      <div class="login-body">
        <div class="login-body-form">
          <LoginForm :error-login="validErrorLogin" @on-success-valid="handleSubmit" />
          <LoginOther class="login-body__other" :onRegisterNow="onRegisterNow" />
        </div>
      </div>
      <Footer theme="login" />
    </div>
    <SubscriptionExpiredDialog />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { actionTypes } from '@/store/modules/auth';
import LoginForm from '@/components/LoginForm';
import LoginOther from '@/components/LoginOther';
import Header from '@/components/Header/Header';
import Footer from '@/components/Footer/Footer';
import BgTemplate from '@/components/BgTemplate';
import TrialOver from '@/components/TrialOver';
import SubscriptionExpiredDialog from '@/components/dialogs/SubscriptionExpiredDialog.vue';

export default {
  name: 'Login',
  components: {
    SubscriptionExpiredDialog,
    LoginForm,
    LoginOther,
    Header,
    Footer,
    BgTemplate,
    TrialOver,
  },
  computed: {
    ...mapState({
      isSubmitting: (state) => state.auth.isSubmitting,
      lead: (state) => {
        return state.auth.lead;
      },
      validErrorLogin: (state) => state.auth.failedLogin,
    }),
  },
  methods: {
    closeRegistration(e) {
      if (!e?.data) this.$store.dispatch(actionTypes.closeLead);
      if (e?.data?.action == 'close') {
        this.$store.dispatch(actionTypes.closeLead);
        window.location.href = e.data.url;
      }
    },
    handleSubmit({ username, password }) {
      this.errorLogin = false;
      this.errorMsg = '';
      this.$store
        .dispatch(actionTypes.login, {
          username: username,
          password: password,
        })
        .then(() => {
          this.$router.push('/ProfilesGate');
        });
    },
  },
  beforeMount() {
    if (this.$route.params.guid) {
      this.$store.dispatch(actionTypes.guidLogin, this.$route.params).then(() => {
        this.$router.push('/ProfilesGate');
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.login {
  min-height: 100%;
  margin: 0;
  padding: 0;
  position: relative;
  z-index: 0;
  background-color: transparent;
}
.login-body-form {
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  //min-height: 50vh;
  padding: 20px 5% 30px;
  margin: 0 auto;
  padding-top: 110px;
  width: 100%;

  @media only screen and (min-width: 500px) {
    min-width: 420px;
  }
  @media only screen and (min-width: 740px) {
    margin-bottom: 90px;
    padding: 60px 40px 40px;
    margin-top: 140px;
  }

  .open-portal-btn {
    font-size: 0.8rem;
    margin-top: 15px;
    text-align: center;
    background-color: #b70616;
  }
}
.login-body__other {
  font-size: 13px;
}
.login-wrapper {
  min-height: 100vh;
  margin: 0;
  padding: 0;
  position: relative;
  z-index: 0;
  background-color: $black;
}

// .login-bg {
//   @media only screen and (max-width: 740px) {
//     display: none;
//   }
// }
</style>
