<template>
  <div class="explations">
    <article class="article-body" id="article-body" rel="image-enlarge">
      <p>
        <span class="num">1.</span>{{ $t("installation.apple.toUse") }}
        <strong>{{ $t("installation.appName") }} </strong>,
        {{ $t("installation.apple.goAppStore") }}
      </p>
      <img src="@/assets/img/appStore.png" width="86" />
      <p>
        <span class="num">2.</span>{{ $t("installation.apple.searchFor") }}
        <strong>{{ $t("installation.appName") }} </strong>
        {{ $t("installation.apple.downloadApp") }}.
      </p>
      <p></p>
      <p>
        <span class="num">3.</span>{{ $t("installation.apple.toLogin") }}
        <strong> {{ $t("installation.apple.m3uLink") }}</strong>
        {{ $t("installation.apple.useM4U") }}
        <strong>{{ $t("installation.apple.top") }}</strong
        >{{ $t("installation.apple.whereM3u") }}
        <strong>{{ $t("installation.apple.enterM3Uurl") }}.</strong>
      </p>
      <p>
        <img src="@/assets/img/login.png" width="316" />
      </p>
      
      <p v-if="credentials?.m3uLink" >{{ $t("installation.YourlinkLookLike") }}:  
      <a >{{ credentials?.m3uLink }}</a></p>

      <p>{{ $t("installation.apple.example") }}</p>
      <p>
        <span style="font-size: 16px" 
          ><a href="tvnow.best/api/list/EMAIL/PASSWORD">
            tvnow.best/api/list/<strong>{{
              $t("installation.email").toLocaleUpperCase()
            }}</strong
            >/<strong>{{
              $t("installation.password").toLocaleUpperCase()
            }}</strong></a
          ></span
        >
      </p>
      <p><br /></p>
      <p>{{ $t("installation.exampleBob") }}</p>
      <p>
        <span style="color: rgb(101, 165, 236)">EMAIL</span>:
        <a
          class="email"
          href="/cdn-cgi/l/email-protection#d7b5b8b5e4e497b0bab6bebbf9b4b8ba"
          >bob33@gmail.com
        </a>
      </p>
      <p>
        <span style="color: rgb(101, 165, 236)">{{
          $t("installation.password").toLocaleUpperCase()
        }}</span
        >: 123bb1
      </p>
      <p>
        
        <br /></p>
      <p v-if="credentials?.m3uLink" >{{ $t("installation.YourlinkLookLike") }}:  
        <a >{{ credentials?.m3uLink }}</a>
        <br /></p>
      <p>{{ $t("installation.linkLookLike") }}:</p>
      <p>
        
        <a href="tvnow.best/api/list/EMAIL/PASSWORD">
          tvnow.best/api/list/<span>bob33@gmail.com</span>/123bb1</a
        >
      </p>
      <p>
        <img src="@/assets/img/apple_m3u.jpg" width="295" />
      </p>
      <p>
        <strong
          ><span style="font-size: 16px"
            ><a href="tvnow.best/api/list/EMAIL/PASSWORD"></a></span></strong
        ><span><a href="tvnow.best/api/list/EMAIL/PASSWORD"></a></span>
      </p>
      <p>
        <span class="num">4.</span>{{ $t("installation.apple.leaveBottom") }}
        <strong>{{ $t("installation.apple.not") }}</strong>
        {{ $t("installation.apple.typeAny") }}
        <strong>[{{ $t("installation.apple.enterEPG") }}]</strong>.
      </p>
      <p>
        <span
          ><span class="num">3.</span>{{ $t("installation.apple.pressBlue") }}
          <span style="color: rgb(41, 105, 176)"
            ><strong>{{
              $t("installation.apple.download").toUpperCase()
            }}</strong></span
          >
          button.</span
        >
      </p>
      <p></p>
      <p class="num">
        {{ $t("installation.apple.ifAppDontLoad") }}
      </p>
      <p class="num">
        {{ $t("installation.apple.doubleCheck") }}
      </p>
      <p class="num">
        {{ $t("installation.apple.passswordCaseSens") }}
      </p>
    </article>
  </div>
</template>

<script>
export default {
  name: "Apple",
  props: {
    credentials: {
      type: Object,
      default: () => null,
    }
  },
};
</script>

<style lang="scss" scoped>
strong {
  color: #fff;
}
.num {
  margin-inline-end: 5px;
  color: #ccc;
}
p {
  padding-top: 10px;
}
.email {
  color: $gray70;
}
img {
  margin-top: 10px;
}
</style>
