import { getMoviesCategories, addMovie, deleteMovie, getSearchGlobal } from "@/api/index.js";
import { commitGlobalSearch } from "@/helpers/storage";

// import { arrayFilterUniqueItemsById } from "@/helpers/storage";
export default {
  state: {
    categories: [],
    isLoading: false,
    isFinished: false,
    error: null,
    searchMovies: null,
    searchText: null,
  },
  actions: {
    async search({ commit }, { text }) {
      if (window.location.pathname.toLowerCase().startsWith("/movies") || window.location.pathname.toLowerCase().startsWith("/allmovies")) {
        commit("getMoviesByCategoryStart");
        if (text?.length) {
          const result = await getSearchGlobal(text);
          commitGlobalSearch(commit, text, result);
        } else {
          commit("setSearchMovies", { text: null, movies: null });
        }
      }
    },
    async initMoviesLoad({ dispatch }, { offset, size, toContinue }) {
      await dispatch("getFirstCategories", { offset, size, toContinue });
    },
    async loadMoreCategories({ commit, state }, { offset, size }) {
      if(state.isFinished) {
        return
      }
      commit("loadMoreCategoriesStart");
      try {
        const response = await getMoviesCategories(offset, size);

        if (response.status !== 200) {
          throw new Error(`HTTP error: ${response.status}`);
        }

        let categoriesList = response.data.data;
        commit("loadMoreCategoriesSuccess", categoriesList);

        if (response.data.data.length < size) {
          commit("finishedCategoryLoader", true);
        }
      } catch (error) {
        commit("getAllCategoriesMoviesSuccess");
        console.error(`Could not get products: ${error}`);
      }
    },
    async getFirstCategories({ commit }, { offset, size, toContinue }) {
      commit("getAllCategoriesMoviesStart");
      try {
        let commitFunction = "getAllCategoriesMoviesSuccess";
        do {
          const response = await getMoviesCategories(offset, size);

          if (response.status !== 200) {
            throw new Error(`HTTP error: ${response.status}`);
          }

          commit(commitFunction, response.data.data);

          if (toContinue && response.data.data.length === size) {
            offset += size;
            commitFunction = "loadMoreCategoriesSuccess";
          } else toContinue = false;
        } while (toContinue);
      } catch (error) {
        console.error(`Could not get products: ${error}`);
      }
    },
    // fillMoreMovieStore({ commit }, { moviesList }) {
    //   commit("getMoviesByCategoryStart");
    //   try {
    //     const filteredMovies = arrayFilterUniqueItemsById(
    //       moviesList,
    //       "imdb_id"
    //     );
    //     commit("addMoviesByCategorySuccess", filteredMovies);
    //   } catch (error) {
    //     commit("getMoviesByCategoryFailure");
    //     console.error(`Could not get movies: ${error}`);
    //   }
    // },
    // fillMovieStore({ commit }, { moviesList }) {
    //   commit("getMoviesByCategoryStart");
    //   try {
    //     const filteredMovies = arrayFilterUniqueItemsById(
    //       moviesList,
    //       "imdb_id"
    //     );
    //     commit("getMoviesByCategorySuccess", filteredMovies);
    //   } catch (error) {
    //     commit("getMoviesByCategoryFailure");
    //     console.error(`Could not get movies: ${error}`);
    //   }
    // },
    async switchFavouriteMovies({ state }, { item_id, category, is_favorite }) {
      return new Promise((resolve) => {
        const promise = is_favorite ? deleteMovie(item_id) : addMovie(item_id);
        promise
          .then((response) => {
            const arr = state.categories.find((c) => c.id === category).infos;

            const itemIndex = arr.findIndex((item) => item.imdb_id === item_id);
            arr[itemIndex].is_favorite = !arr[itemIndex].is_favorite;
            resolve(response);
          })
          .catch(() => {
            // commit(mutationTypes.addToFavoritesTvShowFailure())
          });
      });
    },
  },
  mutations: {
    setSearchMovies(state, payload) {
      state.searchMovies = payload.movies;
      state.searchText = payload.text;
      state.isLoading = false;
    },
    getAllCategoriesMoviesStart(state) {
      state.isLoading = true;
      state.categories = [];
    },
    getAllCategoriesMoviesSuccess(state, payload) {
      state.isLoading = false;
      state.categories = payload;
    },
    loadMoreCategoriesStart(state) {
      state.isLoading = true;
    },
    loadMoreCategoriesSuccess(state, payload) {
      state.isLoading = false;
      state.categories.push(...payload);
    },
    getAllCategoriesMoviesFailure(state) {
      state.isLoading = false;
    },

    getMoviesByCategoryStart(state) {
      state.isLoading = true;
      // state.movies = null
    },

    getMoviesByCategorySuccess(state, payload) {
      state.isLoading = false;
      state.movies = payload;
    },
    addMoviesByCategorySuccess(state, payload) {
      state.isLoading = false;
      state.movies.push(...payload);
    },

    getMoviesByCategoryFailure(state) {
      state.isLoading = false;
    },
    finishedCategoryLoader(state, payload) {
      state.isFinished = payload;
    },
  },
  getters: {
    favouriteListMovies: (state) => {
      return state.movies?.filter((movie) => movie.is_favorite);
    },
  },
};
