<template>
  <div class="db">
    <div class="pinning-header">
      <div class="pinning-header-container">
        <Header :hideSearch="true" />
        <!-- <SubHeader :title="$t('tvGuide.title')" /> -->
      </div>
    </div>

    <div id="tabs" class="tvguide-content__select">
      <SelectCountry
        :categories="categories"
        @input="getInfoTvGuide"
      ></SelectCountry>
    </div>

    <Tabs
      :activetab="activetab"
      :setActiveTab="setActiveTab"
      :tabs="tabs"
    ></Tabs>

    <div class="tvguide-content">
      <div class="lists-content justify-content">
        <Loader class="loader-tvGuide"  v-if="isLoading" />
      </div>
      <div
        v-if="!isLoading"
        class="cards-tvguide__wrapper scrolable-table-wraper"
      >
        <div class="cards-tvguide__wraper-column">
          <div class="cards-tvguide-title">
            <h2>
              {{ $t("tvGuide.channel") }}
            </h2>
          </div>
          <div v-for="(tvGuide, index) in tvGuides" :key="tvGuide.id">
            <div
              class="cards-tvguide-title"
              v-if="tvGuideChannels?.[index]?.full?.length"
            >
              <h2>
                {{ tvGuide.name }}
              </h2>
            </div>
          </div>
        </div>
        <div class="scrollable-table">
          <div class="cards-tvguide__wrapper">
            <div
              class="cards-tvguide__wraper-column"
              v-for="index in 24"
              :key="index"
            >
              <div class="cards-tvguide-title">
                <h2>{{ getTime(index) }}</h2>
              </div>
              <template v-for="channel in tvGuideChannels" :key="channel.id">
                <div
                  class="cards-tvguide-timerow tvguide-card"
                  v-if="channel.full?.length"
                >
                  <div
                    class="tvguide-card-item"
                    v-for="tvShow in getChannelTvShowsAt(channel.full, index)"
                    :key="tvShow.start"
                  >
                    <div class="tvguide-card__front">
                      <div class="tvguide-card-title">{{ tvShow.title }}</div>
                      <div class="tvguide-card-date">
                        {{ dateTime(tvShow.start, tvShow.stop) }}
                      </div>
                    </div>
                    <div class="tvguide-card-item__back">
                      <div class="tvguide-card-item__back-title">
                        <div>{{ tvShow.title }}</div>
                      </div>
                      <div class="tvguide-card-item__back-body">
                        {{ tvShow.desc }}
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer class="member-footer" />
  </div>
</template>

<script>
import { mapState } from "vuex";

import Header from "@/components/Header/Header";
import Footer from "@/components/Footer/Footer.vue";
import Loader from "@/components/Loader/Loader";
import moment from "moment";
import SelectCountry from "../LiveTv/SelectCountry.vue";
import Tabs from "@/components/Tabs/Tabs.vue";
// import SubHeader from "@/components/Header/SubHeader.vue";

export default {
  name: "TVGuide",
  components: {
    Header,
    Footer,
    Loader,
    SelectCountry,
    // SubHeader,
    Tabs,
  },
  data() {
    return {
      activetab: 0,
      tabs: [
        { id: 0, text: "tvGuide.tabs.today" },
        { id: 1, text: "tvGuide.tabs.tomorrow" },
        { id: 2, text: "tvGuide.tabs.dayAfterTomorrow" },
      ],
    };
  },

  methods: {
    setActiveTab(tab) {
      this.activetab = tab;
    },
    getChannelTvShowsAt(tvShows, time) {
      const startTime = moment()
        .startOf("minute")
        ?.add(time - 1, "hours")
        .add(this.activetab, "days");

      return tvShows.filter((i) => {
        const start = moment(i.start, "YYYY-MM-DDTHH:mm");
        const diff = start.diff(startTime, "minutes");
        return diff < 60 && diff > 0;
      });
    },
    getTime(index) {
      return moment()
        .startOf("minute")
        ?.add(index - 1, "hours")
        .add(this.activetab, "days")
        .format("MM/DD   -   HH:00");
    },
    getInfoTvGuide(id) {
      this.$store.dispatch("getInfoTvGuide", {
        id,
      });
    },
    dateTime(from, to) {
      return (
        moment(from, "YYYY-MM-DDTHH:mm").format("HH:mm ") +
        moment(to, "YYYY-MM-DDTHH:mm").format(" - HH:mm")
      );
    },
  },

  computed: {
    ...mapState({
      tvGuides: (state) => state.tvGuide.tvGuide,
      defaultCountry: (state) => state.liveTv.defaultCountry,
      tvGuideChannels: (state) =>
        state.tvGuide.tvGuideChannels?.map?.((i) => ({
          ...i,
          full: i.full?.filter((channel) => {
            const diff = moment(channel.start, "YYYY-MM-DDTHH:mm").diff(
              moment(),
              "hours"
            );
            return diff < 24 && diff > -1;
          }),
        })),
      categories: (state) => state.liveTv.categories?.filter((i) => i.is_genre),
      isLoading: (state) =>
        state.liveTv.isLoadingChannels ||
        state.liveTv.isLoadingGuid ||
        !state.tvGuide.tvGuideChannels ||
        !state.tvGuide.tvGuide,
    }),
  },

  mounted() {
    this.$emit("input", this.defaultCountry.countryName);
    this.$store.dispatch("getAllCategoriesLiveTv");
    this.$store.dispatch("getInfoTvGuide", {
      id: this.defaultCountry.countryCode,
    });
  },
};
</script>
<style lang="scss" scoped>
.tvguide-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
  justify-content: center;
  padding: 0 4% 4%;
  &__select {
    margin: 30px auto 0;
  }
  &__content__container {
    margin-bottom: 50px;
  }
  &-text {
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: 400;
    margin-right: 20px;
    color: $white;
    p {
      margin-right: 20px;
    }
  }

  .loader-tvGuide {
    left: 50%;
    top: 50%;
    z-index: 1;
  }
}
.cards-tvguide__wraper-column:first-child {
  position: relative;
  left: 0;
  top: 0;
}

.cards-tvguide {
  &__wrapper {
    display: flex;
  }
  &-timerow {
    display: flex;
  }
  &-title {
    min-width: 100;
    height: 60px;
    display: flex;
    border-left: 2px solid $gray60;
    border-bottom: 2px solid $gray60;
    align-items: center;
    padding: 0 20px;
    &:first-child {
      border: 2px solid $gray60;
      border-right: 0;
    }
    &:last-child {
      border-bottom: 2px solid $gray60;
      border-right: 2px solid $gray60;
    }
    h2 {
      font-size: 15px;
    }
  }
}
.selectHide {
  display: none;
}

.tvguide-card {
  display: flex;
  background-color: $black80;
  position: relative;
  min-width: 320px;
  height: 60px;
  &-row {
    display: flex;
    min-width: 100%;
    overflow-x: auto;
  }
  &-item {
    min-width: 150px;
    padding: 5px 10px;

    &:hover &__back {
      display: flex;
      z-index: 100;
    }
    &__back {
      border-radius: 10px;
      position: absolute;
      display: none;
      flex-direction: column;
      width: 350px;
      border-radius: 5px;
      background-color: $black18;
      bottom: -20%;
      padding: 15px;
      &-title {
        display: flex;
        flex-direction: column;
        align-items: start;
        font-size: 11px;
        color: $gray500;
        border-bottom: 1px solid $black80;
        padding-bottom: 3px;
        div {
          padding-bottom: 5px;
        }
      }
      &-body {
        padding-top: 10px;
        font-size: 13px;
        line-height: 16px;
        color: $gray500;
        text-align: justify;
      }
    }
  }
  border-bottom: 1px solid $gray60;
  border-left: 2px solid $gray60;
  border-top: 1px solid $gray60;
  &-title {
    font-size: 12px;
    padding: 0.3rem 1.375rem 0.3rem 0.2rem;
  }
  &-date {
    font-size: 11px;
    font-weight: bold;
    padding: 0 1.375rem 0 0.2rem;
  }
}
.column {
  flex-direction: column;
}
.mainView-aligned {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
}
.scrolable-table-wraper {
  max-width: 100%;
  overflow: hidden;
}
.scrollable-table {
  max-width: 100%;
  width: 100%;
  overflow-x: scroll;
  background-color: $black80;
  border: solid 1px $gray60;
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    height: 5px;
    border-radius: 15px;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    height: 5px;
    background-color: #f5f5f5;
  }
}
.cards-tvguide-title,
.db {
  background-color: $black30;
}
.width-250 {
  width: 250px;
}
.view-webplayer__tabs {
  margin: 20px 0 0;
  -webkit-transform: scale(0.67);
  -moz-transform: scale(0.67);
  transform: scale(0.67);
}
</style>
