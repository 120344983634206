const getCategoryImage = (name) => {
  try {
    let nameCheck = name.split(" ");
    if (nameCheck.length > 1) {
      nameCheck = nameCheck.join("-");
    } else {
      nameCheck = name;
    }
    return require(`@/assets/categories/${nameCheck}.jpg`);
  } catch (err) {
    console.warn("getCategoryImage", err);
    return require(`@/assets/categories/All.jpg`);
  }
};

export default getCategoryImage;
