<template>
  <div class="our-story-container">
    <CookieDisclosure v-if="false" />
    <Header />
    <div class="our-story-cards">
      <div class="story-card-wrap">
        <BgTemplate />
        <Hero />
      </div>
      <CardsSection />
      <div class="story-card-wrap">
        <Faq />
      </div>
    </div>
    <Footer theme="home" />
  </div>
</template>

<script>
import Hero from "@/components/Hero";
import CardsSection from "@/components/CardsSection";
import Faq from "@/components/Faq";
import Header from "@/components/Header/Header";
import Footer from "@/components/Footer/Footer";
import BgTemplate from "@/components/BgTemplate";
import CookieDisclosure from "@/components/CookieDisclosure";

export default {
  name: "Home",
  components: {
    Hero,
    BgTemplate,
    CardsSection,
    Faq,
    Header,
    Footer,
    CookieDisclosure,
  },
};
</script>

<style lang="scss" scoped>
.our-story-container {
  overflow-x: hidden;
  width: 100%;
}
.our-story-cards {
  margin: 0 auto;
}
</style>
