<template>
  <div
    class="view-search__content-input"
    :class="showInput ? 'show-search-input' : ''"
  >
    <input
      v-if="showInput"
      type="text"
      v-model.trim="searchMovie"
      v-on:keyup.enter="getDataMovies"
      v-on:change="textChange"
      :placeholder="$t(searchText)"
    />
    <SearchIcon @click="searchClicked" class="view-search__content-icon" />
  </div>
</template>
<script>
import SearchIcon from "@/assets/icons/search.svg";
export default {
  name: "app-search",
  components: {
    SearchIcon,
  },
  data() {
    return {
      searchMovie: "",
      showInput: window.innerWidth > 1000,
      searchText:
        "search.search" + location.pathname.toLowerCase().split("/")[1],
    };
  },
  props: {
    theme: {
      type: String,
      default: "",
    },
  },
  methods: {
    searchClicked() {
      if (!this.showInput) this.showInput = true;
      else this.getDataMovies();
    },
    textChange() {
      if (!this.searchMovie.length) {
        this.$store.dispatch("search", {
          text: "",
        });
      }
    },
    async getDataMovies() {
      try {
        this.$store.dispatch("search", {
          text: this.searchMovie,
        });
        this.showInput = window.innerWidth > 1000;
      } catch (e) {
        console.log(e.message);
      }
    },
  },
};
</script>
