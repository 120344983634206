<template>
  <div class="signup__steps">
    <div class="step-two">
      <form>
        <div>
          <span>STEP 1 OF 3</span>
          <h1 class="signup__title">{{ $t("signUp.stepTwo.stepTitle") }}</h1>
        </div>
        <div class="step-two__text">
          {{ $t("signUp.stepTwo.stepTextOne") }}
        </div>
        <div class="step-two__text">
          {{ $t("signUp.stepTwo.stepTextTwo") }}
        </div>
        <div class="step-two__input-wrapper">
          <Input
            class="step-two__input"
            type="text"
            id="email"
            :label="$t('signUp.stepTwo.inputEmail')"
          />
          <Input
            class="step-two__input"
            type="password"
            id="pasw"
            :label="$t('signUp.stepTwo.password')"
          />
        </div>
        <Checkbox id="rememberMe" :label="$t('signUp.stepTwo.checkbox')" />
        <Button
          class="signup__button"
          theme="red-large"
          :label="$t('signUp.button')"
        />
      </form>
    </div>
  </div>
</template>

<script>
import Button from "@/components/Button";
import Input from "@/components/Input";
import Checkbox from "@/components/Checkbox";
export default {
  name: "StepOne",
  components: {
    Button,
    Input,
    Checkbox,
  },
};
</script>

<style lang="scss" scoped>
.step-two {
  margin: 0 auto;
  max-width: 440px;
  text-align: left;
  &__text {
    font-size: 18px;
    margin-bottom: 0;
    margin-top: 10px;
  }
  &__input {
    margin-bottom: 10px;
  }
  &__input-wrapper {
    margin: 16px 0 20px;
  }
}
</style>
