<template>
  <div class="profile-actions-container">
    <h1>{{ $t("/profiles/manage.editProfile.title") }}</h1>

    <div class="profile-metadata profile-entry">
      <div class="main-profile-avatar">
        <div class="avatar-box">
          <button @click="handleGetPictures" class="avatar-edit-icon position">
            <img
              class="account-dropdown-image"
              :alt="user?.name"
              :src="user?.avatar"
            />
            <EditIcon class="edit-icon" />
          </button>
          <input
            type="text"
            id="add-profile-name"
            :placeholder="user?.name"
            v-model="name"
          />
        </div>
      </div>

      <div class="profile-edit-parent">
        <div class="profile-entry-dropdowns">
          <div class="profileDropDown">
            <h2
              class="profileDropDown-label"
              id="profileLanguageDropDown-header"
            >
              {{ $t("language") }}:
            </h2>
            <LanguagePicker />
          </div>
        </div>
      </div>
    </div>
    <div class="buttons-container">
      <button
        class="profile-button preferred-action"
        @click="handleEditProfile"
      >
        {{ $t("save") }}
      </button>
      <button @click="handleClose" class="profile-button">
        {{ $t("cancel") }}
      </button>
      <button class="profile-button" @click="handleDeleteProfile">
        {{ $t("deleteProfile") }}
      </button>
    </div>
  </div>
  <div :class="{ show: isActive }" class="pictures-modal">
    <div v-for="picture in pictures" :key="picture.file">
      <button
        @click="handleSetPicture(picture.file)"
        class="pictures-modal-button"
      >
        <img
          class="pictures-modal-image"
          :alt="picture.file"
          :src="picture?.url"
        />
      </button>
    </div>
  </div>
  <div
    @click.self="hideInfo"
    :class="{ show: isActive }"
    class="previewModal--backDrop"
  ></div>
</template>

<script>
import LanguagePicker from "@/components/LanguagePicker";
import { mapState } from "vuex";
import EditIcon from "@/assets/icons/svg/EditIcon";
import { actionTypes as actionPictures } from "@/store/modules/profile/profilePictures";
import { actionTypes as actionEdit } from "@/store/modules/profile/editProfile";
import { actionTypes as actionDelete } from "@/store/modules/profile/profiles";
// import CheckIcon from "@/assets/icons/svg/CheckIcon";
import { getItem } from "@/helpers/storage";
export default {
  name: "EditProfile",

  data() {
    return {
      user: getItem("currentProfile"),
      name: this.user?.name,
      isActive: false,
      setIcon: "",
    };
  },
  components: {
    LanguagePicker,
    EditIcon,
    // CheckIcon,
  },
  computed: {
    ...mapState({
      pictures: (state) => state.profilePictures.data,
    }),
  },
  methods: {
    handleGetPictures() {
      return (this.isActive = true);
    },
    handleClose() {
      this.$store.commit("handleEditProfile", false);
    },
    handleDeleteProfile() {
      this.$store.dispatch(actionDelete.deleteProfiles, {
        id: this.user.profile_number,
      });
      this.$store.commit("handleAddProfile", false);
      this.$store.commit("handleEditProfile", false);
      this.$router.push("/ProfilesGate");
    },
    handleEditProfile() {
      this.$store.dispatch(actionEdit.updateProfile, {
        id: this.user.profile_number,
        profile: {
          name: this.name,
          avatar: this.setIcon,
        },
      });
      this.$store.commit("handleAddProfile", false);
      this.$store.commit("handleEditProfile", false);
      this.$router.push("/ProfilesGate");
    },
    handleSetPicture(fileName) {
      this.setIcon = fileName;
      console.log(fileName);
      return (this.isActive = false);
    },
  },
  mounted() {
    this.$store.dispatch(actionPictures.getProfilePictures);
  },
};
</script>

<style lang="scss" scoped>
@import "style";
.pictures-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: $black10;
  transform: translate(-50%, -50%);
  z-index: 100;
  width: 700px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  display: none;

  @media only screen and (min-width: 550px) and (max-width: 949px) {
    width: 580px;
  }
  @media only screen and (max-width: 349px),
    only screen and (min-width: 350px) and (max-width: 399px),
    only screen and (min-width: 400px) and (max-width: 549px) {
    width: 350px;
  }
  &-image {
    height: 58px;
    width: 58px;
  }
  &-button {
    align-items: center;
    background-color: transparent;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: contain;
    border: none;
    border-radius: 4px;
    box-sizing: border-box;
    display: flex;
    position: relative;
  }
}
.edit-icon {
  position: absolute;
  display: none;
  left: 35%;
  top: 40%;
  transition: all 1s, opacity 0.3s;
  &:hover {
    display: block;
  }
}
.avatar-edit-icon {
  &:hover .edit-icon {
    display: block;
  }
}
.show {
  display: flex;
}
</style>
