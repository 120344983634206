<template>
  <dialog ref="dialogRef">
      <Close width="20" height="20" viewBox="0 0 24 24" class="close-btn" v-on:click="close" />
    <div class="dialog-container">
      <header>
        <h1 class="dialog-header">{{ $t("common.appStoreBanner.title") }}</h1>
      </header>
      <div class="link-container">
        <div class="text">{{ t("common.appStoreBanner.text") }}</div>
      </div>
      <Button
          theme="red"
          :link="true"
          :to="applink"
          v-on:click="close"
          class="download-app-btn"
          :label="$t('common.appStoreBanner.button')"
      />
    </div>
  </dialog>
</template>

<script setup>
import { computed, ref, watchEffect } from "vue";
import Close from "@/assets/icons/svg/close.svg";
import { isAndroid, isIOS } from "@/helpers/utils";

import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { flags, mutationTypes } from "@/store/modules/config";
import Button from "@/components/Button.vue";
import { getterTypes } from '@/store/modules/auth';

const { t } = useI18n();
const isMobile = isAndroid() || isIOS();

const store = useStore();

const googlePlayLink = computed(() => store.state.config.googlePlayLink);
const appStoreLink = computed(() => store.state.config.appStoreLink);

const applink = computed(() => isMobile ? (isAndroid() ? googlePlayLink.value : appStoreLink.value) : "");

const isLoggedIn = computed(() => store.getters[getterTypes.isLoggedIn]);
const isGuidLogin = computed(() => store.getters.isTrial);

const showDialog = computed(() => {
  return isMobile && isLoggedIn.value && !isGuidLogin.value;
});

const dialogRef = ref(null);

const offWatch = watchEffect(() => {
  if (showDialog.value) {
    setTimeout(() => dialogRef.value.showModal(), 1000);
  }
});

const close = () => {
  store.commit(mutationTypes.updateFlag, {
    flag: flags.isAlreadySeenMobileAppLink,
    value: true
  });
  dialogRef.value.close();
  offWatch();
};


</script>

<style lang="scss" scoped >

dialog {

  max-width: 100%;
  top: 100px;
  border: 0;
  border-radius: 5px;
  margin-left: 15px;
  margin-right: 15px;

  .dialog-container {
    padding: 30px 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 230px;
  }

  .close-btn {
    position: absolute;
    right: 5px;
    top: 5px;
  }

  .link-container {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .dialog-header {
    color: black;
    font-size: 20px;
  }

  .text {
    height: 100%;
    font-size: 16px;
    display: flex;
    align-items: center;
    color: black;
  }

  .download-app-btn {
    text-align: center;
  }

  &::backdrop {
    opacity: 0.8;
    background-color: black;
    backdrop-filter: blur(5px);
  }

  .logo {
    width: 100%;
    max-width: 70px;
    display: block;
  }
}


</style>
