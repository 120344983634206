import { getLiveTvCategories, getLiveTvCategory, deleteLiveTv, addLiveTv, getSearchGlobal } from "@/api/index.js";
import { getDefaultCountry, getDefaultCountryFromIp } from "@/api/ipApi.js";
import axios from "@/api/axios";
import { flatten, values } from "lodash";
import { commitGlobalSearch } from "../../../helpers/storage";

export default {
  state: {
    categories: null,
    liveTvCards: [],
    firstCategories: null,
    isLoading: false,
    error: null,
    offset: 0,
    limit: 7,
    searchLiveTv: null,
    searchText: null,
    defaultCountry: getDefaultCountry(),
  },
  actions: {
    async search({ commit }, { text }) {
      if (window.location.pathname.toLowerCase().startsWith("/livetv")) {
        commit("setLoading", text);
        if (text?.length) {
          const result = await getSearchGlobal(text);
          commitGlobalSearch(commit, text, result);
        } else {
          commit("setSearchLiveTv", null);
        }
      }
    },
    switchCategory({ state }, category) {
      if (!category) {
        state.defaultCountry = false;
      } else {
        state.defaultCountry = {
          countryCode: category.id,
          countryName: category.name,
        };

        localStorage.setItem("defaultCountry", JSON.stringify(state.defaultCountry));
      }
    },
    async getAllCategoriesLiveTv({ commit, dispatch }) {
      commit("getAllCategoriesLiveTvStart");
      try {
        const [response, defaultCountry] = await Promise.all([getLiveTvCategories(), getDefaultCountryFromIp()]);
        if (response.status !== 200) {
          throw new Error(`HTTP error: ${response.status}`);
        }

        const categories = response.data.data;
        if (defaultCountry) {
          const country = categories.find((item) => item.name.toLowerCase() === defaultCountry.code.toLowerCase() || item.name.toLowerCase() === defaultCountry.name.toLowerCase());
          if (country) {
            dispatch("switchCategory", country);
          }
        }

        commit("getAllCategoriesLiveTvSuccess", categories);
      } catch (error) {
        commit("getAllCategoriesLiveTvFailure");
        console.error(`Could not get products: ${error}`);
      }
    },
    async getLiveTvCards({ commit, dispatch }) {
      commit("getAllCategoriesLiveTvStart");
      try {
        const [response, defaultCountry] = await Promise.all([getLiveTvCategories(), getDefaultCountryFromIp()]);
        if (response.status !== 200) {
          throw new Error(`HTTP error: ${response.status}`);
        }
        let categories = response.data.data;
        // console.log(categories);
        // let firstfiveCat = categories.slice(state.offset, state.limit);
        const sportCategoriesLength = Object.keys(categories).length;
        const catRequests = [];
        for (let i = 0; i < sportCategoriesLength; i++) {
          const category = categories[i];
          catRequests.push(getLiveTvCategory(category.id));
        }

        let categoriesCards = await axios.all(catRequests);
        categoriesCards = categoriesCards.map((item) => values(item.data.data));
        categories = categories.map((item, index) => {
          const categoryShows = categoriesCards[index] || [];
          return {
            ...item,
            infos: categoryShows.map((show) => show.id),
          };
        });

        if (defaultCountry) {
          const country = categories.find((item) => item.name.toLowerCase() === defaultCountry.code.toLowerCase() || item.name.toLowerCase() === defaultCountry.name.toLowerCase());
          if (country) {
            dispatch("switchCategory", country);
          }
        }

        commit("getLiveTvCardsSuccess", flatten(categoriesCards));
        commit("getCategoriesLiveTvSuccess", categories);
      } catch (error) {
        commit("getAllCategoriesLiveTvFailure");
        console.error(`Could not get products: ${error}`);
      }
    },
    async loadMoreLiveTvCards() {},
    async switchFavouriteLiveTv({ state }, { id, is_favorite }) {
      return new Promise((resolve) => {
        const promise = is_favorite ? deleteLiveTv(id) : addLiveTv(id);
        promise
          .then((response) => {
            const itemIndex = state.liveTvCards.findIndex((item) => item.id === id);

            state.liveTvCards[itemIndex].is_favorite = !state.liveTvCards[itemIndex].is_favorite;
            resolve(response);
          })
          .catch(() => {});
      });
    },
  },
  mutations: {
    setSearchLiveTv(state, payload) {
      state.searchLiveTv = payload;
      state.isLoading = false;
    },
    getAllCategoriesLiveTvStart(state) {
      state.isLoading = true;
      state.categories = null;
    },
    getAllCategoriesLiveTvSuccess(state, payload) {
      state.isLoading = false;
      state.categories = payload;
    },
    getAllCategoriesLiveTvFailure(state) {
      state.isLoading = false;
    },
    setLoading(state, payload) {
      state.isLoading = true;
      state.searchText = payload;
    },
    getLiveTvCardsSuccess(state, payload) {
      state.isLoading = false;
      state.liveTvCards = payload;
    },
    getCategoriesLiveTvSuccess(state, payload) {
      state.isLoading = false;
      state.firstCategories = payload;
    },
    changeOffset(state, payload) {
      state.isLoading = false;
      state.offset = payload;
    },
    changeLimit(state, payload) {
      state.isLoading = false;
      state.limit = payload;
    },
  },
  getters: {
    favouriteLiveTv: (state) => {
      return state.liveTvCards?.filter((card) => card.is_favorite);
    },
  },
};
