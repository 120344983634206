<template>
  <div class="faq-accordion">
    <Slot>
      <accordion-item>
        <template v-slot:accordion-trigger>
          <button class="accordion-button">
            {{ $t("faq.faqQuestions.q1.question") }}
            <Add />
          </button>
        </template>
        <template v-slot:accordion-content>
          <p>
            {{ $t("faq.faqQuestions.q1.answer") }}
          </p>
          <br />
          <Button
            link
            theme="faq"
            :label="$t('faq.faqQuestions.q1.link')"
            to="https://www.netflix.com/nl-en/browse/genre/839338"
          />
        </template>
      </accordion-item>
      <accordion-item>
        <template v-slot:accordion-trigger>
          <button class="accordion-button">
            {{ $t("faq.faqQuestions.q2.question") }}
            <Add />
          </button>
        </template>
        <template v-slot:accordion-content>
          <p>
            {{ $t("faq.faqQuestions.q2.answer_1") }}
          </p>
          <br />
          <p>
            {{ $t("faq.faqQuestions.q2.answer_2") }}
          </p>
        </template>
      </accordion-item>
      <accordion-item>
        <template v-slot:accordion-trigger>
          <button class="accordion-button">
            {{ $t("faq.faqQuestions.q3.question") }}
            <Add />
          </button>
        </template>
        <template v-slot:accordion-content>
          <p>
            {{ $t("faq.faqQuestions.q3.answer") }}
          </p>
        </template>
      </accordion-item>
      <accordion-item>
        <template v-slot:accordion-trigger>
          <button class="accordion-button">
            {{ $t("faq.faqQuestions.q4.question") }}
            <Add />
          </button>
        </template>
        <template v-slot:accordion-content>
          <p>
            {{ $t("faq.faqQuestions.q4.answer_1") }}
          </p>
          <br />
          <p>
            {{ $t("faq.faqQuestions.q4.answer_2") }}
          </p>
        </template>
      </accordion-item>
      <accordion-item>
        <template v-slot:accordion-trigger>
          <button class="accordion-button">
            {{ $t("faq.faqQuestions.q5.question") }}
            <Add />
          </button>
        </template>
        <template v-slot:accordion-content>
          <p>
            {{ $t("faq.faqQuestions.q5.answer") }}
          </p>
        </template>
      </accordion-item>
      <accordion-item>
        <template v-slot:accordion-trigger>
          <button class="accordion-button">
            {{ $t("faq.faqQuestions.q6.question") }}
            <Add />
          </button>
        </template>
        <template v-slot:accordion-content>
          <p>
            {{ $t("faq.faqQuestions.q6.answer_1") }}
          </p>
          <br />
          <p>
            {{ $t("faq.faqQuestions.q6.answer_2") }}
          </p>
        </template>
      </accordion-item>
    </Slot>
  </div>
</template>

<script>
import Slot from "../Accordion/Slot";
import AccordionItem from "./AccordionItem";
import Button from "@/components/Button";
import Add from "@/assets/icons/svg/Add";
export default {
  name: "Accordion",
  components: {
    Slot,
    AccordionItem,
    Button,
    Add,
  },
};
</script>

<style lang="scss">
.faq-accordion {
  margin: 2em auto;
  max-width: 815px;
  width: 100%;
  @media only screen and (min-width: 550px) and (max-width: 949px) {
    margin: 1.5em auto;
    width: 90%;
  }
  @media only screen and (min-width: 1450px),
    only screen and (min-width: 950px) and (max-width: 1449px) {
    margin: 2em auto;
    width: 75%;
  }
  .accordion {
    .accordion__item {
      list-style-type: none;
      margin: 0 0 8px;
      font-size: 1.625rem;
      font-weight: 400;
      @media only screen and (min-width: 550px) and (max-width: 949px) {
        font-size: 1.25rem;
      }
      @media only screen and (max-width: 349px),
        only screen and (min-width: 350px) and (max-width: 399px),
        only screen and (min-width: 400px) and (max-width: 549px) {
        font-size: 1.125rem;
      }
      .accordion__trigger {
        .accordion-button {
          float: inherit !important;
          color: $white;
          text-align: start;
          font-size: 26px;
          @media only screen and (min-width: 550px) and (max-width: 949px) {
            font-size: 1.25rem;
          }
          @media only screen and (max-width: 349px),
            only screen and (min-width: 350px) and (max-width: 399px),
            only screen and (min-width: 400px) and (max-width: 549px) {
            font-size: 1.125rem;
          }
          border: 0;
          font-weight: 400;
          margin-bottom: 1px;
          padding: 0.8em 2.2em 0.8em 1.2em;
          position: relative;
          width: 100%;
          background: $bg-gray;
          svg {
            fill: $white;
            height: 1em;
            position: absolute;
            right: 1em;
            top: 50%;
            transform: translateY(-50%);
            width: 1em;
          }
          @media only screen and (min-width: 550px) and (max-width: 949px) {
            font-size: 1.25rem;
          }
        }
      }
      .accordion__content {
        background: $bg-gray;
        display: block;
        text-align: left;
        max-height: 1200px;
        will-change: height, opacity;
        transition: all 0.5s ease, opacity 0.5s ease;

        padding: 1.2em;
        @media only screen and (min-width: 550px) and (max-width: 949px) {
          font-size: 1.25rem;
          line-height: 1.3;
        }
      }
    }
  }
}
</style>
