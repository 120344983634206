<template>
  <div class="onboardingContainer firstLoad simplicity-light">
    <Header class="nfHeader" :hideSearch="true" />
    <div
      class="basicLayout notMobile modernInApp profileOnboarding-secondarylanguages simplicity"
    >
      <div class="simpleContainer">
        <div class="firstLoad">
          <div class="onboarding-main">
            <div class="onboarding-sidebar">
              <div class="stepHeader-container">
                <div class="stepHeader">
                  <span class="stepIndicator">Step <b>4</b> of <b>5</b></span>
                  <h1 class="stepTitle">
                    Which languages do you like to watch shows and movies in?
                  </h1>
                </div>
                <div class="subtitle">
                  Letting us know helps set up your audio and subtitles.
                  <strong>You can always change these.</strong>
                </div>
              </div>
            </div>
            <div class="onboarding-main-content">
              <form action="">
                <ul
                  class="preferred-languages languages-container simpleForm form-profiles"
                >
                  <li class="language-item">
                    <div class="ui-binary-input">
                      <Ok class="language-item-icon" />
                      <input type="checkbox" />
                      <label for="">English</label>
                    </div>
                  </li>
                </ul>
                <ul class="all-languages languages-container">
                  <li
                    class="language-item"
                    v-for="item in languges"
                    :key="item.value"
                  >
                    <div class="ui-binary-input">
                      <input type="checkbox" />
                      <label class="languages-container-label" for="">{{
                        item.name
                      }}</label>
                    </div>
                  </li>
                </ul>
                <div class="submitBtnContainer">
                  <Button
                    class="button-container-btn"
                    theme="lang-setup"
                    label="Next"
                    link
                    to="/movies"
                  />
                </div>
                <div v-show="isShowButton" class="fixed-button-container">
                  <div class="submitBtnContainer">
                    <Button
                      class="button-container-btn"
                      theme="lang-setup"
                      label="Next"
                      link
                      to="/movies"
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header/Header";
import Footer from "@/components/Footer/Footer";
import Button from "@/components/Button";
import lang from "@/data/languages.js";
import Ok from "@/assets/icons/svg/ok.svg";
export default {
  name: "LanguageSetup",
  components: {
    Button,
    Header,
    Footer,
    Ok,
  },
  data() {
    return {
      languges: lang,
      isShowButton: false,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "style";
</style>
