<template>
  <div class="profile-actions-container">
    <h1>{{ $t("addProfile") }}</h1>
    <h2>{{ $t("addProfileDescr") }}</h2>
    <div class="profile-metadata profile-entry">
      <div class="main-profile-avatar">
        <img src="https://media.tv4.live/bm01.png" alt="avatar" />
      </div>
      <div class="profile-add-parent">
        <div class="profile-entry-inputs">
          <input
            type="text"
            id="add-profile-name"
            :placeholder="$t('name')"
            v-model="name"
          />
          <label for="add-profile-name" aria-label="Name"></label>
          <div class="option-wrapper">
            <!-- <div
              class="add-kids-option"
              @mouseenter="handleShowTooltip(isShowTooltip)"
              @mouseleave="handleShowTooltip(isShowTooltip)"
              @click="handleCheck(isCheckedKid)"
            >
              <input
                type="checkbox"
                id="add-kids-profile"
                @click="handleCheck(isCheckedKid)"
              />
              <label for="add-kids-profile">
                <CheckIcon v-if="isCheckedKid" />
              </label>
              <span
                class="add-kids-marker"
                role="checkbox"
                :aria-checked="isCheckedKid"
                tabindex="0"
              >
                {{ $t("kid?") }}
              </span>
              <span
                class="kids-profile-tooltip"
                :class="{ 'show-tooltip': isShowTooltip }"
              >
                {{ $t("kidsTooltip") }}
              </span>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="buttons-container">
      <button
        @click="handleCreateUser"
        class="profile-button preferred-action"
        :class="{ 'preferred-active': !!userName }"
      >
        {{ $t("continue") }}
      </button>
      <button
        @click="this.$store.commit('handleAddProfile', false)"
        class="profile-button"
      >
        {{ $t("cancel") }}
      </button>
    </div>
  </div>
</template>

<script>
// import CheckIcon from "@/assets/icons/svg/CheckIcon";
import { actionTypes } from "@/store/modules/profile/createProfile";
export default {
  name: "AddProfile",
  data() {
    return {
      isCheckedKid: false,
      isShowTooltip: false,
      name: "",
    };
  },
  components: {
    // CheckIcon,
  },
  methods: {
    handleCheck(value) {
      this.isCheckedKid = !value;
    },
    handleShowTooltip(value) {
      this.isShowTooltip = !value;
    },
    handleCreateUser() {
      this.$store.dispatch(actionTypes.createProfile, {
        name: this.name,
        avatar: "/bm01.png",
      });
      this.$store.commit("handleAddProfile", false);
      this.$router.push("/ProfilesGate");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "style";
</style>
