<template>
  <div class="lolomoRow lolomoRow_title_card ltr-0" id="slider">
    <h2 class="rowHeader ltr-0" v-if="!isLiveTv">
      <span class="rowTitle ltr-0 ccc">
        <span class="row-header-title">{{ category.name }}</span>
      </span>
    </h2>
    <div class="rowContainer rowContainer_title_card">
      <div class="ptrack-container">
        <div v-if="isInfos">
          <div class="cards">
            <carousel :settings="settings" :breakpoints="breakpoints">
              <slide v-for="item in category?.infos" :key="item.id">
                <div class="width-slider">
                  <SliderItem @click="click" :item="item" :category="category.name" :categoryId="category.id"
                    :brandImage="brandImage" />
                </div>
              </slide>
              <template #addons="{ slidesCount }">
                <navigation v-if="slidesCount > 1" />
              </template>
            </carousel>
          </div>
        </div>

        <div v-if="isSport && catMovies?.length">
          <div class="cards sports_cards">
            <carousel class="slider" :settings="settings" :breakpoints="breakpoints">
              <slide v-for="item in catMovies" :key="item.id">
                <div class="width-slider">
                  <SliderItemSport @click="click" :item="item" />
                </div>
              </slide>
              <template #addons="{ slidesCount }">
                <navigation v-if="slidesCount > 1" />
              </template>
            </carousel>
          </div>
        </div>
        <div v-if="isLiveTv">
          <div class="cards__live">
            <li v-for="(item, index) in cards" :key="item?.id + index">
              <div class="width-slider">
                <SliderLiveTv @click="click" :item="item" />
              </div>
            </li>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Navigation } from "vue3-carousel";
import SliderItem from "@/components/Slider/SliderItem";
import SliderItemSport from "@/components/Slider/SliderItemSport";
import SliderLiveTv from "@/components/Slider/SliderLiveTv";
import { mapState } from "vuex";

import "./style.scss";

export default {
  name: "Slider",
  props: {
    cards: {
      type: Array,
    },
    name: {
      type: String,
    },
    isInfos: {
      type: Boolean,
    },
    isSport: {
      type: Boolean,
    },
    isLiveTv: {
      type: Boolean,
    },
    isTvGuide: {
      type: Boolean,
    },
    category: {
      type: Object,
    },
    getData: {
      type: Function,
    },
    click: {
      type: Function,
      default: () => { },
    },
  },
  components: {
    Carousel,
    SliderItem,
    Slide,
    Navigation,
    SliderItemSport,
    SliderLiveTv,
  },
  data() {
    return {
      catMovies: [],
    };
  },
  setup() {
    return {
      settings: {
        itemsToShow: 1,
        itemsToScroll: 1,
        snapAlign: "center",
        transition: 300,
        wrapAround: true,
      },
      breakpoints: {
        700: {
          itemsToShow: 3,
          itemsToScroll: 3,
          snapAlign: "center",
        },
        1024: {
          itemsToShow: 5,
          itemsToScroll: 5,
          snapAlign: "start",
        },
      },
    };
  },
  computed: {
    ...mapState({
      brandImage: (state) => `/img/${state.config?.brandName}-logo.png`,
    }),
  },
  methods: {
    getSliderData() {
      if (!this.category) {
        this.catMovies = this.cards;
      } else {
        const itemIds = this.category?.infos;
        this.catMovies = this.cards?.filter((item) => {
          if (item.imdb_id) {
            return !itemIds?.includes(item.imdb_id);
          } else {
            return itemIds?.includes(item.id);
          }
        });
      }
    },
  },
  mounted() {
    if (this.isSport) {
      this.getSliderData();
    }
  },
};
</script>
<style lang="scss">
.sports_cards .rowContainer {
  height: 200px;
}
</style>
