<template>
  <div>
    <div v-if="!isHideSelect" class="video-player__bar">
      <div class="button-back">
        <button @click="goBack()">
          <Back />
        </button>
      </div>
      <div v-if="isSelect && !isHideSelect" class="video-player__select">
        <select class="video-player__select-season" @change="changeSeason" v-model="selectedSeason">
          <option value="" label="Seasons" disabled aria-disabled="true" />
          <option v-for="season in seasons" :key="season" :value="season" :label="`Season ${season}`" />
        </select>
        <select class="video-player__select-season" @change="changeEpisode" v-model="selectedEpisode">
          <option value="" label="Episodes" disabled aria-disabled="true" />
          <option v-for="episode in episodes" :key="episode" :value="episode" :label="`Episode ${episode}`" />
        </select>
      </div>
    </div>
    <div class="video-player__wrap" v-if="isMkv()">
      <PlayerMkv :linkVideo="videoSrc" @playing="onVideoPlaying" @pause="onVideoPause" @timeupdate="onVideoTimeUpdate" :textTracks="textTracks" />
    </div>
    <div class="video-player__wrap" v-else>
      <VideoPlayer @playing="onVideoPlaying" @pause="onVideoPause" @timeupdate="onVideoTimeUpdate" :linkVideo="videoSrc" :textTracks="textTracks" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { throttle } from "lodash";
import moment from "moment";
import Back from "@/assets/icons/svg/back.svg";
import PlayerMkv from "@/views/Player/PlayerMkv";
import VideoPlayer from "@/views/Player/VideoPlayer";
import { updateWatchTime } from "@/api/backoffice";

export default {
  name: "PlayerWrap",
  props: {},
  data() {
    return {
      player: null,
      isSelect: false,
      isHideSelect: false,
      videoSrc: "",
      episodes: [],
      seasons: [],
      selectedEpisode: 1,
      selectedSeason: 1,
      trackId: "",
      timer: 0,
      starPlaying: null,
      updateWatchTimeThrottled: throttle(
        async ({ imdbId, durationInSec, leadId }) => {
          try {
            await updateWatchTime({
              imdbId,
              durationInSec,
              leadId,
            });
            this.timer = 0;
          } catch (e) {
            console.error(e);
          }
        },
        5 * 60 * 1000,
        { leading: false, trailing: true }
      ),
    };
  },
  components: {
    PlayerMkv,
    VideoPlayer,
    Back,
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    getItem() {
      if (this.linkVideo) {
        return;
      }
      const { trackId, type, season, episode } = this.$route.query;

      if (!trackId || !type) {
        this.$router.push("/");
        return;
      }

      this.trackId = trackId;

      if (type === "getLinkSportVod") {
        this.$store.dispatch("getLinkSportVod", {
          id: trackId,
        });
      }
      if (type === "getLinkLive") {
        this.$store.dispatch("getLinkLive", {
          id: `${trackId}.m3u8`,
        });
      }
      if (type === "getLinkMovie") {
        this.$store.dispatch("getLinkMovie", {
          id: trackId,
        });
      }
      if (type === "getLinkTvShow") {
        this.$store.dispatch("getLinkTvShow", {
          id: trackId,
          season: season,
          episode: episode,
        });
        this.isSelect = true;
      }
    },
    isMkv() {
      return this.videoSrc?.indexOf(".mkv") !== -1; // true
    },
    changeSeason(e) {
      this.selectedSeason = e.target.value;

      console.log(this.selectedSeason);
    },
    changeEpisode(e) {
      this.selectedEpisode = e.target.value;
      this.$store.dispatch("getLinkTvShow", {
        id: this.trackId,
        season: this.selectedSeason,
        episode: this.selectedEpisode,
      });
    },
    onVideoPlaying() {
      this.isHideSelect = true;
    },
    onVideoPause() {
      this.isHideSelect = false;
      this.starPlaying = null;
    },
    async onVideoTimeUpdate() {
      // this method is called every 300ms approximately by the browser, during video playback.
      // each time it been called we add the time from the previous call to the sum
      if (this.starPlaying == null) {
        this.starPlaying = new Date();
      }
      this.timer += moment.duration(new Date() - this.starPlaying).asSeconds();
      this.starPlaying = new Date();
      try {
        await this.updateWatchTimeThrottled({
          imdbId: this.trackId,
          durationInSec: this.timer,
          leadId: this.isTrial ? this.leadId : null,
        });
      } catch (e) {
        console.error(e);
      }
    },
  },
  computed: {
    ...mapState({
      linkVideoState: (state) => state.playVideo.linkPlay,
      textTracks: (state) => state.playVideo.subtitles,
      infoTvShow: (state) => state.playVideo.infoTvShow,
    }),
    ...mapGetters({ isTrial: "isTrial", leadId: "leadId" }),
  },
  watch: {
    linkVideoState() {
      this.videoSrc = this.linkVideoState?.secure_link;
    },
    subtitles() {
      this.textTrack = this.subtitles?.data;
    },
    infoTvShow() {
      const seasonsList = this.infoTvShow?.[0].meta_episodes || [];
      const episodesList = seasonsList?.[this.selectedSeason - 1] || [];
      this.seasons = seasonsList.map((_, index) => index + 1);
      this.episodes = episodesList.map((item) => item.episode);
    },
  },
  beforeMount() {
    this.getItem();
  },
  unmounted() {
    this.updateWatchTimeThrottled.flush();
  },
};
</script>
<style lang="scss" scoped>
.video-player {
  &__wrap {
    height: 100vh;
  }
  &__bar {
    display: flex;
    position: absolute;
    top: 20px;
    z-index: 100;
    left: 30px;
  }
  &__select {
    display: flex;
    position: absolute;
    top: 20px;
    left: 40px;
    &-episode {
      width: 100px !important;
      border-radius: 2px;
      margin-left: 10px;
    }
    &-season {
      width: 200px;
      border-radius: 2px;
      background: transparent;
      border-color: #fff;
      color: #fff;
      margin-right: 10px;
      min-height: 40px;
      padding: 0 10px;
    }
  }
}
.button-back {
  position: absolute;
  top: 9px;
  left: -30px;
  svg {
    padding-top: 5px;
    fill: white;
    width: 30px;
    margin-right: 20px;
  }
  button {
    cursor: pointer;
    padding: 12px 24px;
    background: transparent;
    outline: 0;
    border: 0;
  }
}
.video-player__select-season option {
  color: black;
}
</style>
